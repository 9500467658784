import { useState, useEffect } from "react"
import { connect, useDispatch } from "react-redux"
import { ClipLoader } from "react-spinners"
import { ImageRepository } from "../../../../logic/imageRepository/ImageRepository"
import { updateImageDataById } from "../../../../store/labels/actionCreators"
import { AppState } from "../../../../store"
import { FileUtil } from "../../../../utils/FileUtil"
import { ImageData } from "store/labels/types"

interface IProps {
  imageData: ImageData
  isScrolling?: boolean
  onClick: () => void
  isSelected?: boolean
  addImageIdForChangeStatus: (id: string) => void
  isLazyLoading?: boolean
  isChangingActiveIndex?: boolean
  showCheckBox?: boolean
  imageStyleClass?: string
  selectedImages: string[]
}

const ImagePreview: React.FC<IProps> = ({
  imageData,
  isScrolling = false,
  onClick,
  isSelected = false,
  addImageIdForChangeStatus,
  isLazyLoading,
  isChangingActiveIndex,
  showCheckBox = false,
  imageStyleClass = "",
  selectedImages,
}) => {
  const [image, setImage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [imageTypeBgColor, setImageTypeBgColor] = useState("")
  const dispatch = useDispatch()

  useEffect(() => {
    const loadImage = async () => {
      if (imageData.loadStatus) {
        const image = ImageRepository.getById(imageData.id)
        setImage(image)
      } else if (!isScrolling || !isLoading) {
        setIsLoading(true)
        FileUtil.loadImage(imageData.fileData)
          .then((image) => {
            imageData.loadStatus = true
            ImageRepository.storeImage(imageData.id, image)
            setImage(image)
            setIsLoading(false)
          })
          .catch((error) => {
            console.error("Load image error:", error)
          })
      }
    }

    loadImage()
  }, [dispatch, imageData, isScrolling, isLoading])

  useEffect(() => {
    switch (imageStyleClass) {
      case "annotated":
        setImageTypeBgColor("bg-blue-400")
        break
      case "review":
        setImageTypeBgColor("bg-yellow-400")
        break
      case "re-evaluate":
        setImageTypeBgColor("bg-red-400")
        break
      case "done":
        setImageTypeBgColor("bg-green-400")
        break
      default:
        setImageTypeBgColor("")
    }
  }, [imageStyleClass])

  return (
    <div onClick={onClick} className="p-4">
      <div
        className={`relative rounded-md bg-secondary bg-opacity-25 hover:border-accent 
                    border-2 aspect-square flex items-center 
                    ${isSelected ? "border-base-content" : "border-primary"} `}
        key="Foreground"
      >
        {image ? (
          <>
            {(isChangingActiveIndex || isLazyLoading) && (
              <div className="absolute inset-0 bg-secondary bg-opacity-75 flex items-center justify-center">
                <ClipLoader size={30} color={"#14B58C"} loading={true} />
              </div>
            )}
            <img
              className="mx-auto aspect-square rounded-sm p-1 bg-base-primary"
              draggable={false}
              src={image.src}
              alt=""
            />
            <span
              className={`absolute h-3 w-3 top-0 right-0 -mt-1 -mr-1 ${imageTypeBgColor}`}
            ></span>
            {showCheckBox && (
              <input
                id="imageCheckBox"
                type="checkbox"
                className="absolute bottom-1 left-1 w-4 h-4"
                checked={selectedImages.includes(imageData.imageId)}
                onClick={() => addImageIdForChangeStatus(imageData.imageId)}
              />
            )}
          </>
        ) : (
          <div className="flex justify-center w-full">
            <ClipLoader size={30} color={"#14B58C"} loading={true} />
          </div>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = (state: AppState) => ({
  selectedImages: state.labels.selectedImages,
})

export default connect(mapStateToProps, { updateImageDataById })(ImagePreview)
