import axios from "axios"

export interface FeedbackFormValues {
  phoneNumber: string
  annotationAccuracy: number
  toolUsability: number
  userInterface: number
  annotationSpeed: number
  overallSatisfaction: number
  encounteredIssues?: string
  featureRequest?: string
  comments: string
}

export const submitFeedbackAPI = async (feedbackData: FeedbackFormValues, accessToken: string) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/feedback/createFeedback`,
      feedbackData,
      {
        headers: {
          "Content-Type": "application/json",
          accesstoken: accessToken,
        },
      }
    )
    return { statusCode: 200, data }
  } catch (error) {
    if (error instanceof Error) {
      console.log({ error })
      return { statusCode: 500, error: error.message }
    }
  }
}
