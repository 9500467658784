import React, { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import { AppState } from "../../../../store"
import { ImageData } from "../../../../store/labels/types"
import { FixedSizeGrid as Grid } from "react-window"
import ImagePreview from "../ImagePreview/ImagePreview"
import { ClipLoader } from "react-spinners"
import "./ImagesList.scss"
import { ImageStatus } from "data/enums/ImageStatus"
import { ISize } from "interfaces/ISize"

interface IProps {
  activeImageIndex: number
  imagesData: ImageData[]
  loadImageIndex: any
  updateLoadImageIndex: () => any
  totalImagesSize: number
  isLoading: boolean
  addImageIdForChangeStatus: (id: string) => void
  userRole: any
  imageStatus: any
  isChangingActiveIndex: boolean
  changeActiveIndex: (index: number) => any
  imageDataLoading?: boolean
}

const ImagesList: React.FC<IProps> = ({
  activeImageIndex,
  imagesData,
  loadImageIndex,
  updateLoadImageIndex,
  totalImagesSize,
  isLoading,
  addImageIdForChangeStatus,
  userRole,
  imageStatus,
  isChangingActiveIndex,
  changeActiveIndex,
  imageDataLoading,
}) => {
  const [size, setSize] = useState<ISize | null>(null)
  const imagesListRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const updateListSize = () => {
      if (imagesListRef.current) {
        const listBoundingBox = imagesListRef.current.getBoundingClientRect()
        setSize({
          width: listBoundingBox.width,
          height: listBoundingBox.height,
        })
      }
    }

    updateListSize()
    window.addEventListener("resize", updateListSize)

    return () => {
      window.removeEventListener("resize", updateListSize)
    }
  }, [])

  const renderImagePreview = (
    rowIndex: number,
    columnIndex: number,
    style: React.CSSProperties
  ) => {
    const index = rowIndex * 2 + columnIndex
    if (index >= imagesData.length) {
      return null
    }

    let showCheckbox = false
    if (userRole && ![ImageStatus.ALL, ImageStatus.UN_ANNOTATED].includes(imageStatus)) {
      if (userRole === "admin") {
        showCheckbox = true
      } else if (
        userRole === "reviewer" &&
        [ImageStatus.RE_EVALUATE, ImageStatus.ANNOTATED, ImageStatus.REVIEW].includes(imageStatus)
      ) {
        showCheckbox = true
      } else if (
        userRole === "annotator" &&
        [ImageStatus.RE_EVALUATE, ImageStatus.ANNOTATED].includes(imageStatus)
      ) {
        showCheckbox = true
      }
    }

    return (
      <div style={style} key={index}>
        <ImagePreview
          isScrolling={false}
          imageData={imagesData[index]}
          onClick={() => onClickHandler(index)}
          isSelected={activeImageIndex === index}
          isChangingActiveIndex={isChangingActiveIndex}
          isLazyLoading={isLoading}
          addImageIdForChangeStatus={addImageIdForChangeStatus}
          showCheckBox={showCheckbox}
          imageStyleClass={`${
            imageStatus === ImageStatus.ALL ? imagesData[index].imageStatus : ""
          }`}
        />
      </div>
    )
  }

  const onClickHandler = (index: number) => {
    if (isChangingActiveIndex || isLoading) return
    changeActiveIndex(index)
  }

  const handleItemsRendered = ({
    visibleRowStartIndex,
    visibleRowStopIndex,
    overscanRowStopIndex,
  }) => {
    if (
      overscanRowStopIndex >= Math.ceil(imagesData.length / 2) - 1 &&
      loadImageIndex < totalImagesSize
    ) {
      updateLoadImageIndex()
    }
  }

  return (
    <>
      {!imageDataLoading ? (
        <div
          className="h-full max-w-[16vw] overflow-y-scroll scrollbar flex flex-col items-center"
          ref={imagesListRef}
          onClick={() => console.log("Clicked")}
        >
          {!!size && (
            <Grid
              columnCount={2}
              columnWidth={size.width / 2.05}
              height={size.height}
              rowCount={Math.ceil(imagesData.length / 2)}
              rowHeight={size.height / 6} // Adjust height as necessary
              width={size.width}
              overscanRowCount={5}
              className="scrollbar"
              onItemsRendered={handleItemsRendered}
            >
              {({ columnIndex, rowIndex, style }) =>
                renderImagePreview(rowIndex, columnIndex, style)
              }
            </Grid>
          )}
          {isLoading && (
            <div className="flex justify-center items-center mx-auto pt-5">
              <ClipLoader size={30} color={"#14B58C"} loading={true} />
            </div>
          )}
          {imagesData && imagesData.length === 0 && (
            <div className="flex justify-center my-1">No data to display</div>
          )}
        </div>
      ) : (
        <div className="flex justify-center items-center mx-auto pt-5">
          <ClipLoader size={30} color={"#14B58C"} loading={true} />
        </div>
      )}
    </>
  )
}

const mapStateToProps = (state: AppState) => ({
  activeImageIndex: state.labels.activeImageIndex,
  imagesData: state.labels.imagesData,
  userRole: state.general.userRole,
  imageStatus: state.labels.imageStatus,
})

export default connect(mapStateToProps)(ImagesList)
