export enum Direction {
  TOP = "TOP",
  BOTTOM = "BOTTOM",
  LEFT = "LEFT",
  RIGHT = "RIGHT",
  TOP_RIGHT = "TOP_RIGHT",
  TOP_LEFT = "TOP_LEFT",
  BOTTOM_RIGHT = "BOTTOM_RIGHT",
  BOTTOM_LEFT = "BOTTOM_LEFT",
  CENTER = "CENTER",
}
