function LinearProgressWithLabel(value: number, secure?: boolean) {
  return (
    <>
      {value > 0 && (
        <div>
          <progress className="progress progress-accent w-full" value={value} max="100"></progress>
          {secure && (
            <div className="flex items-center space-x-2 text-accent">
              {/* Lock Icon */}
              <i className="bi bi-lock-fill text-accent text-2xl"></i>
              {/* Text indicating secure upload */}
              <span className="text-sm font-medium">
                Upload in progress... Secure and end-to-end encrypted.
              </span>
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default LinearProgressWithLabel
