import { AuthContext } from "contexts/authContext"
import { useFormik } from "formik"
import { useContext, useState } from "react"
import { toast } from "react-toastify"
import * as yup from "yup"
import { ForgotView } from "../ForgotView/ForgotView"
import { AuthFlowWrapper } from "views/Common/AuthFlowWrapper/AuthFlowWrapper"

export const RequestCodeView = (props: any) => {
  const [forgotView, setForgotView] = useState<JSX.Element | null>(null)
  const authContext = useContext(AuthContext)

  const sendCodeClicked = async (email) => {
    try {
      await authContext.sendCode(email)
      setForgotView(<ForgotView state={{ email: email }} />)
    } catch (err) {
      console.log(err)
      if (err instanceof Error) {
        toast.error(err.message, {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
    }
  }

  const formik = useFormik({
    initialValues: { email: "" },
    validationSchema: yup.object({
      email: yup.string().email().required(),
    }),
    onSubmit: (values) => {
      console.log(values)
      sendCodeClicked(values.email)
    },
  })

  return (
    <AuthFlowWrapper>
      {forgotView === null ? (
        <div className="flex flex-col justify-center w-full flex-grow items-center">
          <div className="py-1">
            <div className="text-5xl tracking-widest font-black text-accent">
              Account
              <span className="text-5xl ml-3 tracking-widest font-black text-accent-content">
                Recovery
              </span>
            </div>
          </div>
          <div className="w-3/4 h-full flex flex-col justify-center">
            <p className="text-center my-4">
              To help keep your account safe,{" "}
              {window.location.hostname.includes("upjao.ai") ? "Upjao" : "EasyML"} wants to make
              sure it’s really you trying to sign in
            </p>
            <form className="w-full" onSubmit={formik.handleSubmit}>
              <div className="form-control w-full pt-10">
                <label className="label pl-2">
                  <span className="label-text font-medium text-sm">
                    Confirm your registered email id
                  </span>
                </label>
                <input
                  id="email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  type="email"
                  placeholder="Enter your email id"
                  className="input input-bordered focus:input-accent hover:input-accent rounded-full"
                />

                <label className="label">
                  <span className="label-text-alt text-error ">{formik.errors.email}</span>
                </label>
              </div>
              <div className="pt-4 w-full">
                <button
                  disabled={!(formik.isValid && formik.dirty)}
                  className="btn btn-accent w-full rounded-full text-base"
                >
                  Next
                </button>
              </div>
            </form>
          </div>
        </div>
      ) : (
        forgotView
      )}
    </AuthFlowWrapper>
  )
}
