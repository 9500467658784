import { getUser } from "apis/users"
import { AuthContext, AuthStatus } from "contexts/authContext"
import { useFormik } from "formik"
import { useContext, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { BeatLoader } from "react-spinners"
import { toast } from "react-toastify"
import * as yup from "yup"
import "./SigninView.scss"
import { AuthFlowWrapper } from "views/Common/AuthFlowWrapper/AuthFlowWrapper"

export const SigninView = (props: any) => {
  const location = useLocation()

  const authContext = useContext(AuthContext)
  const navigator = useNavigate()

  const [showPassword, setShowPassword] = useState(false)

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  //   Handler function for signin button clicked
  const signInClicked = async (email, password) => {
    try {
      await authContext.signInWithEmail(email, password)
      const session: any = await authContext.getSession()
      const { statusCode, data } = await getUser(
        session.accessToken.payload.sub,
        session.accessToken.jwtToken
      )
      console.log({ statusCode, data })
      if (statusCode !== 200) {
        toast.error(data.message, {
          position: toast.POSITION.TOP_RIGHT,
        })
        console.error(data)
        await authContext.signOut()
      } else {
        const uInfoFromLStorage = await authContext.setUserInfo(data)
        const currentUserEmail = uInfoFromLStorage?.data?.email
        authContext.setAuthStatus(AuthStatus.SignedIn)
        if (location.state)
          navigator(
            `/accept-invitation${location.state}&accessToken=${session.accessToken.jwtToken}&email=${currentUserEmail}`
          )
        else navigator("/")
      }
      navigator("/")
    } catch (err) {
      await authContext.signOut()
      navigator("/signin")
      if (err instanceof Error) {
        // setOpen(true);
        // setError(err.message);
        toast.error(err.message, {
          position: toast.POSITION.TOP_RIGHT,
        })
        console.error(err.message)
      } else {
        toast.error("Error not of Error instance!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        })
      }
    }
  }

  const formik = useFormik({
    initialValues: { email: "", password: "" },
    validationSchema: yup.object({
      email: yup.string().email().required(),
      password: yup.string().required(),
    }),
    onSubmit: (values, { setSubmitting }) => {
      console.log(values)
      setSubmitting(true)
      signInClicked(values.email, values.password).finally(() => setSubmitting(false))
    },
  })

  const loading = location.state ? location.state["authLoading"] : false

  const finalRender = (
    <AuthFlowWrapper>
      <div className="flex flex-col justify-center w-full flex-grow items-center">
        <div className="text-5xl tracking-widest font-black text-accent">
          Welcome
          <span className="text-5xl ml-3 tracking-widest font-black text-accent-content">Back</span>
        </div>
        <div className="w-3/4 h-full flex flex-col justify-center">
          <form onSubmit={formik.handleSubmit}>
            <div className="flex flex-col space-y-0 pt-8">
              <div className="form-control">
                <label className="label">
                  <span className="label-text font-medium text-base">Email</span>
                </label>
                <input
                  id="email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  type="email"
                  placeholder="Enter your email id"
                  className="input input-bordered focus:input-accent hover:input-accent rounded-full"
                />
                <label className="label">
                  <span className="label-text-alt text-error ">{formik.errors.email}</span>
                </label>
              </div>
              <div className="flex items-end w-full">
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text font-medium text-base">Password</span>
                  </label>
                  <div className="flex justify-between">
                    <div className="relative w-full">
                      <input
                        id="password"
                        onChange={formik.handleChange}
                        value={formik.values.password}
                        type={showPassword ? "text" : "password"}
                        placeholder="Enter your password"
                        autoComplete="current-password"
                        className="input w-full input-bordered focus:input-accent hover:input-accent rounded-full pr-12"
                      />
                      <button
                        type="button"
                        onClick={togglePasswordVisibility}
                        className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700"
                      >
                        {showPassword ? (
                          <i className="bi bi-eye-slash"></i>
                        ) : (
                          <i className="bi bi-eye"></i>
                        )}
                      </button>
                    </div>
                  </div>
                  <button
                    className={`btn my-4 w-full rounded-full py-3 border-none ${
                      formik.isSubmitting
                        ? "bg-accent-content"
                        : "bg-accent-content text-primary-content text-opacity-50 hover:text-white hover:text-opacity-100 hover:bg-accent"
                    } disabled:bg-accent-content`}
                    type="submit"
                    disabled={!(formik.isValid && formik.dirty) || formik.isSubmitting}
                  >
                    {formik.isSubmitting ? (
                      <>
                        <svg
                          className="inline mr-2 w-4 h-4 text-white animate-spin"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 16 16"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
                          />
                          <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384l-2.36 1.966A.25.25 0 0 1 8 4.466z" />
                        </svg>
                        Loading...
                      </>
                    ) : (
                      "LOG IN"
                    )}
                  </button>
                  <label className="label">
                    <span className="label-text-alt text-error ">{formik.errors.password}</span>
                  </label>
                </div>
              </div>
              {/* <div className="mx-auto my-5 flex items-center">
            <div className="border-t border-white border-2 flex-grow mr-2"></div>
            <div className="w-full font-medium">Or Login via OTP</div>
            <div className="border-t border-white border-2 flex-grow ml-2"></div>
          </div> */}
              {/* <div className="flex items-end">
             <div className="form-control w-full">
              <label className="label">
                <span className="label-text font-medium text-base">
                  Phone
                </span>
              </label>
              <div className="flex justify-between">
              <input
                id="phone"
                onChange={formik.handleChange}
                value={formik.values.phone}
                type="text"
                placeholder="Enter your phone"
                className="input w-full input-bordered focus:input-accent hover:input-accent rounded-full relative"
              />
              <button
                className="btn ml-2 btn-circle bg-accent-content hover:bg-accent hover:fill-current disabled:bg-accent-content w-12"
                type="submit"
                disabled={!(formik.isValid && formik.dirty)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="bi bi-arrow-right h-6 w-6"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                  />
                </svg>
              </button>
            </div>
              <label className="label">
                <span className="label-text-alt text-error ">
                  {formik.errors.phone}
                </span>
              </label>
            </div>
          </div> */}
              <a href="/requestcode" className="link text-primary-content hover:text-accent">
                Forgot password
              </a>
            </div>
          </form>
          <div className="mx-auto my-5">
            {window.location.hostname.includes("upjao.ai")
              ? "Not registered on UPJAO? Sign up with us now!"
              : "Not registered on EasyML? Sign up with us now!"}
          </div>
          <button
            onClick={() => navigator("/signup", { state: location.state })}
            className="w-full mx-auto my-4 border-accent font-bold border-2 px-10 hover:bg-accent text-primary-content hover:text-white rounded-full py-3 "
          >
            SIGN UP
          </button>
        </div>
      </div>
    </AuthFlowWrapper>
  )

  return loading ? (
    <>
      <div className="grid h-screen place-items-center">
        <BeatLoader size={30} color={"#fc4e03"} loading={true} />
      </div>
    </>
  ) : (
    finalRender
  )
}
