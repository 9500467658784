import React from "react"
import "./EditorBottomNavigationBar.scss"
import { ImageData } from "../../../store/labels/types"
import { AppState } from "../../../store"
import { connect } from "react-redux"
import { ISize } from "../../../interfaces/ISize"
import { ContextType } from "../../../data/enums/ContextType"

interface IProps {
  size: ISize
  imageData: ImageData
  totalImageCount: number
  activeImageIndex: number
  activeContext: ContextType
  isChangingActiveIndex: boolean
  isLazyLoading: boolean
  changeActiveIndex: (index) => any
}

const EditorBottomNavigationBar: React.FC<IProps> = ({
  size,
  imageData,
  totalImageCount,
  activeImageIndex,
  isChangingActiveIndex,
  isLazyLoading,
  activeContext,
  changeActiveIndex,
}) => {
  // console.log({size, imageData, totalImageCount, activeImageIndex, activeContext})
  // const minWidth: number = 400;

  const getImageCounter = () => {
    return (totalImageCount ? activeImageIndex + 1 : 0) + " / " + totalImageCount
  }

  // const getClassName = () => {
  //     return classNames(
  //         "EditorBottomNavigationBar",
  //         {
  //             "with-context": activeContext === ContextType.EDITOR
  //         }
  //     );
  // };

  // console.log(imageData)

  return (
    <div className="w-full flex gap-5 items-center justify-center text-primary-content text-sm py-2">
      {activeImageIndex !== 0 && totalImageCount !== 0 && (
        <button
          id="prevImgButton"
          onClick={() => changeActiveIndex(activeImageIndex - 1)}
          disabled={isChangingActiveIndex || isLazyLoading}
          // disabled={activeImageIndex === 0}
          className="btn btn-sm btn-circle bg-accent-content border-accent-content hover:border-accent-content hover:bg-accent hover:fill-current disabled:bg-accent-content"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="bi bi-arrow-right h-4 w-4"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
            />
          </svg>
        </button>
      )}
      {/* <ImageButton
                image={"/ico/left.png"}
                imageAlt={"previous"}
                buttonSize={{ width: 25, height: 25 }}
                isDisabled={activeImageIndex === 0}
                externalClassName={"left"}
            /> */}
      <div>
        {" "}
        {imageData?.fileData.name} {` (${getImageCounter()}) `}{" "}
      </div>
      {activeImageIndex !== totalImageCount - 1 && totalImageCount !== 0 && (
        <button
          id="nextImgButton"
          onClick={() => changeActiveIndex(activeImageIndex + 1)}
          disabled={isChangingActiveIndex || isLazyLoading}
          // disabled={activeImageIndex === totalImageCount - 1}
          className="btn btn-sm btn-circle bg-accent-content border-accent-content hover:border-accent-content hover:bg-accent hover:fill-current disabled:bg-accent-content"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="bi bi-arrow-right h-4 w-4"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
            />
          </svg>
        </button>
      )}
      {/* <ImageButton
                image={"/ico/right.png"}
                imageAlt={"next"}
                buttonSize={{ width: 25, height: 25 }}
                isDisabled={activeImageIndex === totalImageCount - 1}
                externalClassName={"right"}
            /> */}
    </div>
  )
}

const mapDispatchToProps = {}

const mapStateToProps = (state: AppState) => ({
  activeImageIndex: state.labels.activeImageIndex,
  activeContext: state.general.activeContext,
})

export default connect(mapStateToProps, mapDispatchToProps)(EditorBottomNavigationBar)
