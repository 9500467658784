import * as yup from "yup"

const rectAnnotationSchema = yup.object().shape({
  fileData: yup.array().of(
    yup.object().shape({
      id: yup.string().required(),
      rect: yup
        .object()
        .shape({
          x: yup.number().required(),
          y: yup.number().required(),
          width: yup.number().required(),
          height: yup.number().required(),
        })
        .required(),
      isCreatedByAI: yup.boolean().required(),
      status: yup.string().required(),
      image_id: yup.string().required(),
      image_name: yup.string().required(),
      stage: yup.string().required(),
      labels: yup
        .object()
        .shape({
          id: yup.string().required(),
          name: yup.string().required(),
          color: yup.string().required(),
        })
        .required(),
    })
  ),
})

const pointAnnotationSchema = yup.object().shape({
  fileData: yup.array().of(
    yup.object().shape({
      id: yup.string().required(),
      point: yup
        .object()
        .shape({
          x: yup.number().required(),
          y: yup.number().required(),
        })
        .required(),
      isCreatedByAI: yup.boolean().required(),
      status: yup.string().required(),
      image_id: yup.string().required(),
      image_name: yup.string().required(),
      stage: yup.string().required(),
      labels: yup
        .object()
        .shape({
          id: yup.string().required(),
          name: yup.string().required(),
          color: yup.string().required(),
        })
        .required(),
    })
  ),
})

const lineAnnotationSchema = yup.object().shape({
  fileData: yup.array().of(
    yup.object().shape({
      id: yup.string().required(),
      line: yup
        .object()
        .shape({
          start: yup
            .object()
            .shape({
              x: yup.number().required(),
              y: yup.number().required(),
            })
            .required(),
          end: yup
            .object()
            .shape({
              x: yup.number().required(),
              y: yup.number().required(),
            })
            .required(),
        })
        .required(),
      image_id: yup.string().required(),
      image_name: yup.string().required(),
      stage: yup.string().required(),
      labels: yup
        .object()
        .shape({
          id: yup.string().required(),
          name: yup.string().required(),
          color: yup.string().required(),
        })
        .required(),
    })
  ),
})

const polygonAnnotationSchema = yup.object().shape({
  fileData: yup.array().of(
    yup.object().shape({
      id: yup.string().required(),
      vertices: yup
        .array()
        .of(
          yup
            .object()
            .shape({
              x: yup.number().required(),
              y: yup.number().required(),
            })
            .required()
        )
        .required(),
      image_id: yup.string().required(),
      image_name: yup.string().required(),
      stage: yup.string().required(),
      labels: yup
        .object()
        .shape({
          id: yup.string().required(),
          name: yup.string().required(),
          color: yup.string().required(),
        })
        .required(),
    })
  ),
})

const polygonCOCOAnnotationSchema = yup.object().shape({
  fileData: yup.object().shape({
    images: yup
      .array()
      .of(
        yup.object().shape({
          id: yup.number().required(),
          width: yup.number().required(),
          height: yup.number().required(),
          file_name: yup.string().required(),
        })
      )
      .required(),
    annotations: yup
      .array()
      .of(
        yup
          .object()
          .shape({
            id: yup.number().required(),
            iscrowd: yup.number().required(),
            image_id: yup.number().required(),
            category_id: yup.number().required(),
            segmentation: yup.array().of(yup.array()).required(),
            bbox: yup.array().required(),
            area: yup.number().required(),
          })
          .required()
      )
      .required(),
    categories: yup
      .array()
      .of(
        yup.object().shape({
          id: yup.number().required(),
          name: yup.string().required(),
        })
      )
      .required(),
  }),
})

const imageRecognitionSchema = yup.object().shape({
  fileData: yup
    .array()
    .of(
      yup.object().shape({
        image: yup.string().required(),
        annotations: yup.array().of(yup.string().required()),
      })
    )
    .required(),
})

const labelTypeToSchema = {
  RECT: rectAnnotationSchema,
  POINT: pointAnnotationSchema,
  LINE: lineAnnotationSchema,
  POLYGON: polygonAnnotationSchema,
  COCO_POLYGON: polygonCOCOAnnotationSchema,
  "IMAGE RECOGNITION": imageRecognitionSchema,
}

export const findAnnotationType = (fileData: object, labelName: string) => {
  console.log("label name: " + labelName)
  if (labelName === "POLYGON") {
    if (polygonAnnotationSchema.isValidSync({ fileData })) return "JSON"
    else if (polygonCOCOAnnotationSchema.isValidSync({ fileData })) return "COCO"
    else return "NONE"
  } else {
    return "JSON"
  }
}

export const validateAnnotationSchema = (fileData: object, labelName: string, format?: string) => {
  if (format === "COCO" && labelName === "POLYGON") {
    return polygonCOCOAnnotationSchema.isValidSync({ fileData })
  }
  return labelTypeToSchema[labelName]
    ? labelTypeToSchema[labelName].isValidSync({ fileData })
    : false
}
