const SecuritySection = () => {
  return (
    <div className="px-8 md:px-16 lg:px-[4rem] py-20 ">
      <h2 className="text-4xl md:text-5xl lg:text-6xl font-bold text-accent mb-8">
        Secure Platform
      </h2>

      <div className="flex flex-wrap justify-start gap-4 mb-12">
        <div className="badge badge-outline badge-accent p-4">SSL</div>
        <div className="badge badge-outline badge-accent p-4">TLS</div>
        <div className="badge badge-outline badge-accent p-4">OAuth</div>
        <div className="badge badge-outline badge-accent p-4">OpenID</div>
        <div className="badge badge-outline badge-accent p-4">JWT</div>
      </div>

      <div className="flex flex-col lg:flex-row items-center lg:justify-between gap-16">
        {/* Image on the left */}
        <div className="w-full lg:w-2/5">
          <img
            src="/3d-internet-secuirty-badge.webp"
            alt="Secure Platform"
            className="rounded-lg shadow-lg w-full aspect-square"
          />
        </div>

        {/* Content on the right */}
        <div className="w-full lg:w-3/5 p-4 flex flex-col">
          <ul className="text-justify list-inside list-disc space-y-4">
            <li>External SSL tunneling for secure data transmission.</li>
            <li>
              Internal requests authenticated via TLS, with peer-to-peer authentication for all
              internal services via TLS internal CA.
            </li>
            <li>
              Industry standards like OpenID, OAuth, and Open Policy Agent for Identity & Access
              Management and Access control.
            </li>
            <li>Peer-to-peer authentication for all the services ensures secure communication.</li>
            <li>
              Short-lived JWT tokens as part of OpenID Connect, an industry standard for security.
            </li>
            <li>No external access for stored images.</li>
            <li>All services operate within private networks without external access.</li>
            <li>
              Read-only containers make malware installation difficult, and all containers are
              industry-verified, significantly reducing risks.
            </li>
            <li>Row-level access control in the database ensures granular security.</li>
            <li>
              Encrypted database connections with a dynamic password policy provide an extra layer
              of protection.
            </li>
            <li>
              Was DDoS protection & Was Application Gateway are used for analyzing incoming traffic
              patterns and ensuring security.
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default SecuritySection
