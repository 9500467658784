import { setUser } from "apis/users"
import { AuthContext, AuthStatus } from "contexts/authContext"
import { useFormik } from "formik"
import { useCallback, useContext, useRef, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import * as yup from "yup"
import "./SignupView.scss"
import { AuthFlowWrapper } from "views/Common/AuthFlowWrapper/AuthFlowWrapper"

export const SignupView = (props: any) => {
  const navigator = useNavigate()
  const location = useLocation()
  const authContext = useContext(AuthContext)
  const inputRefs = useRef<Array<HTMLInputElement | null>>(Array(6).fill(null))
  const [stage, setStage] = useState(1)
  const [created, setCreated] = useState(false)
  const [coginotoID, setCogintoID] = useState("")

  const [signupCredentials, setSignupCredentials] = useState({
    name: "",
    role: "",
    company: "",
    email: "",
    password: "",
  })

  const signUpClicked = async (email, password) => {
    // const allowedCompanies = ["Upjao", "IISER Bhopal"]
    // if (!allowedCompanies.includes(signupCredentials.company)) {
    //   toast.error("The company is not allowed to sign up")
    //   return
    // }
    try {
      var result: any = await authContext.signUpWithEmail(email, password)
      setCreated(true)
      setCogintoID(result.userSub)
      setSignupCredentials({ ...signupCredentials, email, password })
      // navigator('/signup');
    } catch (err) {
      if (err instanceof Error) {
        console.log(err.message)
        toast.error(err.message, {
          position: toast.POSITION.TOP_RIGHT,
        })
      } else {
        toast.error("error not instance of Error!", {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
    }
  }

  const verifyCode = async (cognitoId, code, name, role, company, email, password) => {
    try {
      toast.loading("Loading", {
        position: toast.POSITION.TOP_RIGHT,
        toastId: "signIn",
      })
      await authContext.verifyCode(cognitoId, code)
      await authContext.signInWithEmail(email, password)
      const session: any = await authContext.getSession()
      // Call API endpoint to save the credentials in the database
      let user: Object = {
        cognitoID: cognitoId,
        name: name,
        email: email,
        role: role,
        company: company,
      }
      const { data, statusCode, error } = await setUser(user, session.accessToken.jwtToken)
      if (statusCode === 500 && error) {
        toast.error(error, {
          position: toast.POSITION.TOP_RIGHT,
          toastId: "signIn",
        })
      } else {
        authContext.setAuthStatus(AuthStatus.SignedIn)
        const uInfoFromLStorage = await authContext.setUserInfo(data)
        const currentUserEmail = uInfoFromLStorage?.data?.email
        if (location.state)
          navigator(
            `/accept-invitation${location.state}&accessToken=${session.accessToken.jwtToken}&email=${currentUserEmail}`
          )
        else {
          navigator("/dashboard")
        }
        toast.success("Success", {
          position: toast.POSITION.TOP_RIGHT,
          toastId: "signIn",
        })
      }
    } catch (err) {
      if (err instanceof Error) {
        toast.error(err.message, {
          position: toast.POSITION.TOP_RIGHT,
          toastId: "signIn",
        })
      }
      console.log(err)
    }
  }

  const formik1 = useFormik({
    initialValues: { name: "", role: "", company: "" },
    validationSchema: yup.object({
      name: yup.string().required(),
      role: yup.string().required(),
      company: yup.string().required(),
    }),
    onSubmit: (values) => {
      console.log(values)
      setStage(2)
      setSignupCredentials({
        ...signupCredentials,
        name: values.name,
        role: values.role,
        company: values.company,
      })
    },
  })

  const formik2 = useFormik({
    initialValues: { email: "", password: "", confirmPassword: "" },
    validationSchema: yup.object({
      email: yup.string().email().required(),
      password: yup
        .string()
        .matches(
          /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
          "Password Should Contains: 1 Lowercase, 1 UpperCase, 1 Number, 1 Special Symbol and Length should be >= 8"
        )
        .required("Password is required"),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref("password"), null], "Passwords must match")
        .required("Confirm Password is required"),
    }),
    onSubmit: (values) => {
      signUpClicked(values.email, values.password)
    },
  })

  const formik3 = useFormik({
    initialValues: { code: "" },
    validationSchema: yup.object({
      code: yup.string().length(6).required("Please enter the OTP"),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true)
      verifyCode(
        coginotoID,
        values.code,
        signupCredentials.name,
        signupCredentials.role,
        signupCredentials.company,
        signupCredentials.email,
        signupCredentials.password
      ).finally(() => {
        toast.dismiss("signIn")
        setSubmitting(false)
      })
    },
  })

  const handleInputChange = useCallback(
    (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target
      const updatedOtp = [...formik3.values.code]
      updatedOtp[index] = value || ""
      formik3.setFieldValue("code", updatedOtp.join(""))
      console.log({ index, updatedOtp, inputRefs })

      if (!value && index > 0) {
        inputRefs.current[index - 1]?.focus()
      } else if (value && index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1]?.focus()
      } else if (value && index === inputRefs.current.length - 1) {
        inputRefs.current[index]?.blur()
      }
    },
    [formik3.values.code]
  )
  console.log("OTP:", formik3.values.code) // Check the value in the console

  return (
    <AuthFlowWrapper>
      {created ? (
        <>
          <div className="flex font-poppins flex-col justify-center w-full flex-grow items-center">
            <div className="text-5xl tracking-widest font-black text-accent">
              Welcome
              <span className="text-5xl ml-3 tracking-widest font-black text-accent-content">
                Back
              </span>
            </div>
            <form onSubmit={formik3.handleSubmit}>
              <div className="form-control w-full pt-10">
                <label className="label pl-2">
                  <span className="label-text font-medium text-sm mx-auto">
                    An email with a verification code was just sent to{" "}
                    {signupCredentials.email
                      .split("@")[0]
                      .replace(/.(?=.{2,}$)/g, "*")
                      .concat("@")
                      .concat(signupCredentials.email.split("@")[1])}
                  </span>
                </label>
                <label className="label">
                  <span className="label-text-alt text-error ">{formik3.errors.code}</span>
                </label>
                <div className="flex justify-between">
                  <div className="flex justify-center pt-10 space-x-4">
                    {Array.from({ length: 6 }, (_, index) => (
                      <input
                        key={index}
                        ref={(el) => (inputRefs.current[index] = el)}
                        type="text"
                        value={formik3.values.code[index] || ""}
                        maxLength={1}
                        onChange={(event) => handleInputChange(index, event)}
                        className={`input text-2xl input-bordered focus:input-accent bg-secondary hover:input-accent rounded-md w-20 h-20 text-center`}
                      />
                    ))}
                  </div>
                </div>
                <div className="pt-4 w-full">
                  <button
                    disabled={!(formik3.isValid && formik3.dirty) || formik3.isSubmitting}
                    type="submit"
                    className="btn btn-accent w-full rounded-full text-base"
                  >
                    Next
                  </button>
                </div>
              </div>
            </form>
          </div>
        </>
      ) : (
        <div className="flex flex-col justify-center w-full flex-grow items-center">
          <div className=" text-5xl font-poppins tracking-widest font-bold text-accent w-full flex justify-center relative">
            {stage === 2 && (
              <button
                className="text-3xl border-none text-accent-content hover:text-opacity-100 hover:text-accent absolute top-2 left-20"
                onClick={() => setStage(1)}
              >
                <i className="bi bi-arrow-left-circle"></i>
              </button>
            )}
            Hi
            <span className="text-5xl ml-3 tracking-widest font-bold text-accent-content">
              Friend
            </span>
          </div>
          <div className="w-3/4 h-full flex flex-col justify-center">
            {stage === 1 ? (
              <>
                <form onSubmit={formik1.handleSubmit}>
                  <div className="flex flex-col -space-y-4">
                    <div className="form-control">
                      <label className="label">
                        <span className="label-text font-medium text-base">Name</span>
                      </label>
                      <input
                        id="name"
                        onChange={formik1.handleChange}
                        value={formik1.values.name}
                        type="text"
                        placeholder="Enter your name"
                        className="input input-bordered focus:input-accent hover:input-accent rounded-full"
                      />

                      <label className="label">
                        <span className="label-text-alt text-error ">{formik1.errors.name}</span>
                      </label>
                    </div>
                    <div className="form-control">
                      <label className="label">
                        <span className="label-text font-medium text-base">Role</span>
                      </label>
                      <input
                        id="role"
                        onChange={formik1.handleChange}
                        value={formik1.values.role}
                        type="text"
                        placeholder="Enter your role"
                        className="input input-bordered focus:input-accent hover:input-accent rounded-full"
                      />

                      <label className="label">
                        <span className="label-text-alt text-error ">{formik1.errors.role}</span>
                      </label>
                    </div>
                    <div className="flex items-end">
                      <div className="form-control w-full">
                        <label className="label">
                          <span className="label-text font-medium text-base">Company</span>
                        </label>
                        <div className="flex justify-between">
                          <input
                            id="company"
                            onChange={formik1.handleChange}
                            value={formik1.values.company}
                            type="text"
                            placeholder="Enter your company"
                            className="input w-full input-bordered focus:input-accent hover:input-accent rounded-full relative"
                          />
                        </div>
                        <button
                          className="btn my-4 w-full rounded-full py-3 border-none bg-accent-content text-primary-content text-opacity-50 hover:text-white hover:text-opacity-100 hover:bg-accent disabled:bg-accent-content"
                          // onClick={() => {
                          //   setStage(2);
                          // }}
                          type="submit"
                          disabled={!(formik1.isValid && formik1.dirty)}
                        >
                          NEXT
                        </button>
                        <label className="label">
                          <span className="label-text-alt text-error ">
                            {formik1.errors.company}
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </form>
              </>
            ) : (
              <>
                <form onSubmit={formik2.handleSubmit}>
                  <div className="flex flex-col -space-y-4 pt-10">
                    <div className="form-control">
                      <label className="label">
                        <span className="label-text font-medium text-base">Email Id</span>
                      </label>

                      <input
                        id="email"
                        onChange={formik2.handleChange}
                        value={formik2.values.email}
                        type="email"
                        placeholder="Enter your email id"
                        className="input input-bordered focus:input-accent hover:input-accent rounded-full"
                      />

                      <label className="label">
                        <span className="label-text-alt text-error ">{formik2.errors.email}</span>
                      </label>
                    </div>
                    <div className="form-control">
                      <label className="label">
                        <span className="label-text font-medium text-base">Password</span>
                      </label>

                      <input
                        id="password"
                        onChange={formik2.handleChange}
                        value={formik2.values.password}
                        type="password"
                        placeholder="Enter your password"
                        className="input input-bordered focus:input-accent hover:input-accent rounded-full"
                      />

                      <label className="label">
                        <span className="label-text-alt text-error ">
                          {formik2.errors.password}
                        </span>
                      </label>
                    </div>
                    <div className="flex items-end">
                      <div className="form-control w-full">
                        <label className="label">
                          <span className="label-text font-medium text-base">Confirm Password</span>
                        </label>

                        <div className="flex justify-between">
                          <input
                            id="confirmPassword"
                            onChange={formik2.handleChange}
                            value={formik2.values.confirmPassword}
                            type="password"
                            placeholder="Confirm your password"
                            className="input w-full input-bordered focus:input-accent hover:input-accent rounded-full relative"
                          />
                        </div>
                        <button
                          disabled={!(formik2.isValid && formik2.dirty)}
                          type="submit"
                          className="btn my-4 w-full rounded-full py-3 border-none bg-accent-content text-primary-content text-opacity-50 hover:text-white hover:text-opacity-100 hover:bg-accent disabled:bg-accent-content"
                        >
                          SIGN UP
                        </button>
                        <label className="label">
                          <span className="label-text-alt text-error ">
                            {formik2.errors.confirmPassword}
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </form>
              </>
            )}
            <div
              onClick={() => {
                stage === 1 ? setStage(2) : setStage(1)
              }}
              className="cursor-pointer"
            >
              <svg
                className="mx-auto"
                xmlns="http://www.w3.org/2000/svg"
                width="27.911"
                height="6.202"
                viewBox="0 0 27.911 6.202"
              >
                <g id="Group_2249" data-name="Group 2249" transform="translate(-1031 -762.59)">
                  <g id="Group_2234" data-name="Group 2234" transform="translate(1031 762.59)">
                    <rect
                      className={`fill-base-content ${stage === 1 ? "" : "fill-secondary"}`}
                      id="Rectangle_14"
                      data-name="Rectangle 14"
                      width={stage === 1 ? "15.506" : "6.202"}
                      height="6.202"
                      rx="2"
                    />
                  </g>
                  <g
                    id="Group_2235"
                    data-name="Group 2235"
                    transform={
                      stage === 2 ? "translate(1042.709 762.59)" : "translate(1052.709 762.59)"
                    }
                  >
                    <rect
                      className={`fill-base-content ${stage === 2 ? "" : "fill-secondary"}`}
                      id="Rectangle_14-2"
                      data-name="Rectangle 14"
                      width={stage === 2 ? "15.506" : "6.202"}
                      height="6.202"
                      rx="2"
                    />
                  </g>
                </g>
              </svg>
            </div>
            <div className="mx-auto my-5">Registered on UPJAO? Log In!</div>
            <button
              onClick={() => navigator("/signin", { state: location.state })}
              className="w-full mx-auto my-4 border-accent font-bold border-2 px-10 hover:bg-accent text-primary-content rounded-full py-3 "
            >
              LOG IN
            </button>
          </div>
        </div>
      )}
    </AuthFlowWrapper>
  )
}
