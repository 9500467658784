function getDefaultTheme() {
  let defaultTheme = localStorage.getItem("theme")
  if (!defaultTheme) {
    const prefersDarkTheme = window.matchMedia("(prefers-color-scheme: dark)").matches
    defaultTheme = prefersDarkTheme ? "dark" : "light"
    // defaultTheme = 'light';
    localStorage.setItem("theme", defaultTheme)
    document.documentElement.setAttribute("data-theme", defaultTheme)
  }

  return defaultTheme
}

const defaultTheme = getDefaultTheme()
export default defaultTheme
