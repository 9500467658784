import React from "react"
import "./ExitProjectPopup.scss"
import { GenericYesNoPopup } from "../GenericYesNoPopup/GenericYesNoPopup"
import {
  updateActiveImageIndex,
  updateActiveLabelNameId,
  updateFirstLabelCreatedFlag,
  updateImageData,
  updateLabelNames,
} from "../../../store/labels/actionCreators"
import { AppState } from "../../../store"
import { connect } from "react-redux"
import { ImageData, LabelName } from "../../../store/labels/types"
import { PopupActions } from "../../../logic/actions/PopupActions"
import { ProjectData } from "../../../store/general/types"
import { updateProjectData } from "../../../store/general/actionCreators"
import { useNavigate } from "react-router-dom"

interface IProps {
  updateActiveImageIndex: (activeImageIndex: number) => any
  updateActiveLabelNameId: (activeLabelId: string) => any
  updateLabelNames: (labelNames: LabelName[]) => any
  updateImageData: (imageData: ImageData[]) => any
  updateFirstLabelCreatedFlag: (firstLabelCreatedFlag: boolean) => any
  updateProjectData: (projectData: ProjectData) => any
}

const ExitProjectPopup: React.FC<IProps> = (props) => {
  const navigator = useNavigate()

  const {
    updateActiveLabelNameId,
    updateLabelNames,
    updateActiveImageIndex,
    updateImageData,
    updateFirstLabelCreatedFlag,
    updateProjectData,
  } = props

  const renderContent = () => {
    return (
      <div className="py-8 w-full mx-auto text-center">
        Are you sure you want to leave the editor?
      </div>
    )
  }

  const onAccept = () => {
    updateActiveLabelNameId(null)
    updateLabelNames([])
    updateProjectData({
      type: null,
      name: "my-project-name",
      id: "my-project-id",
      metadata: "my-project-metadata",
    })
    updateActiveImageIndex(null)
    updateImageData([])
    updateFirstLabelCreatedFlag(false)
    PopupActions.close()
    navigator("/dashboard")
  }

  const onReject = () => {
    PopupActions.close()
  }

  return (
    <GenericYesNoPopup
      title={"Exit project"}
      renderContent={renderContent}
      acceptLabel={"Exit"}
      onAccept={onAccept}
      rejectLabel={"Back"}
      onReject={onReject}
    />
  )
}

const mapDispatchToProps = {
  updateActiveLabelNameId,
  updateLabelNames,
  updateProjectData,
  updateActiveImageIndex,
  updateImageData,
  updateFirstLabelCreatedFlag,
}

const mapStateToProps = (state: AppState) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(ExitProjectPopup)
