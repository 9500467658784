import { ISize } from "../../../../interfaces/ISize"
import { ImageData, LabelName } from "../../../../store/labels/types"
import React from "react"
import { updateImageDataById } from "../../../../store/labels/actionCreators"
import { AppState } from "../../../../store"
import { connect } from "react-redux"
import { remove } from "lodash"
import "./TagLabelsList.scss"
import { PopupWindowType } from "../../../../data/enums/PopupWindowType"
import { updateActivePopupType } from "../../../../store/general/actionCreators"
import EmptyLabelList from "../EmptyLabelList/EmptyLabelList"
import { ProjectData } from "store/general/types"
import { ProjectType } from "data/enums/ProjectType"
interface IProps {
  size: ISize
  imageData: ImageData
  updateImageDataById: (id: string, newImageData: ImageData) => any
  labelNames: LabelName[]
  projectData: ProjectData
  updateActivePopupType: (activePopupType: PopupWindowType) => any
}

const TagLabelsList: React.FC<IProps> = ({
  size,
  imageData,
  updateImageDataById,
  labelNames,
  projectData,
  updateActivePopupType,
}) => {
  // const labelInputFieldHeight = 40;
  // const listStyle: React.CSSProperties = {
  //     width: size.width,
  //     height: size.height
  // };
  // const listStyleContent: React.CSSProperties = {
  //     width: size.width,
  //     height: imageData.labelPolygons.length * labelInputFieldHeight
  // };

  const onTagClick = (labelId: string) => {
    if (imageData.labelNameIds.includes(labelId)) {
      updateImageDataById(imageData.id, {
        ...imageData,
        labelNameIds: remove(imageData.labelNameIds, (element: string) => element !== labelId),
      })
    } else {
      if (projectData.type === ProjectType.IMAGE_RECOGNITION_SINGLE_LABEL) {
        updateImageDataById(imageData.id, {
          ...imageData,
          labelNameIds: [labelId],
        })
      } else {
        updateImageDataById(imageData.id, {
          ...imageData,
          labelNameIds: imageData.labelNameIds.concat(labelId),
        })
      }
    }
  }

  // const getClassName = (labelId: string) => {
  //     return classNames(
  //         "TagItem",
  //         {
  //             "active": imageData.labelNameIds.includes(labelId)
  //         }
  //     );
  // };

  const addNewOnClick = () => {
    updateActivePopupType(PopupWindowType.UPDATE_LABEL)
  }

  const getChildren = () => {
    if (!imageData) return []
    return [
      ...labelNames.map((labelName: LabelName) => {
        return (
          <div
            // className={getClassName(labelName.id)}
            className={`${
              imageData.labelNameIds.includes(labelName.id)
                ? "bg-accent hover:bg-accent"
                : "bg-accent-content hover:bg-accent-content"
            }  border-2 hover:border-white w-fit btn btn-sm `}
            onClickCapture={() => onTagClick(labelName.id)}
            key={labelName.id}
          >
            {labelName.name}
          </div>
        )
      }),
    ]
  }

  return (
    <div className="h-full" key="tag-labels-list">
      {labelNames.length === 0 ? (
        <div className="h-full" onClick={addNewOnClick} key="empty-label-list">
          <EmptyLabelList type="label" />
        </div>
      ) : (
        <div className="px-6 py-3 flex gap-2 flex-wrap w-full">
          {getChildren()}
          <button
            className="btn btn-sm hover:bg-accent bg-accent-content fill-black hover:fill-white"
            // image={"/ico/plus.png"}
            // imageAlt={"plus"}
            // buttonSize={{ width: 32, height: 32 }}
            onClick={addNewOnClick}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              className="bi bi-plus"
              viewBox="0 0 16 16"
            >
              <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
            </svg>
          </button>
        </div>
      )}
    </div>
  )
}

const mapDispatchToProps = {
  updateImageDataById,
  updateActivePopupType,
}

const mapStateToProps = (state: AppState) => ({
  labelNames: state.labels.labels,
  projectData: state.general.projectData,
})

export default connect(mapStateToProps, mapDispatchToProps)(TagLabelsList)
