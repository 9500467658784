import axios from "axios"
require("dotenv").config()

export const setUser = async (user, accessToken: string) => {
  console.log({ REACT_APP_API_URL: process.env })
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/users/setUser`,
      {
        cognitoId: user.cognitoID,
        name: user.name,
        email: user.email,
        role: user.role,
        company: user.company,
      },
      {
        headers: {
          "Content-Type": "application/json",
          accesstoken: accessToken,
        },
      }
    )
    console.log({ backendData: data })
    return { statusCode: 200, data }
  } catch (error) {
    if (error instanceof Error) {
      console.error(error.message)
      return { statusCode: 500, error: error.message }
    }
  }
}

export const getUser = async (cognitoId: string, accessToken: string) => {
  console.log({ ENV_SIGNIN: process.env, accessToken })
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/users/getUser`,
      {
        cognitoId: cognitoId,
      },
      {
        headers: {
          "Content-Type": "application/json",
          accesstoken: accessToken,
        },
      }
    )
    console.log("backendData", { backendData: response })
    return { statusCode: response.status, data: response.data }
  } catch (error: any) {
    console.log("backendData", { backendData: error })
    return { statusCode: error.response.status, data: error.response.data }
  }
}
