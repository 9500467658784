import React, { useState } from "react"
import "./GenericLabelTypePopup.scss"
import { LabelType } from "../../../data/enums/LabelType"
import { AppState } from "../../../store"
import { connect, useSelector } from "react-redux"
import { GenericYesNoPopup } from "../GenericYesNoPopup/GenericYesNoPopup"
import { ILabelToolkit, LabelToolkitData } from "../../../data/info/LabelToolkitData"
import { ProjectType } from "../../../data/enums/ProjectType"

interface IProps {
  title: string
  activeLabelType: LabelType
  projectType: ProjectType
  onLabelTypeChange?: (labelType: LabelType) => any
  acceptLabel: string
  onAccept: (labelType: LabelType) => any
  skipAcceptButton?: boolean
  disableAcceptButton?: boolean
  rejectLabel: string
  onReject: (labelType: LabelType) => any
  renderInternalContent: (labelType: LabelType) => any
  skipRejectButton?: boolean
}

const GenericLabelTypePopup: React.FC<IProps> = ({
  title,
  activeLabelType,
  projectType,
  onLabelTypeChange,
  acceptLabel,
  onAccept,
  skipAcceptButton,
  disableAcceptButton,
  rejectLabel,
  onReject,
  renderInternalContent,
  skipRejectButton,
}) => {
  const [labelType, setLabelType] = useState(activeLabelType)
  const currentTheme = useSelector((state: AppState) => state.general.theme)

  const getSidebarButtons = () => {
    function temp() {
      return (
        LabelToolkitData.filter((label: ILabelToolkit) => label?.projectType === projectType)
          // .filter((label: ILabelToolkit) => true)
          .map((label: ILabelToolkit) => {
            return (
              <button
                // image={'/ico/plus.png'}
                onClick={() => {
                  setLabelType(label.labelType)
                  onLabelTypeChange(label.labelType)
                }}
                className={`rounded-md bg-secondary hover:border-accent border-2 shadow-2xl text-white mb-3 w-12 ${
                  labelType === label.labelType ? "border-accent" : "border-secondary"
                }   `}
              >
                <div className="m-[8px]">
                  {currentTheme === "dark" ? (
                    <img src={label.imageSrc} alt={label.imageAlt} />
                  ) : (
                    <img src={label.darkImageSrc} alt={label.imageAlt} />
                  )}
                </div>
              </button>
            )
          })
      )
    }

    return (
      <div className="flex flex-col gap-2 items-center justify-center h-full py-8">{temp()}</div>
    )
  }

  const renderContent = () => {
    return (
      <div>
        <div>{renderInternalContent(labelType)}</div>
      </div>
    )
  }

  return (
    <GenericYesNoPopup
      title={title}
      renderContent={renderContent}
      leftBar={getSidebarButtons}
      acceptLabel={acceptLabel}
      skipRejectButton={skipRejectButton}
      onAccept={() => onAccept(labelType)}
      skipAcceptButton={skipAcceptButton}
      disableAcceptButton={disableAcceptButton}
      rejectLabel={rejectLabel}
      onReject={() => onReject(labelType)}
    />
  )
}

const mapDispatchToProps = {}

const mapStateToProps = (state: AppState) => ({
  projectType: state.general.projectData.type,
})

export default connect(mapStateToProps, mapDispatchToProps)(GenericLabelTypePopup)
