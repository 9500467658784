/****
 * @name: EditorContainer
 * @author: Sudipta Dey, Bhargab Nath
 * @description: This is actually a child component of EditorView but you can say, this is originally the editor page where
 *               basically all the UI parts that you see on the editor page are broken down into child components.
 *               Major Child component of this components are:
 *                  1) SideNavigationBar - It carries all the components needed for the left and right side navigations.
 *                  2) EditorTopNavigationBar - It is the navbar for the editor page.
 *                  3) EditorBottomNavigationBar - This shows the currently shown image's no, and total number of images plus prev and next button to go to the previous or next image respectively.
 *  @summary: This component is basically the most important for the developer as it carries the maximum functionalities that are to be implemented throughout
 *            the whole of website.
 */

import React, { PropsWithChildren, useEffect, useState, useRef } from "react"
import { connect } from "react-redux"
import { Direction } from "../../../data/enums/Direction"
import { ISize } from "../../../interfaces/ISize"
import { Settings } from "../../../settings/Settings"
import { AppState } from "../../../store"
import { ImageData, LabelName } from "../../../store/labels/types"
import LabelsToolkit from "../SideNavigationBar/LabelsToolkit/LabelsToolkit"
import SideNavigationBar from "../SideNavigationBar/SideNavigationBar"
import { VerticalEditorButton } from "../VerticalEditorButton/VerticalEditorButton"
import "./EditorContainer.scss"
import Editor from "../Editor/Editor"
import { ContextManager } from "../../../logic/context/ContextManager"
import { ContextType } from "../../../data/enums/ContextType"
import EditorBottomNavigationBar from "../EditorBottomNavigationBar/EditorBottomNavigationBar"
// Redux functions and types
import {
  updateActivePopupData,
  updateActivePopupType,
  updateProjectData,
  updateUserRole,
} from "../../../store/general/actionCreators"
import { ProjectData } from "../../../store/general/types"
import {
  updateActiveLabelId,
  updateActiveLabelType,
  updateHighlightedLabelId,
  updateImageData,
  updateImageSize,
  updateImageStatus,
  updateLabelNames,
  updateSelectedImages,
} from "../../../store/labels/actionCreators"
import { LabelType } from "../../../data/enums/LabelType"
import { PopupWindowType } from "../../../data/enums/PopupWindowType"
import { addImageData } from "../../../store/labels/actionCreators"
import { updateImageAnnotationsAPI, changeImageStatus } from "../../../apis/images"
import { useLocation, useNavigate } from "react-router-dom"
import { getUserRoleInProjectAPI } from "../../../apis/projects"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import ImagesList from "../SideNavigationBar/ImagesList/ImagesList"
import EditorTopNavigationBar from "../EditorTopNavigationBar/EditorTopNavigationBar"
import LoadingBar from "react-top-loading-bar"
import { ImageStatus } from "data/enums/ImageStatus"
import { hasLabels, updateActiveImageIndexThunk } from "store/thunks/labelsThunks"
import { getFiles } from "utils/FileUtil"
import QuickActions from "./QuickActions"

interface IProps {
  updateActiveImageIndexThunkAction: (activeImageIndex: number) => any
  addImageDataAction: (imageData: ImageData[]) => any
  updateImageDataAction: (imageData: ImageData[]) => any
  updateProjectDataAction: (projectData: ProjectData) => any
  updateActivePopupTypeAction: (activePopupType: PopupWindowType) => any
  updateActivePopupTypeData: (activePopUpData: Object) => any
  updateActiveLabelType: (activeLabelType: LabelType) => any
  updateLabelNamesAction: (labels: LabelName[]) => any
  projectData: ProjectData
  windowSize: ISize
  activeImageIndex: number
  imagesData: ImageData[]
  activeContext: ContextType
  showMenu: boolean
  updateHighlightedLabelId: (highlightedLabelId: string) => any
  updateActiveLabelId: (highlightedLabelId: string) => any
  imageSize: number
  updateImageSizeAction: (imageSize: number) => any
  selectedImages: string[]
  updateSelectedImagesAction: (selectedImages: string[]) => any
  updateUserRoleAction: (userRole: string) => any
  imageStatus: string
  updateImageStatusAction: (imageStatus: string) => any
}

const EditorContainer: React.FC<IProps> = (props: PropsWithChildren<IProps>) => {
  const {
    windowSize,
    activeImageIndex,
    imagesData,
    activeContext,
    imageStatus,
    updateActiveImageIndexThunkAction,
  } = props
  const navigator = useNavigate()
  const [leftTabStatus, setLeftTabStatus] = useState(true)
  const [rightTabStatus, setRightTabStatus] = useState(true)
  const [loading, setLoading] = useState(0)
  const location = useLocation()

  // lets console log image data as well as project and see how it really be
  // console.log(props.imagesData, props.projectData)
  // labelPoints, labelLines, labelRects, and labelPolygons are stored inside imagesData
  // APPROACH: upload entire imagesData, which is any way image-wise, to the DB via save/autoSave API.
  // Q? How different is the shape of the uploaded image file to what is expected by Redux?
  // On the DB we save: _id, imageName, stage.
  // On the frontend, Redux saves: fileData(derived from )
  // if that is done, we have to check if loading the data the same way would work
  // we might want to sync project data as well.

  const [loadImageIndex, setLoadImageIndex] = useState(0)
  const [totalImagesSize, setTotalImagesSize] = useState<number>(0)
  const [imageDataLoading, setImageDataLoading] = useState(false)

  const [isLazyLoading, setIsLazyLoading] = useState(false)
  const [isChangingActiveIndex, setIsChangingActiveIndex] = useState(false)

  const updateLoadImageIndex = () => {
    console.log("xeras")
    if (isLazyLoading) return
    setLoadImageIndex((prevLoadImageIndex) => prevLoadImageIndex + 50)
    setIsLazyLoading(true)
  }

  const [isAdmin, setIsAdmin] = useState(
    location.state ? location.state["buttonVisibility"] : false
  )

  useEffect(() => {
    props.updateImageStatusAction(ImageStatus.ALL)
    props.updateImageDataAction([])

    const checkUserRoleInProject = async () => {
      const projectId = location.pathname.split("/")[2]
      const userInfo = JSON.parse(localStorage.getItem("userInfo")).data
      const accessToken: string = localStorage.getItem("accessToken")
      const userRoleData = await getUserRoleInProjectAPI(projectId, userInfo?._id, accessToken)
      if (userRoleData.statusCode === 500 || !!userRoleData.error) {
        toast.error("Internal Server Error!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        })
        console.log("Some Error Occurred")
        return
      } else {
        // Check role of the logged in user in this project
        const userRoleInProject = userRoleData?.data?.data
        props.updateUserRoleAction(userRoleInProject)
        if (userRoleInProject === "admin") {
          setIsAdmin(true)
          return
        } else {
          setIsAdmin(false)
          if (
            userRoleInProject !== "admin" &&
            userRoleInProject !== "reviewer" &&
            userRoleInProject !== "annotator"
          ) {
            navigator("/not-found")
          }
          return
        }
      }
    }
    checkUserRoleInProject()
  }, [])

  //listen for changes in loadImageIndex
  useEffect(() => {
    console.log("loadImageIndex", loadImageIndex)
    if (Array.isArray(location.state) && location.state.length !== 0) {
      console.log("error Images: ", location.state)
      props.updateActivePopupTypeData(location.state)
      props.updateActivePopupTypeAction(PopupWindowType.UPLOAD_ERROR)
      location.state = null
    }
    // imageStatus useEffect will take care of the initial loading
    if (loadImageIndex === 0) return

    const projectId = location.pathname.split("/")[2]

    getFiles(
      setIsLazyLoading,
      projectId,
      props.updateActiveLabelType,
      props.updateLabelNamesAction,
      props.addImageDataAction,
      props.updateActiveImageIndexThunkAction,
      props.updateProjectDataAction,
      props.updateImageSizeAction,
      setLoading,
      props.activeImageIndex,
      loadImageIndex,
      setTotalImagesSize,
      props.imageStatus,
      isLazyLoading
    )
  }, [loadImageIndex])

  useEffect(() => {
    const fetchData = async () => {
      props.updateActiveLabelType(null)
      props.updateImageDataAction([])
      setTotalImagesSize(0)
      setLoadImageIndex(0)
      console.log("load started")
      setImageDataLoading(true)
      const projectId = location.pathname.split("/")[2]
      await getFiles(
        setIsLazyLoading,
        projectId,
        props.updateActiveLabelType,
        props.updateLabelNamesAction,
        props.addImageDataAction,
        props.updateActiveImageIndexThunkAction,
        props.updateProjectDataAction,
        props.updateImageSizeAction,
        setLoading,
        props.activeImageIndex,
        0,
        setTotalImagesSize,
        props.imageStatus,
        isLazyLoading
      )
      console.log("load ended")
      setImageDataLoading(false)
    }

    fetchData()
  }, [imageStatus])

  // Listen for changes in imageSize state in redux store,
  // If is changes, this menuClasses, totalImagesSize state in this (or EditorContainer) component should also be updated
  // updating totalImagesSize local state is neccessary as that will eventually help the VirtualList.tsx know if there is any need to update the loadImageIndex for lazy loading
  useEffect(() => {
    setTotalImagesSize(props.imageSize)
  }, [props.imageSize])

  //listen for changes to imageData
  useEffect(() => {
    async function uploadLabels(imageId: any, labels: any, currentImageStatus?: ImageStatus) {
      // props.updateActivePopupTypeAction(PopupWindowType.LOADER);

      const projectId = location.pathname.split("/")[2]
      const accessToken: string = localStorage.getItem("accessToken")
      const uploadInfo = await updateImageAnnotationsAPI(imageId.split("_")[0], labels, accessToken)
      if (currentImageStatus)
        await changeImageStatus([image_data.imageId], currentImageStatus, projectId, accessToken)
      if (uploadInfo.statusCode === 200) {
        console.log("Labels Updated Successfully!")
      }
      // PopupActions.close();
    }
    if (isLazyLoading) return
    // console.log({ activeImageIndex });
    console.log({ imagesData })
    const image_data = imagesData[activeImageIndex]

    if (image_data) {
      const {
        labelPoints,
        labelRects,
        labelLines,
        labelPolygons,
        labelNameIds,
        imageId,
        imageStatus,
      } = image_data
      const labels = {
        labelPoints,
        labelRects,
        labelLines,
        labelPolygons,
        labelNameIds,
        imageStatus,
      }

      const isUnannotated =
        image_data.imageStatus !== ImageStatus.UN_ANNOTATED && !hasLabels(image_data)

      const isAnnotated =
        image_data.imageStatus === ImageStatus.UN_ANNOTATED && hasLabels(image_data)

      let currentImageStatus = undefined
      if (isAnnotated) currentImageStatus = ImageStatus.ANNOTATED
      else if (isUnannotated) currentImageStatus = ImageStatus.UN_ANNOTATED
      console.log(
        "Image Data Updated!inside!",
        currentImageStatus,
        isUnannotated,
        isAnnotated,
        image_data
      )

      uploadLabels(imageId, labels, currentImageStatus)
      console.log("to rebuild")
    }
  }, [imagesData])

  const calculateEditorSize = (): ISize => {
    if (windowSize) {
      const leftTabWidth = leftTabStatus
        ? Settings.SIDE_NAVIGATION_BAR_WIDTH_OPEN_PX
        : Settings.SIDE_NAVIGATION_BAR_WIDTH_CLOSED_PX
      const rightTabWidth = rightTabStatus
        ? Settings.SIDE_NAVIGATION_BAR_WIDTH_OPEN_PX
        : Settings.SIDE_NAVIGATION_BAR_WIDTH_CLOSED_PX
      return {
        width: windowSize.width - leftTabWidth - rightTabWidth,
        height:
          windowSize.height -
          Settings.TOP_NAVIGATION_BAR_HEIGHT_PX -
          Settings.EDITOR_BOTTOM_NAVIGATION_BAR_HEIGHT_PX -
          Settings.EDITOR_TOP_NAVIGATION_BAR_HEIGHT_PX,
      }
    } else return null
  }

  const addImageIdForChangeStatus = (imageId) => {
    var selectedImagesTemp = props.selectedImages
    var imageIdIndex = selectedImagesTemp.findIndex((element) => element === imageId)
    if (imageIdIndex === -1) {
      selectedImagesTemp.push(imageId)
    } else {
      selectedImagesTemp.splice(imageIdIndex, 1)
    }
    props.updateSelectedImagesAction(selectedImagesTemp)
  }

  const leftSideBarRender = () => {
    return (
      <ImagesList
        imageDataLoading={imageDataLoading}
        loadImageIndex={loadImageIndex}
        updateLoadImageIndex={updateLoadImageIndex}
        totalImagesSize={totalImagesSize}
        isLoading={isLazyLoading}
        addImageIdForChangeStatus={addImageIdForChangeStatus}
        isChangingActiveIndex={isChangingActiveIndex}
        changeActiveIndex={async (activeImageIndex) => {
          if (!isLazyLoading && !isChangingActiveIndex) {
            setIsChangingActiveIndex(true)
            await updateActiveImageIndexThunkAction(activeImageIndex)
            setIsChangingActiveIndex(false)
          }
        }}
      />
    )
  }

  const rightSideBarButtonOnClick = () => {
    if (!rightTabStatus) ContextManager.switchCtx(ContextType.RIGHT_NAVBAR)
    else if (rightTabStatus && activeContext === ContextType.RIGHT_NAVBAR)
      ContextManager.restoreCtx()

    setRightTabStatus(!rightTabStatus)
  }

  const rightSideBarCompanionRender = () => {
    return (
      <>
        <VerticalEditorButton
          label="Labels"
          image={"/ico/tags.png"}
          imageAlt={"labels"}
          onClick={rightSideBarButtonOnClick}
          isActive={rightTabStatus}
        />
      </>
    )
  }

  const rightSideBarRender = () => {
    return <LabelsToolkit />
  }

  const finalRender = (
    <>
      <QuickActions
        showMenu={props.showMenu}
        updateActiveLabelId={props.updateActiveLabelId}
        updateHighlightedLabelId={props.updateHighlightedLabelId}
        updateActivePopupType={props.updateActivePopupTypeAction}
      />
      <div className="flex px-12 justify-between pt-28 h-full">
        {console.log({ imagesData })}
        <SideNavigationBar
          direction={Direction.LEFT}
          imageDataLoading={imageDataLoading}
          imageData={imagesData[activeImageIndex]}
          isOpen={leftTabStatus}
          isWithContext={activeContext === ContextType.LEFT_NAVBAR}
          renderCompanion={() => <></>}
          renderContent={leftSideBarRender}
          key="left-side-navigation-bar"
          changeImageStatus={props.updateImageStatusAction}
        />
        <div
          className="mx-8 w-full bg-primary flex flex-col h-full"
          onMouseDown={() => ContextManager.switchCtx(ContextType.EDITOR)}
          key="editor-wrapper"
        >
          <div className="w-full flex items-center bg-accent text-white text-sm rounded-t-lg">
            <div className="w-full flex flex-row rounded-t-lg items-center text-sm text-white py-1 pl-2">
              {props.projectData.name}
            </div>
          </div>
          <EditorTopNavigationBar />
          <Editor
            size={calculateEditorSize()}
            imageData={imagesData[activeImageIndex]}
            key="editor"
          />
          <EditorBottomNavigationBar
            imageData={imagesData[activeImageIndex]}
            size={calculateEditorSize()}
            isChangingActiveIndex={isChangingActiveIndex}
            isLazyLoading={isLazyLoading}
            totalImageCount={imagesData.length}
            key="editor-bottom-navigation-bar"
            changeActiveIndex={async (activeImageIndex) => {
              if (!isLazyLoading && !isChangingActiveIndex) {
                setIsChangingActiveIndex(true)
                await updateActiveImageIndexThunkAction(activeImageIndex)
                setIsChangingActiveIndex(false)
              }
            }}
          />
        </div>
        <SideNavigationBar
          direction={Direction.RIGHT}
          imageDataLoading={imageDataLoading}
          imageData={imagesData[activeImageIndex]}
          isOpen={rightTabStatus}
          isWithContext={activeContext === ContextType.RIGHT_NAVBAR}
          renderCompanion={rightSideBarCompanionRender}
          renderContent={rightSideBarRender}
          key="right-side-navigation-bar"
        />
      </div>
    </>
  )

  const loader = (
    <>
      <div className="flex justify-center items-center h-screen">
        <LoadingBar color="#f11946" progress={loading} onLoaderFinished={() => setLoading(0)} />
        <h1 className="text-xl font-extrabold">Loading...</h1>
      </div>
    </>
  )

  return loading ? loader : finalRender
}

const mapDispatchToProps = {
  updateActiveImageIndexThunkAction: updateActiveImageIndexThunk,
  addImageDataAction: addImageData,
  updateImageDataAction: updateImageData,
  updateProjectDataAction: updateProjectData,
  updateActivePopupTypeAction: updateActivePopupType,
  updateActivePopupTypeData: updateActivePopupData,
  updateActiveLabelType: updateActiveLabelType,
  updateLabelNamesAction: updateLabelNames,
  updateHighlightedLabelId,
  updateActiveLabelId,
  updateImageSizeAction: updateImageSize,
  updateSelectedImagesAction: updateSelectedImages,
  updateUserRoleAction: updateUserRole,
  updateImageStatusAction: updateImageStatus,
}

const mapStateToProps = (state: AppState) => ({
  windowSize: state.general.windowSize,
  activeImageIndex: state.labels.activeImageIndex,
  imagesData: state.labels.imagesData,
  activeContext: state.general.activeContext,
  projectData: state.general.projectData,
  showMenu: state.general.showMenu,
  imageSize: state.labels.imageSize,
  selectedImages: state.labels.selectedImages,
  imageStatus: state.labels.imageStatus,
})

export default connect(mapStateToProps, mapDispatchToProps)(EditorContainer)
