import React from "react"
import "./EmptyLabelList.scss"
import { AppState } from "../../../../store"
import { connect } from "react-redux"

interface IProps {
  firstLabelCreatedFlag: boolean
  type: string
}

const EmptyLabelList: React.FC<IProps> = ({ firstLabelCreatedFlag, type }) => {
  const EmptyLabelsIcon = (
    <>
      <div className="flex flex-col items-center justify-center stroke-base-content w-full h-full">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="49.641"
          height="49.64"
          x="0"
          y="0"
          enableBackground="new 0 0 49.641 49.64"
          version="1.1"
          viewBox="0 0 49.641 49.64"
          xmlSpace="preserve"
        >
          <path d="M44.118 11.852c-.008-.014-.022-.021-.03-.034a.631.631 0 00-.109-.122c-.014-.01-.023-.021-.036-.03a.599.599 0 00-.225-.11l-20.65-4.835a.663.663 0 00-.336.015L8.002 11.57c-.042.015-.075.043-.112.065-.024.014-.053.017-.074.033-.006.005-.009.014-.015.019-.006.006-.016.007-.022.014l-7.573 6.81a.627.627 0 00.637 1.052l6.73-2.508v20.163a.62.62 0 00.471.604l19.957 5.092a.657.657 0 00.36-.011l14.935-5.094a.622.622 0 00.424-.591V24.155l5.5-1.904a.625.625 0 00.338-.901l-5.44-9.498zM7.571 15.718l-4.086 1.524 4.086-3.677v2.153zm19.961 25.787l-18.71-4.773V12.978l18.71 5.012v23.515zm.604-24.649l-17.749-4.754 12.568-4.124 18.377 4.302-13.196 4.576zM42.468 36.77l-13.686 4.666V18.815l5.607 8.089a.631.631 0 00.72.236l7.359-2.55v12.18zM35.147 25.8l-5.619-8.104 13.763-4.772 4.805 8.392L35.147 25.8z"></path>
        </svg>
        <p className="mx-auto text-sm px-2 text-center py-2">
          Looks a little bit of empty here. Add your first {type}
        </p>
      </div>
    </>
  )

  return <>{EmptyLabelsIcon}</>
}

const mapDispatchToProps = {}

const mapStateToProps = (state: AppState) => ({
  firstLabelCreatedFlag: state.labels.firstLabelCreatedFlag,
})

export default connect(mapStateToProps, mapDispatchToProps)(EmptyLabelList)
