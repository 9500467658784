import React, { MutableRefObject, useState, useEffect, useRef, PropsWithChildren } from "react"
import { getProjectsByUserIdAndRole, getUserRoleInProjectAPI } from "apis/projects"
import { PopupWindowType } from "data/enums/PopupWindowType"
import { connect } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { AppState } from "store"
import {
  updateActivePopupType,
  updateProjectData,
  updateUserData,
} from "store/general/actionCreators"
import "./DashboardView.scss"
import { Dropdown } from "../../hooks/DropDown"
import { ProjectData, UserData } from "store/general/types"
import { toast } from "react-toastify"
import AddNewButtonSvg from "./AddNewButtonSvg"
import { generatePreSignedGetUrl } from "apis/s3/uploadAndDownload"
import Pagination from "components/Pagination/Pagination"
import { ProjectMongoData } from "interfaces/IProjects"
import ProjectView from "./ProjectView"
import { FetchProjectsThunkProps, fetchProjectsThunk } from "store/thunks/generalThunks"

interface IProps {
  updateActivePopupTypeAction: (activePopupType: PopupWindowType) => any
  updateUserDataAction: (userData: UserData) => any
  updateProjectDataAction: (projectData: ProjectData) => any
  fetchProjectsThunkAction: (props: FetchProjectsThunkProps) => Promise<number>
  projectData: ProjectData
  projectList: ProjectMongoData[]
  userData: UserData
  loadingBarRef: MutableRefObject<any>
}

const DashboardView: React.FC<IProps> = (props: PropsWithChildren<IProps>) => {
  const { userData, projectList, fetchProjectsThunkAction } = props
  const location = useLocation()
  const sortButton = useRef(null)

  const [displayMyProjects, setDisplayMyProject] = useState(true)
  const [loading, setLoading] = useState(false)
  const [sortType, setSortType] = useState<"DATE_DESC" | "DATE_ASC" | "DESC" | "ASC">("ASC")
  const [searchValue, setSearchValue] = useState("")
  const [debouncedSearchValue, setDebouncedSearchValue] = useState("")
  const [sortMenuOpen, setSortMenuOpen] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [currentFold, setCurrentFold] = useState(1)
  const [isTableView, setIsTableView] = useState(false)
  const [totalProjectsSize, setTotalProjectsSize] = useState(0)
  const [recordsPerPage, setRecordsPerPage] = useState(10)
  const nPages = Math.ceil(totalProjectsSize / recordsPerPage)

  useEffect(() => {
    async function loadDashboard() {
      const USER = JSON.parse(localStorage.getItem("userInfo"))
      if (USER) {
        props.updateUserDataAction(USER?.data)
      } else {
        toast.error("User Not Found", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        })
      }
    }
    loadDashboard()
  }, [])

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken")
    setLoading(true)
    fetchProjectsThunkAction({
      userId: userData._id,
      isAdmin: displayMyProjects,
      currentPage: currentPage,
      recordsPerPage: recordsPerPage,
      sortType: sortType,
      searchQuery: searchValue,
      accessToken: accessToken,
    })
      .then((totalProjects) => setTotalProjectsSize(totalProjects))
      .catch((error) => {
        toast.error("Error fetching projects. Please refresh")
        console.log("Error fetching projects", error)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [userData, currentPage, recordsPerPage, sortType, debouncedSearchValue, displayMyProjects])

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchValue(searchValue)
    }, 500)

    return () => {
      clearTimeout(handler)
    }
  }, [searchValue])

  const handleSortButtonClick = () => {
    setSortMenuOpen(!sortMenuOpen)
  }

  const openConfirmInvitationPopup = () => {
    if (location.state && location.state["mailPopup"]) {
      location.state["mailPopup"] = false
      props.updateActivePopupTypeAction(PopupWindowType.CONFIRM_INVITE)
    }
  }

  return (
    <>
      {openConfirmInvitationPopup()}
      <div className="dash bg-base-100 pt-28 h-full flex flex-col">
        <div className="mx-20 pb-8 flex-grow flex flex-col overflow-hidden no-scrollbar">
          <div className="flex justify-between items-center">
            <div className="tabs">
              <p
                onClick={() => setDisplayMyProject(true)}
                className={`tab tab-bordered text-lg ${
                  displayMyProjects ? "tab-active text-base-content" : ""
                }`}
              >
                MY PROJECTS
              </p>
              <p
                onClick={() => setDisplayMyProject(false)}
                className={`tab tab-bordered text-lg ${
                  displayMyProjects ? "" : "tab-active text-base-content"
                }`}
              >
                CONTRIBUTED PROJECTS
              </p>
            </div>
            <div className="flex items-center gap-x-4">
              <label className="input input-bordered flex items-center gap-2">
                <input
                  type="text"
                  placeholder="Search"
                  className="bg-transparent outline-none rounded-lg grow"
                  onChange={(e) => setSearchValue(e.target.value)}
                />
                <i className="bi bi-search"></i>
              </label>
              <div className="flex items-center"></div>
              <Dropdown
                show={sortMenuOpen}
                message={
                  <div className="dropdown dropdown-end" ref={sortButton}>
                    <label
                      tabIndex={0}
                      className="btn m-1 text-primary-content opacity-50 hover:opacity-100 bg-accent-content hover:bg-accent hover:fill-current hover:text-white border-accent-content hover:border-accent disabled:bg-accent-content"
                    >
                      <div className="flex flex-row items-center justify-between">
                        <div className="whitespace-nowrap pr-2 ">{sortType.replace("_", " ")}</div>
                        <div className="flex flex-row ml-2">
                          <i className="bi bi-funnel-fill"></i>
                          <i className="bi bi-arrow-down"></i>
                        </div>
                      </div>
                    </label>
                    <ul
                      tabIndex={0}
                      className="dropdown-content menu p-2 drop-shadow-xl bg-secondary rounded-box w-52"
                    >
                      <li onClick={() => setSortType("ASC")}>
                        <p className="opacity-70 text-primary-content hover:opacity-100">
                          Name Ascending
                        </p>
                      </li>
                      <li onClick={() => setSortType("DESC")}>
                        <p className="opacity-70 text-primary-content hover:opacity-100">
                          Name Descending
                        </p>
                      </li>
                      <li onClick={() => setSortType("DATE_ASC")}>
                        <p className="opacity-70 text-primary-content hover:opacity-100">
                          Date Ascending
                        </p>
                      </li>
                      <li onClick={() => setSortType("DATE_DESC")}>
                        <p className="opacity-70 text-primary-content hover:opacity-100">
                          Date Descending
                        </p>
                      </li>
                    </ul>
                  </div>
                }
                onClickOutside={handleSortButtonClick}
              />
              {isTableView && (
                <button
                  className="btn btn-accent"
                  onClick={() => props.updateActivePopupTypeAction(PopupWindowType.ADD_NEW_PROJECT)}
                >
                  New Project
                  <i className="bi bi-plus"></i>
                </button>
              )}
              <button
                onClick={() => {
                  setIsTableView((prev) => !prev)
                }}
              >
                {isTableView ? (
                  <i className="bi bi-table"></i>
                ) : (
                  <i className="bi bi-card-image"></i>
                )}
              </button>
            </div>
          </div>

          <div className="flex items-center pt-2">
            <p className="text-xs text-base-content w-32">Showing {totalProjectsSize} result(s)</p>
            <div className="divider w-full"></div>
          </div>

          <div className="px-2 flex flex-col overflow-y-scroll no-scrollbar group/grid">
            <ProjectView
              updateActivePopupTypeAction={props.updateActivePopupTypeAction}
              updateProjectDataAction={props.updateProjectDataAction}
              projectData={props.projectData}
              projectList={projectList}
              isTableView={isTableView}
              displayMyProjects={displayMyProjects}
              userData={userData}
              searchValue={searchValue}
              loading={loading}
            />
            <Pagination
              nPages={nPages}
              totalProjectsSize={totalProjectsSize}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              currentFold={currentFold}
              setCurrentFold={setCurrentFold}
              recordsPerPage={recordsPerPage}
              setRecordsPerPage={setRecordsPerPage}
            />
          </div>
        </div>
      </div>
    </>
  )
}

const mapDispatchToProps = {
  updateActivePopupTypeAction: updateActivePopupType,
  updateUserDataAction: updateUserData,
  updateProjectDataAction: updateProjectData,
  fetchProjectsThunkAction: fetchProjectsThunk,
}

const mapStateToProps = (state: AppState) => ({
  projectData: state.general.projectData,
  userData: state.general.userData,
  projectList: state.general.projectList,
})

export default connect(mapStateToProps, mapDispatchToProps)(DashboardView)
