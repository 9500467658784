import React from "react"
import "./PopupView.scss"
import { PopupWindowType } from "../../data/enums/PopupWindowType"
import { AppState } from "../../store"
import { connect } from "react-redux"
import LoadLabelsPopup from "./LoadLabelNamesPopup/LoadLabelNamesPopup"
import InsertLabelNamesPopup from "./InsertLabelNamesPopup/InsertLabelNamesPopup"
import ExitProjectPopup from "./ExitProjectPopup/ExitProjectPopup"
import LoadMoreImagesPopup from "./LoadMoreImagesPopup/LoadMoreImagesPopup"
import { LoadModelPopup } from "./LoadModelPopup/LoadModelPopup"
import SuggestLabelNamesPopup from "./SuggestLabelNamesPopup/SuggestLabelNamesPopup"
import { CSSHelper } from "../../logic/helpers/CSSHelper"
import { ClipLoader } from "react-spinners"
import ImportLabelPopup from "./ImportLabelPopup/ImportLabelPopup"
import ExportLabelPopup from "./ExportLabelsPopup/ExportLabelPopup"
import AddNewProjectPopup from "./AddNewProjectPopup/AddNewProjectPopup"
import ManageProjectMembersPopup from "./ManageProjectMembersPopup/ManageProjectMembersPopup"
import UploadErrorPopup from "./UploadErrorPopup/UploadErrorPopup"
import DeleteProjectPopup from "./DeleteProjectPopup/DeleteProjectPopup"
import ConfirmInvitePopup from "./ConfirmInvitePopup/ConfirmInvitePopup"
import ChangeImageStatusPopUp from "./ChangeImageStatusPopUp/ChangeImageStatusPopup"
import EditProjectPopup from "./EditProjectPopup/EditProjectPopup"
import FeedbackPopup from "./FeedbackPopup/FeedbackPopup"

interface IProps {
  activePopupType: PopupWindowType
}

const PopupView: React.FC<IProps> = ({ activePopupType }) => {
  const selectPopup = () => {
    switch (activePopupType) {
      case PopupWindowType.LOAD_LABEL_NAMES:
        return <LoadLabelsPopup />
      case PopupWindowType.EXPORT_ANNOTATIONS:
        return <ExportLabelPopup />
      case PopupWindowType.IMPORT_ANNOTATIONS:
        return <ImportLabelPopup />
      case PopupWindowType.INSERT_LABEL_NAMES:
        return <InsertLabelNamesPopup isUpdate={false} />
      case PopupWindowType.UPDATE_LABEL:
        return <InsertLabelNamesPopup isUpdate={true} />
      case PopupWindowType.EXIT_PROJECT:
        return <ExitProjectPopup />
      case PopupWindowType.IMPORT_IMAGES:
        return <LoadMoreImagesPopup />
      case PopupWindowType.LOAD_AI_MODEL:
        return <LoadModelPopup />
      case PopupWindowType.SUGGEST_LABEL_NAMES:
        return <SuggestLabelNamesPopup />
      case PopupWindowType.LOADER:
        return <ClipLoader size={50} color={CSSHelper.getLeadingColor()} loading={true} />
      case PopupWindowType.ADD_NEW_PROJECT:
        return <AddNewProjectPopup />
      case PopupWindowType.EDIT_PROJECT:
        return <EditProjectPopup />
      case PopupWindowType.MANAGE_PROJECT_MEMBERS:
        return <ManageProjectMembersPopup />
      case PopupWindowType.UPLOAD_ERROR:
        return <UploadErrorPopup />
      case PopupWindowType.DELETE_PROJECT:
        return <DeleteProjectPopup />
      case PopupWindowType.CONFIRM_INVITE:
        return <ConfirmInvitePopup />
      case PopupWindowType.CHANGE_IMAGE_STATUS:
        return <ChangeImageStatusPopUp />
      case PopupWindowType.FEEDBACK:
        return <FeedbackPopup />
      default:
        return null
    }
  }

  return activePopupType && <div className="PopupView">{selectPopup()}</div>
}

const mapStateToProps = (state: AppState) => ({
  activePopupType: state.general.activePopupType,
})

export default connect(mapStateToProps)(PopupView)
