import ReactDOM from "react-dom"
import configureStore from "./configureStore"
import { Provider } from "react-redux"
import { AppInitializer } from "./logic/initializer/AppInitializer"
import { ToastContainer } from "react-toastify"
import App from "./App"
import "./index.scss"
import "bootstrap-icons/font/bootstrap-icons.css"

export const store = configureStore()
AppInitializer.inti()

if (process.env.REACT_APP_NODE_ENV === "production") {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}

ReactDOM.render(
  <Provider store={store}>
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
    <App />
  </Provider>,
  document.getElementById("root") || document.createElement("div") // fix for testing purposes
)
