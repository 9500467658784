import React, { useContext } from "react"
import { AuthContext } from "contexts/authContext"
import { useFormik } from "formik"
import { useNavigate } from "react-router-dom"
import * as yup from "yup"
import { toast } from "react-toastify"

export const ResetPasswordView = (props: any) => {
  const authContext = useContext(AuthContext)
  const navigator = useNavigate()
  const resetPassword = async (otp, email, password) => {
    try {
      await authContext.forgotPassword(email, otp, password)
      toast.success("Password reset successful")
      navigator("/")
    } catch (err) {
      if (err instanceof Error) {
        toast.error(err.message, {
          position: toast.POSITION.TOP_RIGHT,
        })
        console.log(err.message)
      }
      console.log(err)
      navigator("/requestcode")
    }
  }

  const formik = useFormik({
    initialValues: { password: "", confirmPassword: "" },
    validationSchema: yup.object({
      password: yup
        .string()
        .matches(
          /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
          "Password Should Contains: 1 Lowercase, 1 UpperCase, 1 Number, 1 Special Symbol and Length should be >= 8"
        )
        .required("Password is required"),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref("password"), null], "Passwords must match")
        .required("Confirm Password is required"),
    }),
    onSubmit: (values) => {
      console.log(values)
      console.log(props.state)
      if (!props.state) {
        toast.error("Oops! This page not being redirected from RequestCode page!", {
          position: toast.POSITION.TOP_RIGHT,
        })
        return
      } else {
        resetPassword(props.state["otp"], props.state["email"], values.password)
        return
      }
    },
  })

  return (
    <>
      <div className="flex flex-col justify-center w-full flex-grow items-center">
        <div className="py-1">
          <div className="text-5xl tracking-widest font-black text-accent">
            Change
            <span className="text-5xl ml-3 tracking-widest font-black text-accent-content">
              Password
            </span>
          </div>
        </div>
        <div className="w-3/4 h-full flex flex-col justify-center">
          <p className="text-center my-4">
            Create a new, strong password that you don't use for other websites
          </p>
          <form className="w-full" onSubmit={formik.handleSubmit}>
            <div className="form-control w-full pt-10">
              <label className="label pl-2">
                <span className="label-text font-medium text-sm">
                  {/* Enter the OTP sent to your registered email id */}
                  {/* In order to protect your account, make sure your password: < br/>
                    • Is longer than 7 characters < br/>
                    • Contains atleast one capital letter, small letter, digit and special character < br/>
                    • Does not match or significantly contain your username, e.g. do not use 'username123 < br/> */}
                </span>
              </label>
              <input
                type="password"
                id="password"
                onChange={formik.handleChange}
                placeholder="Enter your new password"
                className="input input-bordered focus:input-accent hover:input-accent rounded-full"
              />

              <label className="label">
                <span className="label-text-alt text-error ">{formik.errors.password}</span>
              </label>

              <input
                type="password"
                id="confirmPassword"
                onChange={formik.handleChange}
                placeholder="Confirm your password"
                className="input input-bordered focus:input-accent hover:input-accent rounded-full"
              />

              <label className="label">
                <span className="label-text-alt text-error ">{formik.errors.confirmPassword}</span>
              </label>
              <div className="pt-4 w-full">
                <button
                  disabled={!(formik.isValid && formik.dirty)}
                  type="submit"
                  className="btn btn-accent w-full rounded-full text-base"
                >
                  Confirm
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
