export enum PopupWindowType {
  LOAD_LABEL_NAMES = "LOAD_LABEL_NAMES",
  UPDATE_LABEL = "UPDATE_LABEL",
  SUGGEST_LABEL_NAMES = "SUGGEST_LABEL_NAMES",
  IMPORT_IMAGES = "IMPORT_IMAGES",
  LOAD_AI_MODEL = "LOAD_AI_MODEL",
  EXPORT_ANNOTATIONS = "EXPORT_ANNOTATIONS",
  IMPORT_ANNOTATIONS = "IMPORT_ANNOTATIONS",
  INSERT_LABEL_NAMES = "INSERT_LABEL_NAMES",
  EXIT_PROJECT = "EXIT_PROJECT",
  LOADER = "LOADER",
  ADD_NEW_PROJECT = "ADD_NEW_PROJECT",
  EDIT_PROJECT = "EDIT_PROJECT",
  MANAGE_PROJECT_MEMBERS = "MANAGE_PROJECT_MEMBERS",
  UPLOAD_ERROR = "UPLOAD_ERROR",
  DELETE_PROJECT = "DELETE_PROJECT",
  CONFIRM_INVITE = "CONFIRM_INVITE",
  CHANGE_IMAGE_STATUS = "CHANGE_IMAGE_STATUS",
  FEEDBACK = "FEEDBACK",
}
