import { getProjectsByUserIdAndRole } from "apis/projects"
import { Action } from "store/Actions"

export interface FetchProjectsThunkProps {
  userId: string
  isAdmin: boolean
  currentPage: number
  recordsPerPage: number
  sortType: "DATE_DESC" | "DATE_ASC" | "DESC" | "ASC"
  searchQuery: string
  accessToken: string
}

export const fetchProjectsThunk = ({
  userId,
  isAdmin,
  currentPage,
  recordsPerPage,
  sortType,
  searchQuery,
  accessToken,
}: FetchProjectsThunkProps) => {
  return async (dispatch) => {
    const { data, totalProjects } = await getProjectsByUserIdAndRole(
      {
        userId,
        isAdmin,
        page: currentPage,
        recordsPerPage,
        sortType,
        searchQuery,
      },
      accessToken
    )
    dispatch({
      type: Action.UPDATE_PROJECT_LIST,
      payload: {
        projectList: data,
      },
    })
    return totalProjects
  }
}
