import AWS from "aws-sdk"
import React, { useState } from "react"
import { blobToURL, urlToBlob, fromBlob, fromURL } from "image-resize-compress" // ES6
require("dotenv").config()

const accessKeyId = `${process.env.REACT_APP_ACCESS_KEY_ID}`
const secretAccessKey = `${process.env.REACT_APP_SECRET_ACCESS_KEY}`

const s3Bucket = `${process.env.REACT_APP_S3_BUCKET}`
const region = `${process.env.REACT_APP_REGION}`
const urlExpirationTime = parseInt(`${process.env.REACT_APP_URL_EXPIRATION_TIME}`) // in seconds

const s3 = new AWS.S3({ region })

export function deleteItemFromBucket(key: string) {
  try {
    const params = { Bucket: s3Bucket, Key: key }
    s3.deleteObject(params, function (err, data) {
      if (err) console.log({ err, err_stack: err.stack }) // an error occurred
      else console.log({ data }) // successful response
    })
  } catch (error) {
    console.log(error)
  }
}

export async function deleteAWSProjectFolder(dir: string) {
  const listParams = {
    Bucket: s3Bucket,
    Prefix: dir,
  }

  const listedObjects = await s3.listObjectsV2(listParams).promise()
  console.log({ listedObjects })

  if (listedObjects.Contents.length === 0) return

  const deleteParams = {
    Bucket: s3Bucket,
    Delete: { Objects: [] },
  }

  listedObjects.Contents.forEach(({ Key }) => {
    deleteParams.Delete.Objects.push({ Key })
  })

  await s3.deleteObjects(deleteParams).promise()

  if (listedObjects.IsTruncated) await deleteAWSProjectFolder(dir)
}
