import { LabelsActionTypes, ImageData, LabelName } from "./types"
import { Action } from "../Actions"
import { LabelType } from "../../data/enums/LabelType"

export function updateActiveImageIndex(activeImageIndex: number): LabelsActionTypes {
  // this function is triggered every time a new image is loaded to the editor.
  // we load labels of this image from the DB everytime this function is called.
  // console.log("New Index:", activeImageIndex)
  // console.log(labelsReducer.imagesData)
  // load labels for that image from the DB and pass them to Redux
  return {
    type: Action.UPDATE_ACTIVE_IMAGE_INDEX,
    payload: {
      activeImageIndex,
    },
  }
}

export function updateActiveLabelNameId(activeLabelNameId: string): LabelsActionTypes {
  return {
    type: Action.UPDATE_ACTIVE_LABEL_NAME_ID,
    payload: {
      activeLabelNameId,
    },
  }
}

export function updateActiveLabelId(activeLabelId: string): LabelsActionTypes {
  return {
    type: Action.UPDATE_ACTIVE_LABEL_ID,
    payload: {
      activeLabelId,
    },
  }
}

export function updateHighlightedLabelId(highlightedLabelId: string): LabelsActionTypes {
  return {
    type: Action.UPDATE_HIGHLIGHTED_LABEL_ID,
    payload: {
      highlightedLabelId,
    },
  }
}

export function updateActiveLabelType(activeLabelType: LabelType): LabelsActionTypes {
  return {
    type: Action.UPDATE_ACTIVE_LABEL_TYPE,
    payload: {
      activeLabelType,
    },
  }
}

export function updateImageDataById(id: string, newImageData: ImageData): LabelsActionTypes {
  return {
    type: Action.UPDATE_IMAGE_DATA_BY_ID,
    payload: {
      id,
      newImageData,
    },
  }
}

export function addImageData(imageData: ImageData[]): LabelsActionTypes {
  // lets look at what data was received by this function
  // console.log(imageData)
  // labels are added to imageData by the time it reaches this function
  return {
    type: Action.ADD_IMAGES_DATA,
    payload: {
      imageData,
    },
  }
}

export function updateImageData(imageData: ImageData[]): LabelsActionTypes {
  return {
    type: Action.UPDATE_IMAGES_DATA,
    payload: {
      imageData,
    },
  }
}

export function updateLabelNames(labels: LabelName[]): LabelsActionTypes {
  return {
    type: Action.UPDATE_LABEL_NAMES,
    payload: {
      labels,
    },
  }
}

export function updateFirstLabelCreatedFlag(firstLabelCreatedFlag: boolean): LabelsActionTypes {
  return {
    type: Action.UPDATE_FIRST_LABEL_CREATED_FLAG,
    payload: {
      firstLabelCreatedFlag,
    },
  }
}

export function updateImageSize(imageSize: number): LabelsActionTypes {
  return {
    type: Action.UPDATE_IMAGE_SIZE,
    payload: {
      imageSize,
    },
  }
}

export function updateSelectedImages(selectedImages: string[]): LabelsActionTypes {
  return {
    type: Action.UPDATE_SELECTED_IMAGES,
    payload: {
      selectedImages,
    },
  }
}

export function updateImageStatus(imageStatus: string): LabelsActionTypes {
  return {
    type: Action.UPDATE_IMAGE_STATUS,
    payload: {
      imageStatus,
    },
  }
}

export function updateHighlightedLabel(highlightedLabel: LabelName): LabelsActionTypes {
  return {
    type: Action.UPDATE_HIGHLIGHTED_LABEL,
    payload: {
      highlightedLabel,
    },
  }
}
