import { ImageData } from "../store/labels/types"
import { v4 as uuidv4 } from "uuid"
import { FileUtil } from "./FileUtil"
import { ImageRepository } from "../logic/imageRepository/ImageRepository"

export class ImageDataUtil {
  public static createImageDataFromFileData(
    fileData: File,
    labels: any,
    imageId?: any,
    imageStatus?: any
  ): ImageData {
    // lets see what this function receives and what it outputs
    // it receives fileData which is great
    // More importantly, it intialises the labels as blanks.
    // We need to pass the labels somehow.
    // console.log(fileData)
    // APPROACH 1: add label info inside fileData (not appealing since we will have to violate the File datatype)
    // APPROACH 2: add labels as an additional param
    const { labelLines, labelPoints, labelRects, labelPolygons, labelNameIds } = labels
    return {
      id: uuidv4(),
      fileData,
      loadStatus: false,
      labelRects,
      labelPoints,
      labelLines,
      labelPolygons,
      labelNameIds,
      isVisitedByObjectDetector: false,
      isVisitedByPoseDetector: false,
      imageId,
      imageStatus,
    }
  }

  public static cleanAnnotations(item: ImageData): ImageData {
    return {
      ...item,
      labelRects: [],
      labelPoints: [],
      labelLines: [],
      labelPolygons: [],
      labelNameIds: [],
    }
  }

  public static arrange(items: ImageData[], idArrangement: string[]): ImageData[] {
    return items.sort((a: ImageData, b: ImageData) => {
      return idArrangement.indexOf(a.id) - idArrangement.indexOf(b.id)
    })
  }

  public static loadMissingImages(images: ImageData[]): Promise<void> {
    return new Promise((resolve, reject) => {
      const missingImages = images.filter((i: ImageData) => !i.loadStatus)
      const missingImagesFiles = missingImages.map((i: ImageData) => i.fileData)
      FileUtil.loadImages(missingImagesFiles)
        .then((htmlImageElements: HTMLImageElement[]) => {
          ImageRepository.storeImages(
            missingImages.map((i: ImageData) => i.id),
            htmlImageElements
          )
          resolve()
        })
        .catch((error: Error) => reject(error))
    })
  }
}
