import { MutableRefObject } from "react"
import { ThemeToggle } from "views/Common/ThemeToggle/ThemeToggle"
import { HomepageBg2 } from "./SvgComponents"
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"
import { blogs } from "data/Blogs"
import Navbar from "components/Navbar/Navbar"
import BlogsCarousel from "./BlogsCarousel"
import SecuritySection from "./SecuritySection"
import AnnotationFormatsSection from "./AnnotationFormats"

interface IProps {
  loadingBarRef: MutableRefObject<any>
}

const HomeView = (props: IProps) => {
  const hostname = window.location.hostname
  const logoSrc = hostname === "easyml.co" ? "easyML.png" : "/upjao-logo.png"

  const ContactSchema = Yup.object().shape({
    name: Yup.string().min(2, "Too Short!").max(50, "Too Long!").required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    message: Yup.string().min(10, "Too Short!").max(300, "Too Long!").required("Required"),
  })

  return (
    <div className="font-sans relative">
      {/* Navigation */}
      <Navbar />
      {/* Header */}
      <div className="overflow-y-auto h-[90vh] w-full scrollbar">
        <div className="px-16">
          <div className="hero px-[3rem]">
            <div className="hero-content justify-between w-full max-w-full flex-col lg:flex-row-reverse">
              <div className="flex items-center justify-end">
                <img src="/homepage_fox_1.svg" className="w-5/6" alt="homepage_fox_1" />
              </div>
              <div className="flex flex-col items-start gap-6 lg:w-3/6">
                <h1 className="text-7xl text-accent font-bold text-start leading-snug">
                  Hey,<br></br> check us out!
                </h1>
                <p className="text-start text-lg w-full">
                  EasyML.co is an image annotation platform designed with a singular focus on making
                  annotation simple, fast, and cost-effective. This web-based tool boasts the title
                  of the easiest annotation platform, offering a delightful user experience (UX)
                  without the need for installations.
                </p>
                <a className="btn btn-md btn-accent" href="/signup">
                  Get Started
                  <i className=" ml-2 bi bi-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>

          {/* Features */}
          <div className="py-10 mt-10 relative" id="key-features">
            <div className="w-full absolute top-0 z-0">
              {/* <HomepageBg1 /> */}
              <img src="/homepage_bg_3.svg" alt="" />
            </div>
            <div className="w-full absolute bottom-0 z-0">
              <HomepageBg2 />
            </div>
            <div className="px-[4rem]">
              <div className="flex">
                <h1 className="text-6xl text-white font-bold text-start z-10 pt-4 pb-16">
                  Key Features{" "}
                </h1>
              </div>
              <div className="grid grid-cols-1 pt-4 md:grid-cols-1 lg:grid-cols-3 gap-20 place-items-center items-stretch">
                {/* Feature Card */}
                <FeatureCard
                  title="Web-Based Platform"
                  description="Completely web-based app, no the need for installations.
                                    Delivers a simple and intuitive UI for efficient image labelling."
                  icon={<i className="bi bi-globe text-6xl text-accent pb-4"></i>}
                />
                <FeatureCard
                  title="Lean Architecture"
                  description="Cost-effective cloud usage due to a lean design structure.
                                    Utilises an efficient AWS backend for optimum cost efficiency."
                  icon={<i className="bi bi-cpu text-6xl text-accent pb-4"></i>}
                />
                <FeatureCard
                  title="Secure Platform"
                  description="Robust security features to protect your data. 
               End-to-end encryption and secure access controls ensure that your sensitive information remains safe."
                  icon={<i className="bi bi-shield-lock text-6xl text-accent pb-4"></i>}
                />
                <div className="w-full min-h-72 h-full flex items-end justify-center z-10">
                  <img src="homepage_fox_2.svg" className="h-4/6" alt="homepage_fox_2"></img>
                </div>
                <FeatureCard
                  title="Multi-User Labelling"
                  description="Facilitates collaboration between users in one project
                                    . Ensures No data leakage and high collaboration"
                  icon={<i className="bi bi-people text-6xl text-accent pb-4"></i>}
                />
                <FeatureCard
                  title="Fast & Beginner Friendly"
                  description="Annotate pictures or photo sets swiftly without software installations.
                                    Ideal for beginners, offering guided annotation with accessible UI."
                  icon={<i className="bi bi-stars text-6xl text-accent pb-4"></i>}
                />
              </div>
            </div>
          </div>
          <SecuritySection />
          <AnnotationFormatsSection />
          <BlogsCarousel />

          {/* Footer */}

          <footer className="footer text-base-content relative py-10" id="footer">
            <img src="homepage_fox_3.svg" className="absolute bottom-10 right-10" alt=""></img>
            <div className="flex border-2 border-accent rounded-xl w-full items-stretch">
              <a href="/" className="w-full lg:w-3/6 flex items-center justify-center">
                <img width="280px" draggable={false} alt="logo" src={logoSrc} />
              </a>
              <div className="w-full lg:w-3/6 flex flex-col justify-between bg-accent p-8">
                <div className="w-2/3 p-8">
                  <Formik
                    initialValues={{ name: "", email: "", message: "" }}
                    validationSchema={ContactSchema}
                    onSubmit={(values, actions) => {
                      console.log(values)
                      // Here you would usually send the form data to your server
                      // Reset the form after submission
                      actions.resetForm()
                    }}
                  >
                    {({ errors, touched }) => (
                      <Form className="form-control w-full">
                        <label className="label">
                          <span className="label-text text-white text-2xl font-bold">
                            Contact Us
                          </span>
                        </label>
                        <div className="flex gap-2 flex-col w-full">
                          <Field
                            name="name"
                            type="text"
                            placeholder="Your Name"
                            className={`input input-bordered w-full ${
                              errors.name && touched.name ? "input-error" : ""
                            }`}
                          />
                          <ErrorMessage name="name" component="div" className="text-error" />

                          <Field
                            name="email"
                            type="email"
                            placeholder="Your Email"
                            className={`input input-bordered w-full ${
                              errors.email && touched.email ? "input-error" : ""
                            }`}
                          />
                          <ErrorMessage name="email" component="div" className="text-error" />

                          <Field
                            as="textarea"
                            name="message"
                            row={4}
                            placeholder="Your Message"
                            className={`textarea textarea-bordered w-full ${
                              errors.message && touched.message ? "textarea-error" : ""
                            }`}
                          />
                          <ErrorMessage name="message" component="div" className="text-error" />

                          <button type="submit" className="btn btn-primary mt-4">
                            Send
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </div>
  )
}

const FeatureCard = ({ title, description, icon }) => {
  return (
    <div className="card flex flex-col justify-between w-full min-h-72 h-full bg-base-200 shadow-xl">
      <div className="card-body flex flex-col items-start justify-center">
        {icon}
        <h2 className="card-title text-primary-content text-left">{title}</h2>
        <p className="text-left">{description}</p>
      </div>
    </div>
  )
}

export default HomeView
