import React from "react"
import "./SizeItUpView.scss"
import { Settings } from "../../settings/Settings"

export const SizeItUpView: React.FC = () => {
  return (
    <div className=" h-screen w-screen flex flex-col justify-center items-center">
      <p className="text-white font-bold">Oops... This window is to tight for me!</p>
      <img
        className="h-2/5 p-8"
        draggable={false}
        alt={"small_window"}
        src={"/resize-illustration.svg"}
      />
      <p className="text-white font-bold">
        Please... make it at least {Settings.EDITOR_MIN_WIDTH} x {Settings.EDITOR_MIN_HEIGHT} px.
      </p>
    </div>
  )
}
