import React, { useEffect, useState, useRef } from "react"
// ROUTING
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom"
// STYLESHEETS
import "./App.scss"
// VIEWS
import EditorView from "./views/EditorView/EditorView"
import PopupView from "./views/PopupView/PopupView"
import { SizeItUpView } from "./views/SizeItUpView/SizeItUpView"
import NotificationsView from "./views/NotificationsView/NotificationsView"
import ConfirmInvitationView from "./views/ConfirmInvitationView/ConfirmInvitationView"
import NotFoundView from "./views/NotFoundView/NotFoundView"
import { TopNavigation } from "views/EditorView/TopNavigationBar/TopNavigationBar"
import { SigninView } from "views/Auth/SigninView/SigninView"
import { SignupView } from "views/Auth/SignupView/SignupView"
import { RequestCodeView } from "views/Auth/RequestCodeView/RequestCodeView"
// LOGIN AUTHENTICATION
import AuthProvider, { AuthIsSignedIn, AuthIsNotSignedIn } from "./contexts/authContext"
// MISC
import { AppState } from "./store"
import { connect, useSelector } from "react-redux"
import { Settings } from "./settings/Settings"
import DashboardView from "views/DashboardView/DashboardView"
import LoadingBar from "react-top-loading-bar"
import UpgradePlanView from "views/UpgradePlanView/UpgradePlanView"
import HomeView from "views/HomeView/HomeView"
import CropDiseaseDetection from "views/Blogs/CropDiseaseDetection"
import HarvestOptimization from "views/Blogs/HarvestOptimization"
import CropGradingAndDiseaseDetection from "views/Blogs/CropGradingAndDiseaseDetection"
import LivestockMonitoring from "views/Blogs/LivestockMonitoring"
import PestDetectionAndPrevention from "views/Blogs/PestDetectionAndPrevention"
import LandManagementOptimization from "views/Blogs/LandManagementOptimization"
import PPEDetection from "views/Blogs/PPEDetection"
import RemoteConstructionMonitoring from "views/Blogs/RemoteConstructionMonitoring"
import AssemblyLineMonitoring from "views/Blogs/AssemblyLineMonitoring"
import WorkplaceAccidentPrevention from "views/Blogs/WorkplaceAccidentPrevention"
import DataAnnotationInAI from "views/Blogs/DataAnnotationInAI"
require("dotenv").config()

interface IProps {
  ObjectDetectorLoaded: boolean
  PoseDetectionLoaded: boolean
}

const App: React.FC<IProps> = ({ ObjectDetectorLoaded, PoseDetectionLoaded }) => {
  const [isSmallWindow, setIsSmallWindow] = useState(false)
  const loadingBarRef = useRef(null)

  useEffect(() => {
    function handleResize() {
      if (
        window.innerHeight < Settings.EDITOR_MIN_HEIGHT ||
        window.innerWidth < Settings.EDITOR_MIN_WIDTH
      ) {
        if (!isSmallWindow) setIsSmallWindow(true)
      } else {
        if (!!isSmallWindow) setIsSmallWindow(false)
      }
    }

    handleResize()

    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [isSmallWindow])

  const selectRoute = () => {
    if (isSmallWindow) return <SizeItUpView />
    return (
      <Router>
        <AuthProvider>
          <AuthIsNotSignedIn>
            <SignInRoute />
          </AuthIsNotSignedIn>
          <AuthIsSignedIn>
            <AuthRoute />
          </AuthIsSignedIn>
        </AuthProvider>
      </Router>
    )
  }

  const SignInRoute = () => {
    const loadingBarRef = useRef(null) // Assuming this ref is defined and used as needed

    return (
      <>
        <Routes>
          <Route path="/" element={<HomeView loadingBarRef={loadingBarRef} />} />
          <Route path="/signin" element={<SigninView loadingBarRef={loadingBarRef} />} />
          <Route path="/signup" element={<SignupView loadingBarRef={loadingBarRef} />} />
          <Route path="/requestcode" element={<RequestCodeView loadingBarRef={loadingBarRef} />} />
          <Route
            path="/accept-invitation"
            element={<ConfirmInvitationView loadingBarRef={loadingBarRef} />}
          />
          {/* Blogs */}
          <Route path="/blogs/DataAnnotationInAI" element={<DataAnnotationInAI />} />
          <Route path="/blogs/CropDiseaseDetection" element={<CropDiseaseDetection />} />
          <Route path="/blogs/HarvestOptimization" element={<HarvestOptimization />} />
          <Route
            path="/blogs/CropGradingAndDiseaseDetection"
            element={<CropGradingAndDiseaseDetection />}
          />
          <Route path="/blogs/LivestockMonitoring" element={<LivestockMonitoring />} />
          <Route
            path="/blogs/PestDetectionAndPrevention"
            element={<PestDetectionAndPrevention />}
          />
          <Route
            path="/blogs/LandManagementOptimization"
            element={<LandManagementOptimization />}
          />
          <Route
            path="/blogs/RemoteConstructionMonitoring"
            element={<RemoteConstructionMonitoring />}
          />
          <Route path="/blogs/AssemblyLineMonitoring" element={<AssemblyLineMonitoring />} />
          <Route path="/blogs/PPEDetection" element={<PPEDetection />} />
          <Route
            path="/blogs/WorkplaceAccidentPrevention"
            element={<WorkplaceAccidentPrevention />}
          />

          <Route path="/*" element={<NotFoundView loadingBarRef={loadingBarRef} />} />
        </Routes>
      </>
    )
  }

  const AuthRoute = () => {
    // console.log("Auth Route")
    useEffect(() => {
      // Set the loading bar's progress
      if (loadingBarRef.current) {
        loadingBarRef.current.complete() // Complete the loading bar animation
      }
    }, [])

    return (
      <>
        <PopupView />
        <TopNavigation loadingBarRef={loadingBarRef} />
        <Routes>
          <Route
            path="/projects/:projectId"
            element={
              <>
                <EditorView loadingBarRef={loadingBarRef} />
                <NotificationsView />
              </>
            }
          />
          <Route path="/dashboard" element={<DashboardView loadingBarRef={loadingBarRef} />} />
          <Route path="/" element={<DashboardView loadingBarRef={loadingBarRef} />} />
          <Route
            path="/accept-invitation"
            element={<ConfirmInvitationView loadingBarRef={loadingBarRef} />}
          />
          {/* <Route path="/upgrade-plan" element={<UpgradePlanView loadingBarRef={loadingBarRef} />} /> */}
          <Route path="/*" element={<NotFoundView loadingBarRef={loadingBarRef} />} />
        </Routes>
      </>
    )
  }

  return (
    <div className="h-full flex flex-col bg-base-100" draggable={false}>
      <LoadingBar ref={loadingBarRef} />
      {selectRoute()}
    </div>
  )
}

const mapStateToProps = (state: AppState) => ({
  ObjectDetectorLoaded: state.ai.isObjectDetectorLoaded,
  PoseDetectionLoaded: state.ai.isPoseDetectorLoaded,
})

export default connect(mapStateToProps)(App)
