/****
 * @name: PrimaryEditorRenderEngine
 * @author: Sudipta Dey, Samarth Naphade
 * @description: This component contains the entire logic for the rendering the image. It contains all the code which helps us to zoom in, zoom out,
 *               drag the render image. This component also contains the logic for rendering the cross hair on the image. To know how the real world
 *               mouse coordinates are transformed to zoomed canvas coordinates, you can visit https://roblouie.com/article/617/transforming-mouse-coordinates-to-canvas-coordinates.
 *               For understanding the logic for zooming on mouse, you can visit https://stackoverflow.com/a/68247894/6603833
 *
 *  @summary: This component is basically deals with the rendering of image on the canvas.
 */

import { IRect } from "../../interfaces/IRect"
import { BaseRenderEngine } from "./BaseRenderEngine"
import { EditorData } from "../../data/EditorData"
import { EditorModel } from "../../staticModels/EditorModel"
import { ViewPortActions } from "../actions/ViewPortActions"
import { DrawUtil } from "../../utils/DrawUtil"
import { RenderEngineUtil } from "../../utils/RenderEngineUtil"
import { RenderEngineSettings } from "../../settings/RenderEngineSettings"
import { IPoint } from "../../interfaces/IPoint"
import { GeneralSelector } from "../../store/selectors/GeneralSelector"
import { ProjectType } from "../../data/enums/ProjectType"
import { PopupWindowType } from "../../data/enums/PopupWindowType"
import { Direction } from "../../data/enums/Direction"

export class PrimaryEditorRenderEngine extends BaseRenderEngine {
  public static givenImageHeight: number
  public static givenImageWidth: number
  public static xPosition: number
  public static yPosition: number
  public static canvasElement: HTMLCanvasElement
  public static initialPositionX: number = 0
  public static initialPositionY: number = 0
  public static scale: number = 1
  public static scaleBy: number = 1.1
  public static noOfTimeZoomed: number = 0

  public constructor(canvas: HTMLCanvasElement) {
    super(canvas)
  }

  // =================================================================================================================
  // EVENT HANDLERS
  // =================================================================================================================

  public mouseMoveHandler(data: EditorData): void {}
  public mouseDownHandler(data: EditorData): void {}
  public mouseUpHandler(data: EditorData): void {}

  // =================================================================================================================
  // RENDERING
  // =================================================================================================================

  public render(data: EditorData): void {
    if (PrimaryEditorRenderEngine.givenImageWidth) {
      var coordinates = ViewPortActions.calculateViewPortContentImageRect()
      PrimaryEditorRenderEngine.initialPositionX = coordinates?.x
      PrimaryEditorRenderEngine.initialPositionY = coordinates?.y
    }

    //to maintain the ratio of the image
    var newImgWidth = PrimaryEditorRenderEngine.givenImageWidth
    var newImgHeight = PrimaryEditorRenderEngine.givenImageHeight
    try {
      let scaleFactor = 1
      if (
        data.realImageSize.width > data.viewPortSize.width ||
        data.realImageSize.height > data.viewPortSize.height
      )
        scaleFactor = Math.min(
          data.viewPortSize.width / data.realImageSize.width,
          data.viewPortSize.height / data.realImageSize.height
        )

      newImgWidth = data.realImageSize.width * scaleFactor
      newImgHeight = data.realImageSize.height * scaleFactor
    } catch (error) {
      console.log("error", error)
    }

    this.drawImage(
      EditorModel.image,
      PrimaryEditorRenderEngine.givenImageWidth
        ? {
            x: PrimaryEditorRenderEngine.xPosition,
            y: PrimaryEditorRenderEngine.yPosition,
            width: newImgWidth,
            height: newImgHeight,
          }
        : ViewPortActions.calculateViewPortContentImageRect()
    )
    this.renderCrossHair(data)
  }

  public renderCrossHair(data: EditorData): void {
    if (!this.shouldRenderCrossHair(data)) return

    const mouse = RenderEngineUtil.setPointBetweenPixels(data.mousePositionOnViewPortContent)
    const drawLine = (startPoint: IPoint, endPoint: IPoint) => {
      DrawUtil.drawLine(
        this.canvas,
        startPoint,
        endPoint,
        RenderEngineSettings.CROSS_HAIR_LINE_COLOR,
        4
      )
    }
    drawLine(
      { x: mouse.x, y: 0 },
      { x: mouse.x - 1, y: mouse.y - RenderEngineSettings.crossHairPadding }
    )
    drawLine(
      { x: mouse.x, y: mouse.y + RenderEngineSettings.crossHairPadding },
      { x: mouse.x - 1, y: data.viewPortContentSize.height }
    )
    drawLine(
      { x: 0, y: mouse.y },
      { x: mouse.x - RenderEngineSettings.crossHairPadding, y: mouse.y - 1 }
    )
    drawLine(
      { x: mouse.x + RenderEngineSettings.crossHairPadding, y: mouse.y },
      { x: data.viewPortContentSize.width, y: mouse.y - 1 }
    )
  }

  public getTransformedPoint(x, y) {
    const ctx = EditorModel.canvas.getContext("2d")
    const originalPoint = new DOMPoint(x, y)
    return ctx.getTransform().invertSelf().transformPoint(originalPoint)
  }

  public shouldRenderCrossHair(data: EditorData): boolean {
    const isCrossHairVisible = GeneralSelector.getCrossHairVisibleStatus()
    const isImageInDragMode = GeneralSelector.getImageDragModeStatus()
    const projectType: ProjectType = GeneralSelector.getProjectType()
    const activePopupType: PopupWindowType = GeneralSelector.getActivePopupType()
    const isMouseOverCanvas: boolean = RenderEngineUtil.isMouseOverCanvas(data)
    const isCustomCursorBlocked = GeneralSelector.getPreventCustomCursorStatus()

    return [
      !!this.canvas,
      isCrossHairVisible,
      !isImageInDragMode,
      projectType !== ProjectType.IMAGE_RECOGNITION,
      !activePopupType,
      isMouseOverCanvas,
      !isCustomCursorBlocked,
    ].every(Boolean)
  }

  public drawImage(image: HTMLImageElement, imageRect: IRect) {
    if (!!image && !!this.canvas) {
      // console.log("Image and Canvas both exist!")

      const ctx = this.canvas.getContext("2d")
      var hRatio = this.canvas.width / imageRect.width
      var vRatio = this.canvas.height / imageRect.height
      var ratio = 1
      console.log("here", imageRect.x, imageRect.y, imageRect.width, imageRect.height)
      // ctx.drawImage(image, imageRect.x, imageRect.y, imageRect.width, imageRect.height);
      PrimaryEditorRenderEngine.givenImageHeight = imageRect.height
      PrimaryEditorRenderEngine.givenImageWidth = imageRect.width
      PrimaryEditorRenderEngine.xPosition = imageRect.x
      PrimaryEditorRenderEngine.yPosition = imageRect.y
      ctx.drawImage(image, image.x, image.y, imageRect.width, imageRect.height)
    }
  }

  public zoomIn(image: any, mouseLocation: any) {
    const ctx = this.canvas.getContext("2d")
    DrawUtil.clearCanvas(this.canvas)
    var origin
    if (PrimaryEditorRenderEngine.scale <= 1) {
      origin = { x: 0, y: 0 } // canvas origin
    } else {
      origin = { x: PrimaryEditorRenderEngine.xPosition, y: PrimaryEditorRenderEngine.yPosition } // canvas origin
    }
    PrimaryEditorRenderEngine.scale *= PrimaryEditorRenderEngine.scaleBy
    var x = mouseLocation.x
    var y = mouseLocation.y
    origin.x = x - (x - origin.x) * PrimaryEditorRenderEngine.scaleBy
    origin.y = y - (y - origin.y) * PrimaryEditorRenderEngine.scaleBy
    ctx.setTransform(
      PrimaryEditorRenderEngine.scale,
      0,
      0,
      PrimaryEditorRenderEngine.scale,
      origin.x,
      origin.y
    )
    PrimaryEditorRenderEngine.xPosition = origin.x
    PrimaryEditorRenderEngine.yPosition = origin.y
    PrimaryEditorRenderEngine.noOfTimeZoomed += 1
    this.drawImage(image, {
      x: origin.x,
      y: origin.y,
      height: PrimaryEditorRenderEngine.givenImageHeight,
      width: PrimaryEditorRenderEngine.givenImageWidth,
    })
    // PrimaryEditorRenderEngine.givenImageHeight = PrimaryEditorRenderEngine.givenImageHeight * 1.05
    // PrimaryEditorRenderEngine.givenImageWidth = PrimaryEditorRenderEngine.givenImageWidth * 1.05;
    // PrimaryEditorRenderEngine.xPosition = mouseLocation.x
    // PrimaryEditorRenderEngine.yPosition = mouseLocation.y
    // PrimaryEditorRenderEngine.xPosition = (mouseLocation.x * 1.05) + PrimaryEditorRenderEngine.initialPositionX;
    // PrimaryEditorRenderEngine.yPosition = (mouseLocation.y * 1.05) + PrimaryEditorRenderEngine.initialPositionY;
    // PrimaryEditorRenderEngine.initialPositionX = PrimaryEditorRenderEngine.xPosition;
    // PrimaryEditorRenderEngine.initialPositionY = PrimaryEditorRenderEngine.yPosition;
    // ctx.drawImage(image, PrimaryEditorRenderEngine.xPosition, PrimaryEditorRenderEngine.yPosition, PrimaryEditorRenderEngine.givenImageWidth, PrimaryEditorRenderEngine.givenImageHeight);
  }

  public zoomOut(image: any, mouseLocation: any) {
    const ctx = this.canvas.getContext("2d")
    DrawUtil.clearCanvas(this.canvas)

    if (PrimaryEditorRenderEngine.scale <= 1) {
      this.setDefaultZoom(image)
      return
    }
    var origin = { x: PrimaryEditorRenderEngine.xPosition, y: PrimaryEditorRenderEngine.yPosition } // canvas origin
    PrimaryEditorRenderEngine.scale /= PrimaryEditorRenderEngine.scaleBy
    var x = mouseLocation.x
    var y = mouseLocation.y
    origin.x = x - ((x - origin.x) * 1) / PrimaryEditorRenderEngine.scaleBy
    origin.y = y - ((y - origin.y) * 1) / PrimaryEditorRenderEngine.scaleBy
    ctx.setTransform(
      PrimaryEditorRenderEngine.scale,
      0,
      0,
      PrimaryEditorRenderEngine.scale,
      origin.x,
      origin.y
    )
    PrimaryEditorRenderEngine.xPosition = origin.x
    PrimaryEditorRenderEngine.yPosition = origin.y
    PrimaryEditorRenderEngine.noOfTimeZoomed -= 1
    // ctx.setTransform(1, 0, 0, 1, 0, 0); // Restore to Default

    // PrimaryEditorRenderEngine.givenImageHeight = (PrimaryEditorRenderEngine.givenImageHeight / PrimaryEditorRenderEngine.givenImageWidth) * PrimaryEditorRenderEngine.givenImageWidth * 0.95
    // PrimaryEditorRenderEngine.givenImageWidth = PrimaryEditorRenderEngine.givenImageWidth * 0.95;
    // this.drawImage(image, {x: 0, y: 0, height: PrimaryEditorRenderEngine.givenImageHeight, width: PrimaryEditorRenderEngine.givenImageWidth});
    this.drawImage(image, {
      x: origin.x,
      y: origin.y,
      height: PrimaryEditorRenderEngine.givenImageHeight,
      width: PrimaryEditorRenderEngine.givenImageWidth,
    })
  }

  private newLeftCoordinate(changeValue) {
    PrimaryEditorRenderEngine.xPosition = PrimaryEditorRenderEngine.xPosition + changeValue
    PrimaryEditorRenderEngine.initialPositionX = PrimaryEditorRenderEngine.xPosition
  }
  private newRightCoordinate(changeValue) {
    PrimaryEditorRenderEngine.xPosition = PrimaryEditorRenderEngine.xPosition - changeValue
    PrimaryEditorRenderEngine.initialPositionX = PrimaryEditorRenderEngine.xPosition
  }
  private newDownCoordinate(changeValue) {
    PrimaryEditorRenderEngine.yPosition = PrimaryEditorRenderEngine.yPosition + changeValue
    PrimaryEditorRenderEngine.initialPositionY = PrimaryEditorRenderEngine.yPosition
  }
  private newUpCoordinate(changeValue) {
    PrimaryEditorRenderEngine.yPosition = PrimaryEditorRenderEngine.yPosition - changeValue
    PrimaryEditorRenderEngine.initialPositionY = PrimaryEditorRenderEngine.yPosition
  }

  public moveZoomSection(image: any, direction: Direction, changeValue) {
    const ctx = this.canvas.getContext("2d")
    DrawUtil.clearCanvas(this.canvas)
    var changeHeight = 0
    var changeWidth = 0
    if (changeValue == null) {
      changeHeight = EditorModel.canvas.height * 0.05
      changeWidth = EditorModel.canvas.width * 0.05
    } else {
      changeHeight = changeValue
      changeWidth = changeValue
    }
    // if(PrimaryEditorRenderEngine.scale !== 1){
    //     x_value = PrimaryEditorRenderEngine.zoomXPosition;
    //     y_value = PrimaryEditorRenderEngine.zoomYPosition;
    // }
    var coordinates = this.getTransformedPoint(0, 0)
    console.log(
      "dads",
      direction,
      coordinates,
      "dsdd",
      PrimaryEditorRenderEngine.givenImageWidth / 2 +
        PrimaryEditorRenderEngine.givenImageWidth *
          0.0371 *
          PrimaryEditorRenderEngine.noOfTimeZoomed,
      PrimaryEditorRenderEngine.noOfTimeZoomed,
      {
        x: PrimaryEditorRenderEngine.xPosition,
        y: PrimaryEditorRenderEngine.yPosition,
        height: PrimaryEditorRenderEngine.givenImageHeight,
        width: PrimaryEditorRenderEngine.givenImageWidth,
        scale: PrimaryEditorRenderEngine.scale,
      }
    )
    if (
      direction === Direction.RIGHT &&
      Math.abs(coordinates.x) >=
        Math.abs(PrimaryEditorRenderEngine.givenImageWidth - Math.abs(coordinates.x)) *
          (PrimaryEditorRenderEngine.scale - 1)
    ) {
      console.log(
        "dads",
        "Called RIGHT",
        Math.abs(PrimaryEditorRenderEngine.givenImageWidth) * PrimaryEditorRenderEngine.scale - 1
      )
      return
    }
    if (
      direction === Direction.TOP &&
      Math.abs(coordinates.y) >
        Math.abs(PrimaryEditorRenderEngine.givenImageHeight - Math.abs(coordinates.y)) *
          (PrimaryEditorRenderEngine.scale - 1)
    ) {
      console.log("dads", "Called  TOP")
      return
    }
    if (direction === Direction.BOTTOM && coordinates.y < 0) {
      console.log("dads", "Called  BOTTOM", coordinates.y)
      return
    }
    if (direction === Direction.LEFT && coordinates.x < 0) {
      console.log("dads", "Called  BOTTOM", coordinates.y)
      return
    }
    if (direction === Direction.LEFT) this.newLeftCoordinate(changeWidth)
    if (direction === Direction.RIGHT) this.newRightCoordinate(changeWidth)
    if (direction === Direction.TOP) this.newUpCoordinate(changeHeight)
    if (direction === Direction.BOTTOM) this.newDownCoordinate(changeHeight)
    // ctx.drawImage(image,PrimaryEditorRenderEngine.xPosition, PrimaryEditorRenderEngine.yPosition);
    ctx.setTransform(
      PrimaryEditorRenderEngine.scale,
      0,
      0,
      PrimaryEditorRenderEngine.scale,
      PrimaryEditorRenderEngine.xPosition,
      PrimaryEditorRenderEngine.yPosition
    )
    this.drawImage(image, {
      x: PrimaryEditorRenderEngine.xPosition,
      y: PrimaryEditorRenderEngine.yPosition,
      height: PrimaryEditorRenderEngine.givenImageHeight,
      width: PrimaryEditorRenderEngine.givenImageWidth,
    })
  }

  setDefaultZoom(image: any) {
    const ctx = this.canvas.getContext("2d")
    DrawUtil.clearCanvas(this.canvas)
    ctx.setTransform(1, 0, 0, 1, 0, 0) // Restore to Default
    PrimaryEditorRenderEngine.scale = 1
    PrimaryEditorRenderEngine.xPosition = 0
    PrimaryEditorRenderEngine.yPosition = 0
    PrimaryEditorRenderEngine.noOfTimeZoomed = 0
    this.drawImage(image, {
      x: 0,
      y: 0,
      height: PrimaryEditorRenderEngine.givenImageHeight,
      width: PrimaryEditorRenderEngine.givenImageWidth,
    })
  }

  isInProgress(): boolean {
    return false
  }
}
