import { filter, reject, sample, uniq } from "lodash"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { toast } from "react-toastify"
import { updateLabels } from "../../../apis/projects"
import { PopupWindowType } from "../../../data/enums/PopupWindowType"
import { ProjectType } from "../../../data/enums/ProjectType"
import { LabelActions } from "../../../logic/actions/LabelActions"
import { Settings } from "../../../settings/Settings"
import { AppState } from "../../../store"
import {
  updateActivePopupType,
  updatePerClassColorationStatus,
} from "../../../store/general/actionCreators"
import { updateLabelNames } from "../../../store/labels/actionCreators"
import { LabelName } from "../../../store/labels/types"
import { submitNewNotification } from "../../../store/notifications/actionCreators"
import { INotification } from "../../../store/notifications/types"
import { LabelsSelector } from "../../../store/selectors/LabelsSelector"
import { LabelUtil } from "../../../utils/LabelUtil"
import { GenericYesNoPopup } from "../GenericYesNoPopup/GenericYesNoPopup"
import { ColorSelectorView } from "./ColorSelectorView/ColorSelectorView"
import "./InsertLabelNamesPopup.scss"

interface IProps {
  updateActivePopupTypeAction: (activePopupType: PopupWindowType) => any
  updateLabelNamesAction: (labels: LabelName[]) => any
  updatePerClassColorationStatusAction: (updatePerClassColoration: boolean) => any
  submitNewNotificationAction: (notification: INotification) => any
  isUpdate: boolean
  projectType: ProjectType
  projectId: string
  enablePerClassColoration: boolean
}

const InsertLabelNamesPopup: React.FC<IProps> = ({
  updateActivePopupTypeAction,
  updateLabelNamesAction,
  updatePerClassColorationStatusAction,
  submitNewNotificationAction,
  isUpdate,
  projectType,
  projectId,
  enablePerClassColoration,
}) => {
  const [labelNames, setLabelNames] = useState(LabelsSelector.getLabelNames())

  useEffect(() => {
    async function uploadLabels(imageId: any, labels: any) {
      console.log(imageId)
      // setLoading(true)
      const accessToken: string = localStorage.getItem("accessToken")
      const uploadInfo = await updateLabels(imageId, labels, accessToken)
      if (uploadInfo.statusCode === 200) {
        console.log("Labels Updated Successfully!")
        // setLoading(false)
      }
    }
    console.log("Image Data Updated!!")
    if (labelNames && labelNames.length !== 0) {
      uploadLabels(projectId, labelNames)
    }
  }, [labelNames])

  const validateEmptyLabelNames = (): boolean => {
    const emptyLabelNames = filter(labelNames, (labelName: LabelName) => labelName.name === "")
    return emptyLabelNames.length === 0
  }

  const validateNonUniqueLabelNames = (): boolean => {
    const uniqueLabelNames = uniq(labelNames.map((labelName: LabelName) => labelName.name))
    return uniqueLabelNames.length === labelNames.length
  }

  const callbackWithLabelNamesValidation = (callback: () => any): (() => any) => {
    return () => {
      if (!validateEmptyLabelNames()) {
        toast.error(
          <>
            <div className="text-lg text-error font-bold">Empty label name</div>
            <div>
              Looks like you didn't assign name to one of your labels. Unfortunately it is mandatory
              for every label to have unique name value. Insert correct name or delete empty label
              and try again
            </div>
          </>,
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        )
        return
      }
      if (validateNonUniqueLabelNames()) {
        callback()
      } else {
        toast.error(
          <>
            <div className="text-lg text-error font-bold">Non unique label names</div>
            <div>
              Looks like not all your label names are unique. Unique names are necessary to
              guarantee correct data export when you complete your work. Make your names unique and
              try again.
            </div>
          </>,
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        )
      }
    }
  }

  const addLabelNameCallback = () => {
    const newLabelNames = [...labelNames, LabelUtil.createLabelName("")]
    setLabelNames(newLabelNames)
  }

  const safeAddLabelNameCallback = () => callbackWithLabelNamesValidation(addLabelNameCallback)()

  const deleteLabelNameCallback = (id: string) => {
    const newLabelNames = reject(labelNames, { id })
    setLabelNames(newLabelNames)
  }

  const togglePerClassColorationCallback = () => {
    updatePerClassColorationStatusAction(!enablePerClassColoration)
  }

  const changeLabelNameColorCallback = (id: string) => {
    const newLabelNames = labelNames.map((labelName: LabelName) => {
      return labelName.id === id
        ? { ...labelName, color: sample(Settings.LABEL_COLORS_PALETTE) }
        : labelName
    })
    setLabelNames(newLabelNames)
  }

  const onKeyUpCallback = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      safeAddLabelNameCallback()
      event.preventDefault()
    }
  }

  const labelInputs = labelNames.map((labelName: LabelName) => {
    const onChangeCallback = (event: React.ChangeEvent<HTMLInputElement>) =>
      onChange(labelName.id, event.target.value)
    const onDeleteCallback = () => deleteLabelNameCallback(labelName.id)
    const onChangeColorCallback = () => changeLabelNameColorCallback(labelName.id)
    return (
      <div className="" key={labelName.id}>
        <label className="label">
          <span className="label-text font-medium text-base text-primary-content">
            {"Insert Label"}
          </span>
        </label>
        <div className="w-full flex">
          <input
            id={"key"}
            autoComplete={"off"}
            autoFocus={true}
            type={"text"}
            onKeyDown={onKeyUpCallback}
            value={labelName.name}
            onChange={onChangeCallback}
            className="input input-bordered w-full bg-secondary focus:input-accent hover:input-accent rounded-full"
          />
          {
            <div className="flex items-center ml-4">
              {projectType === ProjectType.OBJECT_DETECTION && enablePerClassColoration && (
                <ColorSelectorView color={labelName.color} onClick={onChangeColorCallback} />
              )}
            </div>
          }
          <button
            onClick={onDeleteCallback}
            className=" ml-3 btn btn-circle bg-accent-content hover:bg-accent hover:fill-current border-accent-content hover:border-accent-content disabled:bg-accent-content"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              className="bi bi-trash3-fill"
              viewBox="0 0 16 16"
            >
              <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
            </svg>
          </button>
        </div>
      </div>
    )
  })

  const onChange = (id: string, value: string) => {
    const newLabelNames = labelNames.map((labelName: LabelName) => {
      return labelName.id === id
        ? {
            ...labelName,
            name: value,
          }
        : labelName
    })
    setLabelNames(newLabelNames)
  }
  const onCreateAcceptCallback = () => {
    const nonEmptyLabelNames: LabelName[] = reject(
      labelNames,
      (labelName: LabelName) => labelName.name.length === 0
    )
    if (labelNames.length > 0) {
      updateLabelNamesAction(nonEmptyLabelNames)
    }
    console.log("labelNames", labelNames)
    updateActivePopupTypeAction(null)
  }

  const safeOnCreateAcceptCallback = () =>
    callbackWithLabelNamesValidation(onCreateAcceptCallback)()

  const onUpdateAcceptCallback = () => {
    const nonEmptyLabelNames: LabelName[] = reject(
      labelNames,
      (labelName: LabelName) => labelName.name.length === 0
    )
    const missingIds: string[] = LabelUtil.labelNamesIdsDiff(
      LabelsSelector.getLabelNames(),
      nonEmptyLabelNames
    )
    LabelActions.removeLabelNames(missingIds)
    updateLabelNamesAction(nonEmptyLabelNames)
    updateActivePopupTypeAction(null)
  }

  const safeOnUpdateAcceptCallback = () =>
    callbackWithLabelNamesValidation(onUpdateAcceptCallback)()

  const onCreateRejectCallback = () => {
    updateActivePopupTypeAction(PopupWindowType.LOAD_LABEL_NAMES)
  }

  const onUpdateRejectCallback = () => {
    updateActivePopupTypeAction(null)
  }

  const leftBar = () => {
    return (
      <div className="flex flex-col gap-4 items-center justify-center h-full">
        <button
          // image={'/ico/plus.png'}
          onClick={safeAddLabelNameCallback}
          className={
            "rounded-md bg-secondary border-secondary hover:border-accent border-2 hover:shadow-2xl w-12 text-white"
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            // fill="currentColor"
            className="bi bi-plus opacity-50 hover:opacity-100 fill-primary-content"
            viewBox="0 0 16 16"
          >
            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
          </svg>
        </button>
        {labelNames.length > 0 && (
          <button
            // image={enablePerClassColoration ? '/ico/colors-on.png' : '/ico/colors-off.png'}
            onClick={togglePerClassColorationCallback}
            // isActive={enablePerClassColoration}
            className={`rounded-md bg-secondary border-secondary hover:border-accent border-2 w-12 hover:shadow-2xl }`}
            // className={enablePerClassColoration ? "" : "monochrome"}
          >
            <img
              src={`${
                enablePerClassColoration
                  ? //   ? "/ico/labelColorBnW.png"
                    //   : "/ico/labelColor.png"
                    "/ico/labelColor.png"
                  : "/ico/labelColorBnW.png"
              }`}
              alt="labelColor"
            ></img>
          </button>
        )}
      </div>
    )
  }

  const renderContent = () => {
    return (
      <div className="bg-primary pt-4 max-w-[60]">
        <div>
          <div className="text-primary-content text-center text-sm">
            {isUpdate
              ? "You can now edit the label names you use to describe the objects in the photos. Use the " +
                "+ button to add a new empty text field."
              : "Before you start, you can create a list of labels you plan to assign to objects in your " +
                "project. You can also choose to skip that part for now and define label names as you go."}
          </div>
          <div className=" py-8">
            {Object.keys(labelNames).length !== 0 ? (
              <div>
                <div className="mx-auto max-h-72 overflow-y-scroll scrollbar px-2 pb-4 -mr-4 ">
                  {labelInputs}
                </div>
              </div>
            ) : (
              <div className="flex  flex-col items-center py-8" onClick={addLabelNameCallback}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="48"
                  height="48"
                  fill="currentColor"
                  className="bi bi-pencil-square"
                  viewBox="0 0 16 16"
                >
                  <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                  <path
                    fillRule="evenodd"
                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                  />
                </svg>
                <p className="extraBold pt-2">Your label list is empty</p>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <GenericYesNoPopup
        title={isUpdate ? "Edit labels" : "Create labels"}
        renderContent={renderContent}
        leftBar={leftBar}
        acceptLabel={isUpdate ? "Accept" : "Start project"}
        onAccept={isUpdate ? safeOnUpdateAcceptCallback : safeOnCreateAcceptCallback}
        skipRejectButton={true}
        rejectLabel={isUpdate ? "Cancel" : "Upload labels"}
        onReject={isUpdate ? onUpdateRejectCallback : onCreateRejectCallback}
      />
    </>
  )
}

const mapDispatchToProps = {
  updateActivePopupTypeAction: updateActivePopupType,
  updateLabelNamesAction: updateLabelNames,
  updatePerClassColorationStatusAction: updatePerClassColorationStatus,
  submitNewNotificationAction: submitNewNotification,
}

const mapStateToProps = (state: AppState) => ({
  projectType: state.general.projectData.type,
  projectId: state.general.projectData.id,
  enablePerClassColoration: state.general.enablePerClassColoration,
})

export default connect(mapStateToProps, mapDispatchToProps)(InsertLabelNamesPopup)
