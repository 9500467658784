import React, { PropsWithChildren, useState } from "react"
import "./LoadMoreImagesPopup.scss"
import { AppState } from "../../../store"
import { connect } from "react-redux"
import {
  addImageData,
  updateImageSize,
  updateImageStatus,
} from "../../../store/labels/actionCreators"
import { GenericYesNoPopup } from "../GenericYesNoPopup/GenericYesNoPopup"
import { useDropzone } from "react-dropzone"
import { ImageData } from "../../../store/labels/types"
import { AcceptedFileType } from "../../../data/enums/AcceptedFileType"
import { PopupActions } from "../../../logic/actions/PopupActions"
import { ImageDataUtil } from "../../../utils/ImageDataUtil"
import { UploadImageToS3 } from "apis/s3/uploadAndDownload"
import { generateImageDocument, connectImageToProject } from "apis/images"
import { sortBy } from "lodash"
import { ProjectData } from "store/general/types"
import { PopupWindowType } from "data/enums/PopupWindowType"
import {
  updateActivePopupData,
  updateActivePopupType,
  updateUserData,
} from "store/general/actionCreators"
import { getProjectInfo, getImageNames } from "apis/projects"
import LinearProgressWithLabel from "views/Common/ProgressBar/LinearProgressWithLabel"
import { toast } from "react-toastify"
import { FileUtil } from "../../../utils/FileUtil"

interface IProps {
  addImageDataAction: (imageData: ImageData[]) => any
  projectData: ProjectData
  imagesData: ImageData[]
  updateImageSizeAction: (imageSize: number) => any
  imageStatus: string
  updateActivePopupTypeData: (activePopUpData: Object) => any
  updateActivePopupTypeAction: (activePopupType: PopupWindowType) => any
  updateImageStatusAction: (imageStatus: string) => any
}

const labels = {
  labelLines: [],
  labelPoints: [],
  labelRects: [],
  labelPolygons: [],
  labelNameIds: [],
}

const LoadMoreImagesPopup: React.FC<IProps> = (props: PropsWithChildren<IProps>) => {
  const [progress, setProgress] = useState(-10)
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: AcceptedFileType.IMAGE,
  })
  const [invalidFileName, setInvalidFileName] = useState([])

  // Upload loaded images to AWS S3 bucket
  const uploadImage = async (files: File[], projectId) => {
    const error = []
    const done = []
    // name folder in aws s3 as per NODE_ENV
    let projectFolderInititial
    if (process.env.REACT_APP_NODE_ENV === "production") {
      projectFolderInititial = `production/${projectId}`
    } else {
      projectFolderInititial = `staging/${projectId}`
    }
    // upload first image as thumbnail
    const response = await UploadImageToS3(`${projectFolderInititial}/thumbnail.webp`, files[0], 30)
    if (response.status !== 200) {
      error.push(files[0].name)
    }
    var perIncrement = 100 / files.length
    const accessToken: string = localStorage.getItem("accessToken")
    // next, loop through indices of files and images, uploading respectively
    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      const imageElement = await FileUtil.loadImage(file)
      var image = await generateImageDocument(
        file.name,
        imageElement.height,
        imageElement.width,
        accessToken
      )
      if (image["error"] == null) {
        const imageId = image["data"]["data"]["images"].find(
          (image) => image.imageName === files[i].name
        )?._id
        const response = await UploadImageToS3(
          `${projectFolderInititial}/original/${imageId}`,
          file,
          80
        )
        if (response.status !== 200) {
          error.push({ file_name: file.name, file: file })
        } else {
          await connectImageToProject(
            projectId,
            [imageId.split("_")[0] + "_" + file.name],
            accessToken
          )
          done.push({ file: file, imageId: imageId, labels: labels })
        }
      } else {
        error.push({ file_name: file.name, file: file })
      }
      setProgress((prevState) => {
        return (prevState += perIncrement)
      })
    }
    // if (imageIdsToDelete.length > 0) {
    //   const accessToken: string = localStorage.getItem("accessToken");
    //   const deleteImageResponse = removeImagesFromProject(
    //     projectId,
    //     imageIdsToDelete,
    //     accessToken
    //   );
    //   setProgress((prevState) => {
    //     return (prevState += 20);
    //   });
    //   console.log({ deleteImageResponse });
    // }
    console.log({ ok: true, error, done })
    return { ok: true, error, done }
  }

  const onAccept = async () => {
    props.updateImageStatusAction("all")
    setProgress(1)
    if (acceptedFiles.length > 0) {
      const projectId = props.projectData?.id
      let prevImagesSize
      var invalidFileName = []
      setInvalidFileName([])
      const accessToken: string = localStorage.getItem("accessToken")
      var imageName = await (await getImageNames(projectId, accessToken)).data?.data?.imageData
      for (let i = 0; i < acceptedFiles.length; i++) {
        var name = acceptedFiles[i]?.name
        if (imageName.findIndex((x) => x.imageName === name) !== -1) {
          console.log("Already Present File Name : ", acceptedFiles[i]?.name, i)
          invalidFileName.push(acceptedFiles[i]?.name)
        }
      }
      for (let i = 0; i < invalidFileName.length; i++) {
        acceptedFiles.splice(
          acceptedFiles.findIndex((file) => file.name === invalidFileName[i]),
          1
        )
      }
      if (invalidFileName.length) {
        toast.error(
          `Files with same name are already present in the database.
          Please rename and reupload the images.`,
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          }
        )
        setInvalidFileName(invalidFileName)
        setProgress(-10)
        return
      }
      const projectInfo = await getProjectInfo(projectId, accessToken, props.imageStatus)
      if (projectInfo.statusCode === 200) {
        const proInfo = projectInfo?.data?.data
        prevImagesSize = proInfo?.imageIds.length
      }
      const files = sortBy(acceptedFiles, (item: File) => item.name)
      // upload to AWS S3
      const isImageUploaded = await uploadImage(files, projectId)
      const imgData: ImageData[] = props.imagesData
      if (imgData.length === prevImagesSize) {
        const noOfImagesToAdd = 50 - (prevImagesSize % 50)
        if (isImageUploaded.done.length > noOfImagesToAdd) {
          await props.addImageDataAction(
            isImageUploaded.done
              .slice(0, noOfImagesToAdd)
              .map((imageData) =>
                ImageDataUtil.createImageDataFromFileData(
                  imageData.file,
                  imageData.labels,
                  imageData.imageId
                )
              )
          )
        } else {
          await props.addImageDataAction(
            isImageUploaded.done.map((imageData) =>
              ImageDataUtil.createImageDataFromFileData(
                imageData.file,
                imageData.labels,
                imageData.imageId
              )
            )
          )
        }
      }
      props.updateImageSizeAction(prevImagesSize + isImageUploaded.done.length) //*TODO: Handle for errors in uploading (+ uploadErrorImagesSize)
      if (isImageUploaded.error.length === 0) {
        toast.success(`${acceptedFiles.length} more images loaded successfully!`, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        })
        PopupActions.close()
      } else {
        toast.success(`${isImageUploaded.done.length} more images loaded successfully!`, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        })
        console.log(isImageUploaded.error)
        props.updateActivePopupTypeData(isImageUploaded.error)
        props.updateActivePopupTypeAction(PopupWindowType.UPLOAD_ERROR)
      }
    }
  }

  const onReject = () => {
    PopupActions.close()
  }

  const onClose = () => {
    if (progress >= 0) {
      toast.info("Processing...", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      })
    }
    PopupActions.close()
  }

  const getDropZoneContent = () => {
    if (acceptedFiles.length === 0)
      return (
        <>
          <input {...getInputProps()} />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            className="bi bi-cloud-upload fill-secondary-content"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z"
            />
            <path
              fillRule="evenodd"
              d="M7.646 4.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V14.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3z"
            />
          </svg>
          <div className=" text-secondary-content text-center pt-4">
            <p>Drop images or Click here to browse</p>
          </div>
        </>
      )
    else if (acceptedFiles.length === 1)
      return (
        <>
          <input {...getInputProps()} />
          <div className="flex gap-2">
            <img
              className="border-2 border-current mb-2 aspect-square"
              width="48"
              height="48"
              draggable={false}
              alt={"uploaded"}
              src={URL.createObjectURL(acceptedFiles[0])}
            />
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="48"
                height="48"
                fill="currentColor"
                className="bi bi-plus-square"
                viewBox="0 0 16 16"
              >
                <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
              </svg>
            </div>
          </div>
          <p className="text-current text-center">1 image loaded</p>
        </>
      )
    else
      return (
        <>
          <input {...getInputProps()} />
          <div className="flex gap-2">
            {acceptedFiles.map((file, index) =>
              index >= 3 ? (
                <></>
              ) : (
                <img
                  id={index.toString()}
                  className="border-2 border-current mb-2 aspect-square"
                  width="48"
                  height="48"
                  draggable={false}
                  alt={"uploaded"}
                  src={URL.createObjectURL(file)}
                />
              )
            )}
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="48"
                height="48"
                fill="currentColor"
                className="bi bi-plus-square"
                viewBox="0 0 16 16"
              >
                <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
              </svg>
            </div>
          </div>
          <p key={2} className="">
            {` ${acceptedFiles.length} images loaded`}
          </p>
        </>
      )
  }

  const renderContent = () => {
    return (
      <div className="py-16 w-[350px]">
        <div
          {...getRootProps({
            className: `DropZone border-2 border-dashed border-secondary-content opacity-60 hover:opacity-100 py-6 rounded-xl group flex flex-col items-center${
              progress >= 0 ? " pointer-events-none opacity-50" : ""
            }`,
          })}
        >
          {getDropZoneContent()}
        </div>
        {invalidFileName.length ? (
          <div className="pt-4">
            <div className="text-lg text-white text-center py-4">
              Please rename filename and reupload the following images:
            </div>
            <div className="h-48 overflow-y-scroll overflow-x-hidden break-all scrollbar border-y-1 bg-base-100 p-2 rounded-xl">
              {invalidFileName.map((item) => (
                <li>{item}</li>
              ))}
            </div>
          </div>
        ) : null}
        {LinearProgressWithLabel(progress, true)}
      </div>
    )
  }

  return (
    <>
      <GenericYesNoPopup
        title={"Load more images"}
        renderContent={renderContent}
        acceptLabel={"Load"}
        onAccept={onAccept}
        rejectLabel={"Cancel"}
        onReject={onReject}
        onClose={onClose}
        skipRejectButton={true}
        disableAcceptButton={progress >= 0}
      />
    </>
  )
}

const mapDispatchToProps = {
  addImageDataAction: addImageData,
  updateUserDataAction: updateUserData,
  updateImageSizeAction: updateImageSize,
  updateActivePopupTypeData: updateActivePopupData,
  updateActivePopupTypeAction: updateActivePopupType,
  updateImageStatusAction: updateImageStatus,
}

const mapStateToProps = (state: AppState) => ({
  projectData: state.general.projectData,
  imagesData: state.labels.imagesData,
  imageStatus: state.labels.imageStatus,
})

export default connect(mapStateToProps, mapDispatchToProps)(LoadMoreImagesPopup)
