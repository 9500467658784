import axios from "axios"
import { ImageStatus } from "data/enums/ImageStatus"
require("dotenv").config()

export const setImageAPI = async (projectId: any, imageList: any, accessToken: string) => {
  try {
    const images = []
    imageList.map((image) => {
      images.push({ imageName: image, stage: ImageStatus.UN_ANNOTATED })
    })
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/images/setImages`,
      {
        projectId,
        images,
      },
      {
        headers: {
          "Content-Type": "application/json",
          accesstoken: accessToken,
        },
      }
    )
    return { statusCode: 200, data }
  } catch (error) {
    if (error instanceof Error) {
      console.log({ error })
      return { statusCode: 500, error: error.message }
    }
  }
}

export const updateImageAnnotationsAPI = async (imageId: any, labels: any, accessToken: string) => {
  try {
    const payload = { imageId, labels }
    const { data } = await axios.put(
      `${process.env.REACT_APP_API_URL}/images/updateImage`,
      { payload },
      {
        headers: {
          "Content-Type": "application/json",
          accesstoken: accessToken,
        },
      }
    )
    return { statusCode: 200, data }
  } catch (error) {
    if (error instanceof Error) {
      console.log({ error })
      return { statusCode: 500, error: error.message }
    }
  }
}

export const downloadImageAnnotationsAPI = async (imageId: any, accessToken: string) => {
  try {
    // const payload = {imageId}
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/images/getAnnotations/${imageId}`,
      {
        headers: {
          "Content-Type": "application/json",
          accesstoken: accessToken,
        },
      }
    )
    // console.log(data)
    return { statusCode: 200, data }
  } catch (error) {
    if (error instanceof Error) {
      console.log({ error })
      return { statusCode: 500, error: error.message }
    }
  }
}

export const getImageNameByImageId = async (imageId: string, accessToken: string) => {
  try {
    // const payload = {imageId}
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/images/getImageNameByImageId/${imageId}`,
      {
        headers: {
          "Content-Type": "application/json",
          accesstoken: accessToken,
        },
      }
    )
    // console.log(data)
    return { statusCode: 200, data }
  } catch (error) {
    if (error instanceof Error) {
      console.log({ error })
      return { statusCode: 500, error: error.message }
    }
  }
}

export const removeImagesFromProject = async (projectId, images, accessToken: string) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/images/removeImages`,
      {
        projectId,
        images,
      },
      {
        headers: {
          "Content-Type": "application/json",
          accesstoken: accessToken,
        },
      }
    )
    return { statusCode: 200, data }
  } catch (error) {
    if (error instanceof Error) {
      console.log({ error })
      return { statusCode: 500, error: error.message }
    }
  }
}

export const generateImageDocument = async (image, height, width, accessToken: string) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/images/generateImageDocument`,
      {
        images: {
          imageName: image,
          stage: ImageStatus.UN_ANNOTATED,
          height: height,
          width: width,
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
          accesstoken: accessToken,
        },
      }
    )
    return { statusCode: 200, data }
  } catch (error) {
    if (error instanceof Error) {
      console.log({ error })
      return { statusCode: 500, error: error.message }
    }
  }
}

export const connectImageToProject = async (projectId, imageId, accessToken: string) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/images/connectImageToProject`,
      {
        projectId,
        imageId,
      },
      {
        headers: {
          "Content-Type": "application/json",
          accesstoken: accessToken,
        },
      }
    )
    return { statusCode: 200, data }
  } catch (error) {
    if (error instanceof Error) {
      console.log({ error })
      return { statusCode: 500, error: error.message }
    }
  }
}

export const getImageDataAPI = async (projectId, accessToken: string) => {
  try {
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/images/getImageData`, {
      headers: {
        "Content-Type": "application/json",
        accesstoken: accessToken,
      },
      params: {
        projectId: projectId,
      },
    })
    return { statusCode: 200, data }
  } catch (error) {
    if (error instanceof Error) {
      console.log({ error })
      return { statusCode: 500, error: error.message }
    }
  }
}

export const changeImageStatus = async (
  imageId: any[],
  setStatus: string,
  projectId: string,
  accessToken: string
) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/images/changeImageStatus`,
      {
        imageId: imageId,
        setStatus: setStatus,
        projectId: projectId,
      },
      {
        headers: {
          "Content-Type": "application/json",
          accesstoken: accessToken,
        },
      }
    )
    return { statusCode: 200, data }
  } catch (error) {
    if (error instanceof Error) {
      console.log({ error })
      return { statusCode: 500, error: error.message }
    }
  }
}
