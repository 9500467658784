import React, { useContext, useState } from "react"
import { AcceptedFileType } from "data/enums/AcceptedFileType"
import { PopupWindowType } from "data/enums/PopupWindowType"
import { ProjectType } from "data/enums/ProjectType"
import { useFormik } from "formik"
import { useDropzone, DropzoneOptions } from "react-dropzone"
import { connect } from "react-redux"
import { AppState } from "store"
import {
  updateProjectData,
  updateActivePopupType,
  updateUserData,
} from "store/general/actionCreators"
import { ProjectData, UserData } from "store/general/types"
import { updateActiveImageIndex, addImageData, updateImageData } from "store/labels/actionCreators"
import { GenericYesNoPopup } from "../GenericYesNoPopup/GenericYesNoPopup"
import * as yup from "yup"
import { useNavigate } from "react-router-dom"
import { UploadImageToS3 } from "apis/s3/uploadAndDownload"
import { sortBy } from "lodash"
import { setProject } from "apis/projects"
import { ImageData } from "../../../store/labels/types"
import { LabelType } from "data/enums/LabelType"
import LinearProgressWithLabel from "views/Common/ProgressBar/LinearProgressWithLabel"
import { generateImageDocument, connectImageToProject } from "apis/images"
import { AuthContext } from "contexts/authContext"
import { FileUtil } from "../../../utils/FileUtil"
import { PopupActions } from "../../../logic/actions/PopupActions"
import { toast } from "react-toastify"

interface IProps {
  updateActiveImageIndexAction: (activeImageIndex: number) => any
  addImageDataAction: (imageData: ImageData[]) => any
  updateImageDataAction: (imageData: ImageData[]) => any
  updateProjectDataAction: (projectData: ProjectData) => any
  updateActivePopupTypeAction: (activePopupType: PopupWindowType) => any
  updateUserDataAction: (userData: UserData) => any
  userData: UserData
}

const labels = {
  labelLines: [],
  labelPoints: [],
  labelRects: [],
  labelPolygons: [],
  labelNameIds: [],
}

const AddNewProjectPopup: React.FC<IProps> = (props) => {
  const authContext = useContext(AuthContext)
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: AcceptedFileType.IMAGE,
  } as DropzoneOptions)
  const [progress, setProgress] = useState(-10)
  const navigator = useNavigate()

  const uploadImage = async (files: File[], projectId) => {
    const error = []
    const done = []
    setProgress(1)
    // name folder in aws s3 as per NODE_ENV
    let projectFolderInititial
    if (process.env.REACT_APP_NODE_ENV === "production") {
      projectFolderInititial = `production/${projectId}`
    } else {
      projectFolderInititial = `staging/${projectId}`
    }
    // upload first image as thumbnail
    const response = await UploadImageToS3(`${projectFolderInititial}/thumbnail.webp`, files[0], 30)
    if (response.status !== 200) {
      error.push(files[0].name)
    } else {
      done.push(files[0])
    }
    var perIncrement = 100 / files.length
    const accessToken: string = localStorage.getItem("accessToken")

    // Function to upload an individual image and return a promise
    const uploadImageToS3Async = async (file, imageId, labels) => {
      const imageElement = await FileUtil.loadImage(file)
      var image = await generateImageDocument(
        file.name,
        imageElement.height,
        imageElement.width,
        accessToken
      )
      if (image["error"] == null) {
        const response = await UploadImageToS3(
          `${projectFolderInititial}/original/${imageId}`,
          file,
          80
        )
        if (response.status !== 200) {
          error.push({ file_name: file.name, file: file })
        } else {
          await connectImageToProject(
            projectId,
            [imageId.split("_")[0] + "_" + file.name],
            accessToken
          )
          done.push({ file: file, imageId: imageId, labels: labels })
        }
      } else {
        error.push({ file_name: file.name, file: file })
      }
      setProgress((prevState) => prevState + perIncrement)
    }

    // Array to hold promises for parallel uploading
    const uploadPromises = []

    // Loop through files and images, create promises for each image upload
    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      const imageElement = await FileUtil.loadImage(file)
      var image = await generateImageDocument(
        file.name,
        imageElement.height,
        imageElement.width,
        accessToken
      )
      if (image["error"] == null) {
        const imageId = image["data"]["data"]["images"].find(
          (image) => image.imageName === files[i].name
        )?._id

        // Create a promise for each image upload
        const uploadPromise = uploadImageToS3Async(file, imageId, labels)
        uploadPromises.push(uploadPromise)
      } else {
        error.push({ file_name: file.name, file: file })
      }
    }

    // Wait for all promises to be resolved (parallel uploading)
    await Promise.all(uploadPromises)

    console.log({ ERROR_ARRAY: error })
    return { ok: true, error, done }
  }

  const startEditor = async (projectId, projectName, projectImages, projectType: ProjectType) => {
    if (acceptedFiles.length > 0) {
      const files = sortBy(acceptedFiles, (item: File) => item.name)
      //Upload image to AWS S3 one by one
      const isImgUploadOk = await uploadImage(files, projectId)
      props.updateActiveImageIndexAction(0)
      props.updateImageDataAction([])
      let userData = props.userData
      const newUserData: UserData = {
        ...props.userData,
        adminProjectIds: [...userData.adminProjectIds, `${projectId}_${projectName}`],
      }
      props.updateUserDataAction(newUserData)
      let userInfo = JSON.parse(localStorage.getItem("userInfo"))
      userInfo = {
        ...userInfo,
        data: newUserData,
      }
      console.log({ USER_INFO: userInfo })
      authContext.setUserInfo(userInfo)
      if (isImgUploadOk.ok) navigator(`/projects/${projectId}`, { state: isImgUploadOk.error })
      else navigator("/dashboard")
      props.updateActivePopupTypeAction(null)
    }
  }

  const callSetProjectAPI = async (projectName, projectType, annoType, metaData, acceptedFiles) => {
    console.log({ annoType })
    setProgress(0)
    const accessToken: string = localStorage.getItem("accessToken")
    const cognitoId: string = JSON.parse(localStorage.getItem("userInfo"))?.data?.cognitoId
    const { statusCode, error, data } = await setProject(
      cognitoId,
      projectName,
      projectType,
      annoType,
      metaData,
      [],
      accessToken
    )
    console.log({ statusCode, data })
    if (statusCode === 500 || error) {
      console.log("Some Error Occurred in callSetProjectAPI")
      setProgress(-10)
      return { ok: false }
    } else {
      setProgress(0)
      return {
        projectId: data?.data?.newProject?._id,
        projectImages: data?.data?.images,
        ok: true,
      }
    }
  }

  const onClose = () => {
    if (progress >= 0) {
      toast.info("Processing...", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      })
    }
    PopupActions.close()
  }

  const onSubmitForm = async (projectName, projectType, annoType, metaData, acceptedFiles) => {
    const isOk = await callSetProjectAPI(
      projectName,
      projectType,
      annoType,
      metaData,
      acceptedFiles
    )
    console.log({ isOk })
    if (isOk.ok) {
      props.updateProjectDataAction({
        type: projectType,
        name: projectName,
        metadata: metaData,
        id: isOk.projectId,
      })
      startEditor(isOk.projectId, projectName, isOk.projectImages, projectType)
    } else {
      console.log("Can't create a new Project")
    }
  }

  const formik = useFormik({
    initialValues: {
      projectName: "",
      projectType: ProjectType.OBJECT_DETECTION,
      metadata: "",
      //   images: [],
    },
    validationSchema: yup.object({
      projectName: yup.string().required(),
      projectType: yup.string().required("Project Type is a required field"),
      metadata: yup.string().required(),
      //   images: yup.array().min(1, "Select at least one image"),
    }),
    onSubmit: (values) => {
      console.log("formik")
      console.log(values)
      console.log({ acceptedFiles })
      onSubmitForm(
        values.projectName,
        values.projectType,
        LabelType.POINT,
        values.metadata,
        acceptedFiles
      )
    },
  })

  const getDropZoneContent = () => {
    if (acceptedFiles.length === 0)
      return (
        <>
          <input {...getInputProps()} />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            className="bi bi-cloud-upload fill-secondary-content"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z"
            />
            <path
              fillRule="evenodd"
              d="M7.646 4.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V14.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3z"
            />
          </svg>
          <div className="text-secondary-content text-center pt-4">
            <p>Drop images or Click here to browse</p>
          </div>
        </>
      )
    else if (acceptedFiles.length === 1)
      return (
        <>
          <input {...getInputProps()} />
          <div className="flex gap-2">
            <img
              className="border-2 border-current mb-2 aspect-square"
              width="48"
              height="48"
              draggable={false}
              alt={"uploaded"}
              src={URL.createObjectURL(acceptedFiles[0])}
            />
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="48"
                height="48"
                fill="currentColor"
                className="bi bi-plus-square"
                viewBox="0 0 16 16"
              >
                <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
              </svg>
            </div>
          </div>
          <p className="text-current text-center">1 image loaded</p>
        </>
      )
    else
      return (
        <>
          <input {...getInputProps()} />
          <div className="flex gap-2">
            {acceptedFiles.map((file, index) =>
              index >= 3 ? (
                <></>
              ) : (
                <img
                  key={index}
                  id={index.toString()}
                  className="border-2 border-current mb-2 aspect-square"
                  width="48"
                  height="48"
                  draggable={false}
                  alt={"uploaded"}
                  src={URL.createObjectURL(file)}
                />
              )
            )}
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="48"
                height="48"
                fill="currentColor"
                className="bi bi-plus-square"
                viewBox="0 0 16 16"
              >
                <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
              </svg>
            </div>
          </div>
          <p key={2} className="">
            {` ${acceptedFiles.length} images loaded`}
          </p>
        </>
      )
  }

  const renderContent = () => {
    return (
      <>
        <div className="w-full flex flex-col gap-6 pt-16 pb-8">
          <div>
            <label className="label">
              <span className="label-text font-medium text-base text-primary-content">
                Project Name
              </span>
            </label>
            <input
              id="projectName"
              onChange={formik.handleChange}
              value={formik.values.projectName}
              type="text"
              placeholder="Enter Project Name"
              className="input bg-secondary input-bordered w-full focus:input-accent hover:input-accent rounded-full"
              disabled={progress >= 0 ? true : false}
            />
            <label className="label">
              <span className="label-text-alt text-error ">{formik.errors.projectName}</span>
            </label>
          </div>
          <div>
            <div className="flex flex-col items-start">
              <div className="form-control">
                <label className="label cursor-pointer">
                  <input
                    id="od"
                    onChange={formik.handleChange}
                    value={ProjectType.OBJECT_DETECTION}
                    type="radio"
                    name="projectType"
                    className="mr-2 radio border-2 border-accent bg-secondary checked:bg-primary-content"
                    disabled={progress >= 0 ? true : false}
                    defaultChecked={formik.values.projectType === ProjectType.OBJECT_DETECTION}
                  />
                  <span className="label-text">Object Detection</span>
                </label>
              </div>
              <div className="form-control">
                <label className="label cursor-pointer">
                  <input
                    id="ir"
                    onChange={formik.handleChange}
                    value={ProjectType.IMAGE_RECOGNITION}
                    type="radio"
                    name="projectType"
                    className="mr-2 radio border-accent checked:bg-primary-content"
                    disabled={progress >= 0 ? true : false}
                    defaultChecked={formik.values.projectType === ProjectType.IMAGE_RECOGNITION}
                  />
                  <span className="label-text">Image Recognition</span>
                </label>
              </div>
              <div className="form-control">
                <label className="label cursor-pointer">
                  <input
                    id="ir"
                    onChange={formik.handleChange}
                    value={ProjectType.IMAGE_RECOGNITION_SINGLE_LABEL}
                    type="radio"
                    name="projectType"
                    className="mr-2 radio border-accent checked:bg-primary-content"
                    disabled={progress >= 0 ? true : false}
                    defaultChecked={formik.values.projectType === ProjectType.IMAGE_RECOGNITION}
                  />
                  <span className="label-text">Image Recognition Single Label</span>
                </label>
              </div>
            </div>
          </div>
          <div>
            <label className="label">
              <span className="label-text font-medium text-base text-primary-content">
                Metadata
              </span>
            </label>
            <input
              id="metadata"
              onChange={formik.handleChange}
              value={formik.values.metadata}
              type="text"
              placeholder="Enter Metadata"
              className="input input-bordered bg-secondary w-full focus:input-accent hover:input-accent rounded-full"
              disabled={progress >= 0 ? true : false}
            />
            <label className="label">
              <span className="label-text-alt text-error ">{formik.errors.metadata}</span>
            </label>
          </div>
          <div
            {...getRootProps({
              className: `DropZone border-2 border-dashed border-secondary-content opacity-60 hover:opacity-100 py-6 rounded-xl group flex flex-col items-center${
                progress >= 0 ? " pointer-events-none opacity-50" : ""
              }`,
            })}
          >
            {getDropZoneContent()}
          </div>
          <div className="w-full">{LinearProgressWithLabel(progress, true)}</div>
        </div>
      </>
    )
  }

  return (
    <>
      <GenericYesNoPopup
        title={"Create Project"}
        renderContent={renderContent}
        skipRejectButton={true}
        acceptLabel={"CREATE"}
        // onAccept={() => {}}
        onClose={onClose}
        isFormik={() => formik}
        disableAcceptButton={progress >= 0}
      />
    </>
  )
}

const mapStateToProps = (state: AppState) => ({
  projectData: state.general.projectData,
  userData: state.general.userData,
})

const mapDispatchToProps = {
  updateActiveImageIndexAction: updateActiveImageIndex,
  addImageDataAction: addImageData,
  updateImageDataAction: updateImageData,
  updateProjectDataAction: updateProjectData,
  updateActivePopupTypeAction: updateActivePopupType,
  updateUserDataAction: updateUserData,
}

export default connect(mapStateToProps, mapDispatchToProps)(AddNewProjectPopup)
