export enum EventType {
  RESIZE = "resize",
  MOUSE_UP = "mouseup",
  MOUSE_DOWN = "mousedown",
  MOUSE_MOVE = "mousemove",
  MOUSE_WHEEL = "wheel",
  KEY_DOWN = "keydown",
  KEY_PRESS = "keypress",
  KEY_UP = "keyup",
  FOCUS = "focus",
}
