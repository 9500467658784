import { getImageDataAPI, getImageNameByImageId } from "apis/images"
import { generatePreSignedGetUrl } from "apis/s3/uploadAndDownload"
import { ImageDataUtil } from "./ImageDataUtil"
import { ProjectType } from "data/enums/ProjectType"
import { LabelType } from "data/enums/LabelType"
import { ImageData, LabelName } from "store/labels/types"
import { getProjectInfo } from "apis/projects"
import { ProjectData } from "store/general/types"
import { toast } from "react-toastify"

export class FileUtil {
  public static loadImage(fileData: File): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
      // console.log(fileData)
      const url = URL.createObjectURL(fileData)
      // const url = 'https://images.pexels.com/photos/10402422/pexels-photo-10402422.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
      const image = new Image()
      image.src = url
      console.log(image)
      image.onload = () => resolve(image)
      image.onerror = reject
    })
  }

  public static loadImages(fileData: File[]): Promise<HTMLImageElement[]> {
    return new Promise((resolve, reject) => {
      const promises: Promise<HTMLImageElement>[] = fileData.map((fileData: File) =>
        FileUtil.loadImage(fileData)
      )
      Promise.all(promises)
        .then((values: HTMLImageElement[]) => resolve(values))
        .catch((error) => reject(error))
    })
  }

  public static readFile(fileData: File): Promise<string> {
    return new Promise((resolve, reject) => {
      let reader = new FileReader()
      reader.onloadend = (event: any) => {
        resolve(event.target.result)
      }
      reader.onerror = reject
      reader.readAsText(fileData)
    })
  }

  public static readFiles(fileData: File[]): Promise<string[]> {
    return new Promise((resolve, reject) => {
      const promises: Promise<string>[] = fileData.map((fileData: File) =>
        FileUtil.readFile(fileData)
      )
      Promise.all(promises)
        .then((values: string[]) => resolve(values))
        .catch((error) => reject(error))
    })
  }

  public static extractFileExtension(name: string): string | null {
    const parts = name.split(".")
    return parts.length > 1 ? parts[parts.length - 1] : null
  }

  public static extractFileName(name: string): string | null {
    const splitPath = name.split(".")
    let fName = ""
    for (const idx of Array(splitPath.length - 1).keys()) {
      if (fName === "") fName += splitPath[idx]
      else fName += "." + splitPath[idx]
    }
    return fName
  }
}

async function fetchProjectInfo(projectId: any, accessToken: string, imageStatus: string) {
  try {
    const response = await getProjectInfo(projectId, accessToken, imageStatus)
    if (response.statusCode !== 200) {
      throw new Error("Failed to fetch project information")
    }
    return response.data.data
  } catch (error) {
    console.error("Error fetching project information:", error)
    return null // Return null to indicate failure
  }
}

// Async utility to fetch and prepare image files
async function prepareImageFiles(projectId: any, loadableImageIds: any[], accessToken: string) {
  return Promise.all(
    loadableImageIds.map(async (imageId: string) => {
      const presignedURL = generatePreSignedGetUrl(
        `${
          process.env.REACT_APP_NODE_ENV === "production" ? "production/" : "staging/"
        }${projectId}/original/${imageId}`,
        "image/jpg"
      )
      try {
        const blob = await (await fetch(presignedURL)).blob()
        const imgNameData = await getImageNameByImageId(imageId.split("_")[0], accessToken)
        console.log("imageId", imageId)
        const filename =
          imgNameData.statusCode === 200
            ? imgNameData.data.data.imageName
            : `${imageId.substring(imageId.indexOf("_") + 1)}`
        return {
          file: new File([blob], filename, { type: "image/jpeg", lastModified: Date.now() }),
          imageId: imageId,
        }
      } catch (error) {
        console.error("Error fetching image data:", error)
        return null // Return null to handle failures gracefully
      }
    })
  )
}

export async function getFiles(
  setIsLazyLoading: (arg0: boolean) => void,
  projectId: string,
  updateActiveLabelType: (arg0: LabelType) => void,
  updateLabelNamesAction: {
    (labels: LabelName[]): any
    (labels: LabelName[]): any
    (arg0: any): void
  },
  addImageDataAction: (arg0: ImageData[]) => any,
  updateActiveImageIndexThunkAction: (arg0: number | null) => void,
  updateProjectDataAction: (arg0: ProjectData) => void,
  updateImageSizeAction: (arg0: any) => void,
  setLoading: (arg0: number) => void,
  activeImageIndex: number | null,
  loadImageIndex: number,
  setTotalImagesSize: (arg0: number) => void,
  imageStatus: string,
  isLazyLoading?: boolean
) {
  if (!isLazyLoading) setLoading(30)

  const accessToken = localStorage.getItem("accessToken")
  const projectInfo = await fetchProjectInfo(projectId, accessToken, imageStatus)

  if (!projectInfo) {
    toast.error("Project Data not available", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2000,
      toastId: "always1",
    })
    return
  }

  const imageIds = projectInfo.imageIds.map((image: string) => image.split("_")[0])
  updateLabelNamesAction(projectInfo.labels)
  setTotalImagesSize(imageIds.length)
  updateImageSizeAction(imageIds.length)

  const nextItemsToLoadCount = Math.min(imageIds.length - loadImageIndex, 50)
  const loadableImageIds = imageIds.slice(loadImageIndex, loadImageIndex + nextItemsToLoadCount)
  const files = await prepareImageFiles(projectId, loadableImageIds, accessToken)

  if (files.some((file) => !file)) {
    console.error("Some files could not be loaded")
    toast.warning("Some files could not be loaded", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2000,
      toastId: "always1",
    })
  }

  updateProjectDataAction({
    type: projectInfo.projectType,
    name: projectInfo.projectName,
    metadata: projectInfo.metaData,
    id: projectId,
  })
  let localActiveImageIndex = null
  if (activeImageIndex !== null && isLazyLoading) {
    localActiveImageIndex = activeImageIndex
  }

  updateActiveImageIndexThunkAction(localActiveImageIndex)

  const imageStageData = await getImageDataAPI(projectId, accessToken)
  if (imageStageData.statusCode !== 200) {
    console.error("Failed to fetch image stage data")
    toast.error("Image  Data not available", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2000,
      toastId: "always1",
    })
  }

  await addImageDataAction(
    files.map(({ file, imageId }) =>
      ImageDataUtil.createImageDataFromFileData(
        file,
        { labelLines: [], labelPoints: [], labelRects: [], labelPolygons: [], labelNameIds: [] },
        imageId,
        imageStageData.data.data.payload[file.name]
      )
    )
  )

  if (
    projectInfo.projectType === ProjectType.IMAGE_RECOGNITION ||
    projectInfo.projectType === ProjectType.IMAGE_RECOGNITION_SINGLE_LABEL
  ) {
    updateActiveLabelType(LabelType.IMAGE_RECOGNITION)
  } else {
    updateActiveLabelType(null)
  }

  if (!isLazyLoading) setLoading(100)
  else setIsLazyLoading(false)
}
