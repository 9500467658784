export enum Action {
  // AI
  UPDATE_SUGGESTED_LABEL_LIST = "@@UPDATE_SUGGESTED_LABEL_LIST",
  UPDATE_REJECTED_SUGGESTED_LABEL_LIST = "@@UPDATE_REJECTED_SUGGESTED_LABEL_LIST",
  UPDATE_OBJECT_DETECTOR_STATUS = "@@UPDATE_OBJECT_DETECTOR_STATUS",
  UPDATE_POSE_DETECTOR_STATUS = "@@UPDATE_POSE_DETECTOR_STATUS",
  UPDATE_DISABLED_AI_FLAG = "@@UPDATE_DISABLED_AI_FLAG",

  // GENERAL
  UPDATE_PROJECT_DATA = "@@UPDATE_PROJECT_DATA",
  UPDATE_WINDOW_SIZE = "@@UPDATE_WINDOW_SIZE",
  UPDATE_ACTIVE_POPUP_TYPE = "@@UPDATE_ACTIVE_POPUP_TYPE",
  UPDATE_ACTIVE_POPUP_DATA = "@@UPDATE_ACTIVE_POPUP_DATA",
  UPDATE_CUSTOM_CURSOR_STYLE = "@@UPDATE_CUSTOM_CURSOR_STYLE",
  UPDATE_CONTEXT = "@@UPDATE_CONTEXT",
  UPDATE_PREVENT_CUSTOM_CURSOR_STATUS = "@@UPDATE_PREVENT_CUSTOM_CURSOR_STATUS",
  UPDATE_IMAGE_DRAG_MODE_STATUS = "@@UPDATE_IMAGE_DRAG_MODE_STATUS",
  UPDATE_CROSS_HAIR_VISIBLE_STATUS = "@@UPDATE_CROSS_HAIR_VISIBLE_STATUS",
  UPDATE_ENABLE_PER_CLASS_COLORATION_STATUS = "@@UPDATE_ENABLE_PER_CLASS_COLORATION_STATUS",
  UPDATE_PROJECT_LIST = "@@UPDATE_PROJECT_LIST",
  UPDATE_ZOOM = "@@UPDATE_ZOOM",
  UPDATE_USER_ROLE = "@@UPDATE_USER_ROLE",

  UPDATE_THEME = "UPDATE_THEME",

  UPDATE_SHOW_MENU = "@@UPDATE_SHOW_MENU",
  UPDATE_USER_DATA = "@@UPDATE_USER_DATA",

  // LABELS
  UPDATE_ACTIVE_IMAGE_INDEX = "@@UPDATE_ACTIVE_IMAGE_INDEX",
  UPDATE_IMAGE_DATA_BY_ID = "@@UPDATE_IMAGE_DATA_BY_ID",
  ADD_IMAGES_DATA = "@@ADD_IMAGES_DATA",
  UPDATE_IMAGES_DATA = "@@UPDATE_IMAGES_DATA",
  UPDATE_ACTIVE_LABEL_NAME_ID = "@@UPDATE_ACTIVE_LABEL_NAME_ID",
  UPDATE_ACTIVE_LABEL_TYPE = "@@UPDATE_ACTIVE_LABEL_TYPE",
  UPDATE_ACTIVE_LABEL_ID = "@@UPDATE_ACTIVE_LABEL_ID",
  UPDATE_HIGHLIGHTED_LABEL_ID = "@@UPDATE_HIGHLIGHTED_LABEL_ID",
  UPDATE_HIGHLIGHTED_LABEL = "@@UPDATE_HIGHLIGHTED_LABEL",
  UPDATE_LABEL_NAMES = "@@UPDATE_LABEL_NAMES",
  UPDATE_FIRST_LABEL_CREATED_FLAG = "@@UPDATE_FIRST_LABEL_CREATED_FLAG",

  UPDATE_IMAGE_SIZE = "@@UPDATE_IMAGE_SIZE",
  UPDATE_SELECTED_IMAGES = "@@UPDATE_SELECTED_IMAGES",
  UPDATE_IMAGE_STATUS = "@@UPDATE_IMAGE_STATUS",

  // NOTIFICATIONS
  SUBMIT_NEW_NOTIFICATION = "@@SUBMIT_NEW_NOTIFICATION",
  DELETE_NOTIFICATION_BY_ID = "@@DELETE_NOTIFICATION_BY_ID",
}
