const range = (start, end, step, offset) => {
  const len = (Math.abs(end - start) + (offset || 0) * 2) / (step || 1) + 1
  const direction = start < end ? 1 : -1
  const startingPoint = start - direction * (offset || 0)
  const stepSize = direction * (step || 1)
  return Array(len)
    .fill(0)
    .map(function (_, index) {
      return startingPoint + stepSize * index
    })
}

const Pagination = ({
  nPages,
  totalProjectsSize,
  currentPage,
  setCurrentPage,
  currentFold,
  setCurrentFold,
  recordsPerPage,
  setRecordsPerPage,
}) => {
  let currentFoldNumbers = range(1, 5, 0, 0)
  if (currentFold * 5 < nPages) {
    if (currentFold === 1) {
      // currentFoldNumbers = [...Array(4).keys()].slice(1);
      currentFoldNumbers = range(1, 5, 0, 0)
      console.log({ currentFoldNumbers_1: currentFoldNumbers })
    } else {
      // currentFoldNumbers = [...Array(4).keys()].slice(1);
      currentFoldNumbers = range((currentFold - 1) * 5, currentFold * 5, 0, 0)
      console.log({ currentFoldNumbers: currentFoldNumbers })
    }
  } else {
    // currentFoldNumbers = range((currentFold - 1) * 5 + 1, nPages, 0, 0);
    if (currentFold === 1) {
      if (nPages) currentFoldNumbers = range(1, nPages, 0, 0)
      else currentFoldNumbers = range(1, 1, 0, 0)
    } else currentFoldNumbers = range((currentFold - 1) * 5, nPages, 0, 0)
    console.log({
      currentFoldNumbers_greater_than_total_pages: currentFoldNumbers,
    })
  }

  const nextPage = () => {
    if (currentPage !== nPages) {
      setCurrentPage(currentPage + 1)
      if (currentPage + 1 > currentFold * 5) {
        setCurrentFold(currentFold + 1)
      }
    }
  }

  const prevPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1)
      if ((currentFold - 1) * 5 === currentPage) {
        setCurrentFold(currentFold - 1)
      }
    }
  }

  const nextFold = () => {
    if (currentFold * 5 < totalProjectsSize) {
      setCurrentFold(currentFold + 1)
      // if (currentPage !== nPages) {
      setCurrentPage(currentFold * 5)
      // }
    }
  }

  const prevFold = () => {
    // if (currentPage !== 1) setCurrentPage(currentPage - 1);
    if (currentFold !== 1) {
      setCurrentFold(currentFold - 1)
      if (currentFold === 2) {
        setCurrentPage(1)
      } else {
        setCurrentPage((currentFold - 2) * 5)
      }
    }
  }

  const setPaginatedPageNo = (item, currentFoldNumbers, currentFold, nPages) => {
    if (currentFold > 1 && item === currentFoldNumbers[0]) return "..." + item
    if (currentFold * 5 < nPages && item === currentFoldNumbers[currentFoldNumbers.length - 1])
      return item + "..."
    return item
  }

  return (
    <>
      <div className="flex py-6 items-center justify-center gap-4 relative">
        <div className="btn-group rounded-md bg-primary px-2 mr-2">
          <div className={`${currentPage === 1 ? "hidden" : "px-1 py-2"}`} onClick={prevPage}>
            <div className="btn btn-square btn-secondary h-8 min-h-0 w-8 hover:fill-base-content hover:btn-base-100 active:fill-base-content active:btn-base-100">
              <i className="bi bi-chevron-left"></i>
            </div>
          </div>

          <div className={`${currentFold === 1 ? "hidden" : "px-1 py-2"}`} onClick={prevFold}>
            <div className="btn btn-square btn-secondary h-8 min-h-0 w-8 hover:fill-base-content hover:btn-base-100 active:fill-base-content active:btn-base-100">
              <i className="bi bi-chevron-double-left"></i>
            </div>
          </div>
        </div>

        <div className="btn-group bg-primary px-2 rounded-md">
          {/* {[1, 2, 3, 4, 5].map((item) => ( */}
          {currentFoldNumbers.map((item, index) => (
            <div key={index} className="px-1 py-2">
              <button
                className={`btn btn-secondary text-sm h-8 min-h-0 w-8 ${
                  currentPage === item ? "btn-active text-base-content" : "text-black"
                } hover:text-base-content hover:btn-base-100`}
                onClick={() => setCurrentPage(item)}
              >
                {setPaginatedPageNo(item, currentFoldNumbers, currentFold, nPages)}
              </button>
            </div>
          ))}
        </div>

        <div className="btn-group bg-primary px-2 rounded-md ml-2">
          <div className={`${currentPage >= nPages ? "hidden" : "px-1 py-2"}`} onClick={nextPage}>
            <div className="btn btn-square btn-secondary h-8 min-h-0 w-8 hover:fill-base-content hover:btn-base-100 active:fill-base-content active:btn-base-100">
              <i className="bi bi-chevron-right"></i>
            </div>
          </div>

          <div
            className={`${currentFold * 5 >= nPages ? "hidden" : "px-1 py-2"}`}
            onClick={nextFold}
          >
            <div className="btn btn-square btn-secondary h-8 min-h-0 w-8 hover:fill-base-content hover:btn-base-100 active:fill-base-content active:btn-base-100">
              <i className="bi bi-chevron-double-right"></i>
            </div>
          </div>
        </div>
        <select
          className="select select-bordered absolute top-50 right-0"
          onChange={(e) => setRecordsPerPage(e.target.value)}
        >
          <option value={10} selected={recordsPerPage === 10}>
            {10}
          </option>
          <option value={25} selected={recordsPerPage === 25}>
            {25}
          </option>
          <option value={50} selected={recordsPerPage === 50}>
            {50}
          </option>
        </select>
      </div>
    </>
  )
}

export default Pagination
