import AWS from "aws-sdk"
import React, { useState } from "react"
import { blobToURL, urlToBlob, fromBlob, fromURL } from "image-resize-compress" // ES6
require("dotenv").config()

const accessKeyId = `${process.env.REACT_APP_ACCESS_KEY_ID}`
const secretAccessKey = `${process.env.REACT_APP_SECRET_ACCESS_KEY}`

const s3Bucket = `${process.env.REACT_APP_S3_BUCKET}`
const region = `${process.env.REACT_APP_REGION}`
const urlExpirationTime = parseInt(`${process.env.REACT_APP_URL_EXPIRATION_TIME}`) // in seconds

AWS.config.update({
  accessKeyId: accessKeyId,
  secretAccessKey: secretAccessKey,
})

const myBucket = new AWS.S3({
  params: { Bucket: s3Bucket },
  region: region,
})

function generatePreSignedPutUrl(fileName: any, fileType: any) {
  try {
    const signedUrl = myBucket.getSignedUrl("putObject", {
      Key: fileName,
      ContentType: fileType,
      Expires: urlExpirationTime,
    })
    // Do stuff with signedUrl
    return signedUrl
  } catch (error) {
    console.log(error)
    return "www.brokenlink.png"
  }
  // myBucket.getSignedUrl('putObject', {
  //     Key: fileName,
  //     ContentType: fileType,
  //     Expires: urlExpirationTime
  // }, (_err: any, url: any) => {
  //     return url // API Response Here
  // });
  // return null
}

const handleBlob = async (blobFile, quality) => {
  // quality value for webp and jpeg formats.
  console.log("resddsxss", blobFile)

  // output width. 0 will keep its original width and 'auto' will calculate its scale from height.
  const width = 0
  // output height. 0 will keep its original height and 'auto' will calculate its scale from width.
  const height = 0
  // file format: png, jpeg, bmp, gif, webp. If null, original format will be used.
  const format = "webp"

  // note only the blobFile argument is required
  let resizedFile = await fromBlob(blobFile, quality, width, height, format)
  // .then((blob) => {
  //     // will output the converted blob file
  //     console.log("resddsxss", blob);
  //     return blob
  //     // will generate a url to the converted file
  //     // blobToURL(blob).then((url) => console.log("resddsxss", url));
  // });
  return resizedFile
}

const UploadImageToS3 = async (fileName: any, file: any, quality) => {
  // const blob = new Blob([fileName],{type: 'image/jpg'})
  let convertedImage = await handleBlob(file, quality)

  const presignedUrl = generatePreSignedPutUrl(fileName, "image/jpg")
  try {
    const presignedUrlResponse = await fetch(presignedUrl, {
      method: "PUT",
      body: convertedImage,
    })
    // console.log({ presignedUrlResponse });
    return { status: 200, presignedUrlResponse }
  } catch (err) {
    console.error(err)
    return { status: 500, err }
  }
}

function generatePreSignedGetUrl(fileName: string, fileType: string): string {
  try {
    const signedUrl = myBucket.getSignedUrl("getObject", {
      Key: fileName,
      ResponseContentType: fileType,
      Expires: urlExpirationTime,
    })
    // console.log({ SIGNED_URL_FOR__GET: signedUrl});
    // Do stuff with signedUrl
    return signedUrl
  } catch (error) {
    console.log(error)
    return "www.brokenlink.png"
  }
}

export { UploadImageToS3, generatePreSignedGetUrl }
