import React from "react"
import { ISize } from "../../../../interfaces/ISize"
import "./LabelInputField.scss"
import classNames from "classnames"
import { IRect } from "../../../../interfaces/IRect"
import { IPoint } from "../../../../interfaces/IPoint"
import { RectUtil } from "../../../../utils/RectUtil"
import { AppState } from "../../../../store"
import { connect } from "react-redux"
import {
  updateActiveLabelId,
  updateHighlightedLabelId,
} from "../../../../store/labels/actionCreators"
import { EventType } from "../../../../data/enums/EventType"
import { LabelName } from "../../../../store/labels/types"
import { LabelsSelector } from "../../../../store/selectors/LabelsSelector"
import { PopupWindowType } from "../../../../data/enums/PopupWindowType"
import { updateActivePopupType } from "../../../../store/general/actionCreators"
import { Dropdown } from "hooks/DropDown"

interface IProps {
  size: ISize
  isActive: boolean
  isHighlighted: boolean
  id: string
  value: LabelName
  options: LabelName[]
  onDelete: (id: string) => any
  onSelectLabel: (labelRectId: string, labelNameId: string) => any
  updateHighlightedLabelId: (highlightedLabelId: string) => any
  updateActiveLabelId: (highlightedLabelId: string) => any
  updateActivePopupType: (activePopupType: PopupWindowType) => any
}

interface IState {
  animate: boolean
  isOpen: boolean
}

class LabelInputField extends React.Component<IProps, IState> {
  private dropdownOptionHeight: number = 30
  private dropdownOptionCount: number = 6
  private dropdownMargin: number = 4
  private dropdownLabel: HTMLDivElement
  private dropdown: HTMLDivElement
  dropdownRef: React.RefObject<any>

  public constructor(props) {
    super(props)
    this.state = {
      animate: false,
      isOpen: false,
    }
    this.dropdownRef = React.createRef()
  }

  public componentDidMount(): void {
    requestAnimationFrame(() => {
      this.setState({ animate: true })
    })
  }

  private getClassName() {
    return classNames("LabelInputField", {
      loaded: this.state.animate,
      active: this.props.isActive,
      highlighted: this.props.isHighlighted,
    })
  }

  private openDropdown = () => {
    if (LabelsSelector.getLabelNames().length === 0) {
      this.props.updateActivePopupType(PopupWindowType.UPDATE_LABEL)
    } else {
      this.setState({ isOpen: true })
      window.addEventListener(EventType.MOUSE_DOWN, this.closeDropdown)
    }
  }

  private closeDropdown = (event: MouseEvent) => {
    const mousePosition: IPoint = { x: event.clientX, y: event.clientY }
    const clientRect = this.dropdown.getBoundingClientRect()
    const dropDownRect: IRect = {
      x: clientRect.left,
      y: clientRect.top,
      width: clientRect.width,
      height: clientRect.height,
    }

    if (!RectUtil.isPointInside(dropDownRect, mousePosition)) {
      this.setState({ isOpen: false })
      window.removeEventListener(EventType.MOUSE_DOWN, this.closeDropdown)
    }
  }

  private getDropdownStyle = (): React.CSSProperties => {
    const clientRect = this.dropdownLabel.getBoundingClientRect()
    const height: number =
      Math.min(this.props.options.length, this.dropdownOptionCount) * this.dropdownOptionHeight
    const style = {
      width: clientRect.width,
      height: height,
      left: clientRect.left,
    }

    if ((window.innerHeight * 2) / 3 < clientRect.top)
      return Object.assign(style, {
        top: clientRect.top - this.dropdownMargin - height,
      })
    else
      return Object.assign(style, {
        top: clientRect.bottom + this.dropdownMargin,
      })
  }

  private getDropdownOptions = () => {
    // const onClick = (id: string, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const onClick = (id: string) => {
      this.setState({ isOpen: false })
      // window.removeEventListener(EventType.MOUSE_DOWN, this.closeDropdown);
      this.props.onSelectLabel(this.props.id, id)
      this.props.updateHighlightedLabelId(null)
      this.props.updateActiveLabelId(this.props.id)
      // event.stopPropagation();
    }

    return this.props.options.map((option: LabelName) => {
      // return <div
      //     // className="DropdownOption"
      //     key={option.id}
      //     // style={{height: this.dropdownOptionHeight}}
      //     onClick={(event) => onClick(option.id, event)}
      // >
      //     {option.name}
      // </div>
      return (
        <li className="text-sm bg-base-200" key={option.id} onClick={() => onClick(option.id)}>
          <p className="hover:text-white hover:bg-accent py-2">{option.name}</p>
        </li>
      )
    })
  }

  private mouseEnterHandler = () => {
    this.props.updateHighlightedLabelId(this.props.id)
  }

  private mouseLeaveHandler = () => {
    this.props.updateHighlightedLabelId(null)
  }

  private onClickHandler = () => {
    this.props.updateActiveLabelId(this.props.id)
  }

  private handleDropdownButtonClick = () => {
    this.dropdownRef.current.classList.toggle("dropdown-open")

    // console.log(sortMenuOpen);
    if (this.state.isOpen === true) {
      this.setState({ isOpen: false })
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur()
      }
    } else this.setState({ isOpen: true })
  }

  private openUpdateLabelPopup = () => {
    if (LabelsSelector.getLabelNames().length === 0) {
      this.props.updateActivePopupType(PopupWindowType.UPDATE_LABEL)
    }
  }

  public render() {
    const { id, value, onDelete } = this.props
    return (
      <div
        className={`border-y-2 py-3 border-primary ${
          this.props.isActive ? "bg-secondary bg-opacity-25" : ""
        }`}
        // style={{
        //     width: size.width,
        //     height: size.height,
        // }}
        key={id}
        onMouseEnter={this.mouseEnterHandler}
        onMouseLeave={this.mouseLeaveHandler}
        onClick={this.onClickHandler}
      >
        {/* <div className="Marker"/> */}
        <div className="flex gap-2 items-center px-6 justify-between ">
          {/* <div className="DropdownLabel"
                        ref={ref => this.dropdownLabel = ref}
                        onClick={this.openDropdown}
                    >
                        {value ? value.name : "Select label"}
                    </div>
                    {this.state.isOpen && <div
                        // className="Dropdown"
                        // style={this.getDropdownStyle()}
                        ref={ref => this.dropdown = ref}
                    >
                        <div>
                            {this.getDropdownOptions()}
                        </div>

                    </div>} */}
          <div
            className={` ${value ? `bg-[#cff231]` : " hidden"} w-6 h-6 rounded-md`}
            style={value ? { backgroundColor: value.color } : {}}
          />
          <Dropdown
            show={this.state.isOpen}
            className="w-10/12"
            message={
              <div
                className="dropdown dropdown-start rounded-none p-0 w-full flex-grow"
                ref={this.dropdownRef}
              >
                <label
                  tabIndex={0}
                  className={` flex items-center rounded-none w-full border-b-2 ${
                    this.props.isActive ? "border-primary-content" : "border-secondary"
                  } hover:cursor-pointer hover:border-accent text-primary-content text-left text-sm fill-white disabled:bg-accent-content`}
                >
                  <div className="flex flex-row items-center py-1 pl-2">
                    <div className="whitespace-nowrap pr-2" onClick={this.openUpdateLabelPopup}>
                      {value ? value.name : "Select label"}
                    </div>
                    {/* <div className="flex flex-row ml-2">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                className="bi bi-arrow-down"
                                                viewBox="0 0 16 16"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
                                                />
                                            </svg>
                                        </div> */}
                  </div>
                </label>
                <ul
                  tabIndex={0}
                  className="dropdown-content menu drop-shadow-3xl bg-base-100 w-full rounded-md"
                >
                  {this.getDropdownOptions()}
                </ul>
              </div>
            }
            onClickOutside={() => {
              this.handleDropdownButtonClick()
            }}
          />

          <div className="ContentWrapper">
            <button
              className=" stroke-1 opacity-50 stroke-primary-content hover:opacity-100"
              onClick={() => onDelete(id)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                className="bi bi-trash3-fill "
                viewBox="0 0 16 16"
              >
                <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
              </svg>
            </button>
            {/* <ImageButton
                            externalClassName={"trash"}
                            image={"/ico/trash.png"}
                            imageAlt={"remove_rect"}
                            buttonSize={{ width: 30, height: 30 }}
                        /> */}
          </div>
        </div>
        {/* <div className="divider p-0 px-4 m-0"></div> */}
      </div>
    )
  }
}

const mapDispatchToProps = {
  updateHighlightedLabelId,
  updateActiveLabelId,
  updateActivePopupType,
}

const mapStateToProps = (state: AppState) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(LabelInputField)
