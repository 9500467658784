import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import { AppState } from "store"
import { ThemeToggle } from "../ThemeToggle/ThemeToggle"

export const AuthFlowWrapper: React.FunctionComponent = ({ children }) => {
  const [pageTitle, setPageTitle] = useState("")
  const location = useLocation()

  const currentTheme = useSelector((state: AppState) => state.general.theme)

  const hostname = window.location.hostname
  const logoSrc = hostname === "easyml.co" ? "easyML.png" : "/upjao-logo.png"

  const images = [
    "authImgCollage/1.jpg",
    "authImgCollage/2.jpg",
    "authImgCollage/3.jpg",
    "authImgCollage/4.jpg",
    "authImgCollage/5.jpg",
    "authImgCollage/6.jpg",
    "authImgCollage/7.jpg",
    "authImgCollage/8.jpg",
    "authImgCollage/9.jpg",
  ]

  useEffect(() => {
    const titles = {
      "/signin": "LOG IN",
      "/signup": "SIGN UP",
      "/requestcode": "ACCOUNT RECOVERY",
    }
    setPageTitle(titles[location.pathname] || "")
  }, [location])

  const Header = ({ pageTitle, logoSrc }) => {
    return (
      <header className="flex justify-between items-center px-12 py-8">
        <h1 className="font-extrabold font-poppins text-primary-content text-2xl">{pageTitle}</h1>
        <a href="/">
          <img width="130px" draggable={false} alt="logo" src={logoSrc} />
        </a>
        <div className="w-10 h-10 rounded-full flex items-center justify-center bg-primary">
          <ThemeToggle />
        </div>
      </header>
    )
  }

  const Sidebar = ({ currentTheme }) => {
    const themeClass = currentTheme === "dark" ? "text-black from-white" : "text-white from-black"
    return (
      <div className={`w-1/2 h-full my-auto relative rounded-r-[3rem] ${themeClass}`}>
        <div className="grid grid-cols-3 grid-rows-3 gap-6 h-full">
          {images.map((image, index) => (
            <div
              key={index}
              className={`relative ${index % 3 === 0 ? "row-span-2" : "row-span-2"} ${
                index % 4 === 0 ? "col-span-1" : "col-span-1"
              }`}
            >
              <img
                src={image}
                alt={`collage-${index}`}
                className="w-full h-full object-cover rounded-lg"
              />
              {currentTheme === "dark" && (
                <div className="absolute top-0 left-0 w-full h-full bg-black opacity-40 rounded-lg"></div>
              )}
            </div>
          ))}
        </div>
        <div
          className={`text-primary-content flex flex-col absolute top-[15%] left-16 w-[85%] h-4/6 z-10 backdrop-blur-md shadow-2xl ${
            currentTheme === "dark" ? "bg-black/30" : "bg-white/50"
          } rounded-3xl`}
        >
          <div className="pl-8 py-8 flex flex-col justify-center h-full">
            <h3 className="text-8xl font-bold m-4">Simple.</h3>
            <h3 className="text-8xl font-bold m-4">Fast.</h3>
            <h3 className="text-8xl font-bold m-4">Effective.</h3>
            <h3 className="text-4xl font-bold mx-4 my-10 tracking-wider">
              Data annotation made easy.
            </h3>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="flex font-poppins w-screen h-screen">
        <Sidebar currentTheme={currentTheme} />
        <div className="w-1/2 h-screen flex flex-col">
          <Header pageTitle={pageTitle} logoSrc={logoSrc} />
          <div className="flex flex-grow justify-end">
            <main className="flex justify-center w-full h-full items-center">{children}</main>
          </div>
          <div className="flex content-center justify-center py-4">
            <img src="fox_sleepy.svg" alt="" width={160} height={160}></img>
          </div>
        </div>
      </div>
    </>
  )
}
