import React from "react"
import { useFormik } from "formik"
import { connect } from "react-redux"
import { AppState } from "store"
import { updateProjectList } from "store/general/actionCreators"
import { ProjectData } from "store/general/types"
import { GenericYesNoPopup } from "../GenericYesNoPopup/GenericYesNoPopup"
import * as yup from "yup"
import { updateProjectNameAndMetaAPI } from "apis/projects"
import { PopupActions } from "../../../logic/actions/PopupActions"
import { toast } from "react-toastify"
import { ProjectMongoData } from "interfaces/IProjects"

interface IProps {
  projectData: ProjectData
  projectList: ProjectMongoData[]
  updateProjectListAction: (projectList: ProjectMongoData[]) => any
}

const AddNewProjectPopup: React.FC<IProps> = (props) => {
  const { projectData, projectList, updateProjectListAction } = props

  const formik = useFormik({
    initialValues: {
      projectName: projectData.name,
      metadata: projectData.metadata,
    },
    validationSchema: yup.object({
      projectName: yup.string().required(),
      metadata: yup.string().required(),
      //   images: yup.array().min(1, "Select at least one image"),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true)
      console.log("formik")
      console.log("editFormValues", values)
      const accessToken = localStorage.getItem("accessToken")
      updateProjectNameAndMetaAPI(projectData.id, values.projectName, values.metadata, accessToken)
        .then((response) => {
          let tempProjectList = projectList.map((project) => {
            if (project._id === projectData.id) {
              return {
                ...project,
                projectName: values.projectName,
                metadata: values.metadata,
              }
            }
            return project
          })
          updateProjectListAction(tempProjectList)
          toast.success("Project Data Edited Successfully.")
        })
        .catch((error) => {
          toast.error("Failed to Edit. Please try again")
          console.log("Failed to Edit", error)
        })
        .finally(() => {
          setSubmitting(false)
        })
    },
  })

  const renderContent = () => {
    return (
      <>
        <div className="w-full flex flex-col gap-6 pt-16 pb-8">
          <div>
            <label className="label">
              <span className="label-text font-medium text-base text-primary-content">
                Project Name
              </span>
            </label>
            <input
              id="projectName"
              onChange={formik.handleChange}
              value={formik.values.projectName}
              type="text"
              placeholder="Enter Project Name"
              className="input bg-secondary input-bordered w-full focus:input-accent hover:input-accent rounded-full"
              disabled={formik.isSubmitting}
            />
            <label className="label">
              <span className="label-text-alt text-error ">{formik.errors.projectName}</span>
            </label>
          </div>
          <div>
            <label className="label">
              <span className="label-text font-medium text-base text-primary-content">
                Metadata
              </span>
            </label>
            <input
              id="metadata"
              onChange={formik.handleChange}
              value={formik.values.metadata}
              type="text"
              placeholder="Enter Metadata"
              className="input input-bordered bg-secondary w-full focus:input-accent hover:input-accent rounded-full"
              disabled={formik.isSubmitting}
            />
            <label className="label">
              <span className="label-text-alt text-error ">{formik.errors.metadata}</span>
            </label>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <GenericYesNoPopup
        title={"Edit Project Data"}
        renderContent={renderContent}
        skipRejectButton={true}
        acceptLabel={"Edit"}
        onClose={() => PopupActions.close()}
        isFormik={() => formik}
        disableAcceptButton={formik.isSubmitting}
      />
    </>
  )
}

const mapStateToProps = (state: AppState) => ({
  projectData: state.general.projectData,
  projectList: state.general.projectList,
})

const mapDispatchToProps = {
  updateProjectListAction: updateProjectList,
}

export default connect(mapStateToProps, mapDispatchToProps)(AddNewProjectPopup)
