import { PopupWindowType } from "data/enums/PopupWindowType"
import AddNewButtonSvg from "./AddNewButtonSvg"
import { getUserRoleInProjectAPI } from "apis/projects"
import { ProjectData, UserData } from "store/general/types"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import { generatePreSignedGetUrl } from "apis/s3/uploadAndDownload"
import { ProjectMongoData } from "interfaces/IProjects"
import { ClipLoader } from "react-spinners"
import { CSSHelper } from "logic/helpers/CSSHelper"
import { ProjectType } from "data/enums/ProjectType"

interface IProps {
  updateActivePopupTypeAction: (activePopupType: PopupWindowType) => any
  updateProjectDataAction: (projectData: ProjectData) => any
  projectData: ProjectData
  projectList: ProjectMongoData[]
  isTableView: boolean
  displayMyProjects: boolean
  loading: boolean
  searchValue: string
  userData?: UserData
}

const ProjectView: React.FC<IProps> = (props) => {
  const navigator = useNavigate()

  const {
    updateActivePopupTypeAction,
    updateProjectDataAction,
    userData,
    isTableView,
    projectData,
    projectList,
    displayMyProjects,
    searchValue,
    loading,
  } = props

  const projectOnClickHandler = async (project: ProjectMongoData) => {
    const accessToken = localStorage.getItem("accessToken")
    const userRoleData = await getUserRoleInProjectAPI(project._id, userData?._id, accessToken)
    if (userRoleData.statusCode === 500 || !!userRoleData.error) {
      toast.error("Internal Server Error!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      })
      return
    } else {
      const userRoleInProject = userRoleData?.data?.data
      if (!userRoleInProject) {
        navigator("/not-found")
        return
      } else if (userRoleInProject === "admin") {
        window.open(`/projects/${project._id}`)
        return
      }
    }
    window.open(`/projects/${project._id}`)
  }

  const ProjectActions: React.FC<{ project: ProjectMongoData }> = ({ project }) => {
    return (
      <>
        <button
          className="stroke-current opacity-50 hover:stroke-primary-content hover:opacity-100 w-fit h-fit"
          onClick={(event) => {
            event.stopPropagation()
            event.preventDefault()
            updateProjectDataAction({
              ...projectData,
              type: project.projectType as ProjectType,
              id: project._id,
              name: project.projectName,
              metadata: project.metadata,
            })
            props.updateActivePopupTypeAction(PopupWindowType.EDIT_PROJECT)
          }}
        >
          <i className="bi bi-pencil"></i>
        </button>
        <button
          className="stroke-current opacity-50 hover:stroke-primary-content hover:opacity-100"
          onClick={(event) => {
            event.stopPropagation()
            event.preventDefault()
            updateProjectDataAction({
              ...projectData,
              type: project.projectType as ProjectType,
              id: project._id,
              name: project.projectName,
              metadata: project.metadata,
            })
            props.updateActivePopupTypeAction(PopupWindowType.MANAGE_PROJECT_MEMBERS)
          }}
        >
          <i className="bi bi-people"></i>
        </button>
        <button
          className="stroke-current opacity-50 hover:stroke-primary-content hover:opacity-100"
          onClick={(event) => {
            event.stopPropagation()
            event.preventDefault()
            props.updateProjectDataAction({
              ...projectData,
              type: project.projectType as ProjectType,
              id: project._id,
              name: project.projectName,
              metadata: project.metadata,
            })
            props.updateActivePopupTypeAction(PopupWindowType.DELETE_PROJECT)
          }}
        >
          <i className="bi bi-trash3-fill"></i>
        </button>
      </>
    )
  }

  const CardGridView = () => {
    return (
      <div className="grid grid-cols-6 gap-4 px-8 h-full overflow-y-scroll scrollbar-invisible group-hover:grid:scrollbar overflow-hidden flex-grow">
        <div
          onClick={() => updateActivePopupTypeAction(PopupWindowType.ADD_NEW_PROJECT)}
          className="add-project-button cursor-pointer card bg-opacity-0 border-2 border-primary hover:border-accent rounded-xl shadow-xl bg-primary group ease-in-out duration-300"
        >
          <div className="card-body h-full">
            <button className="w-3/5 py-10 mx-auto my-auto group-hover:scale-110 ease-in-out duration-300 stroke-current group-hover:stroke-primary-content">
              <AddNewButtonSvg />
            </button>
          </div>
        </div>
        <>
          {projectList.map((project, index) => (
            <div
              className="card rounded-xl shadow-xl bg-primary group"
              key={index}
              onClick={() => projectOnClickHandler(project)}
            >
              <figure className="p-2 aspect-square">
                <div className="h-full relative flex items-center justify-center overflow-hidden shadow-xl w-full rounded-lg">
                  <img
                    onError={(e) => e.currentTarget.classList.add("p-24")}
                    className="absolute w-full h-full transition-all transform bg-center bg-cover opacity-50 group-hover:opacity-100 group-hover:scale-110 ease-in-out duration-300"
                    src={generatePreSignedGetUrl(
                      `${
                        process.env.REACT_APP_NODE_ENV === "production" ? "production/" : "staging/"
                      }${project._id}/thumbnail.webp`,
                      "image/jpg"
                    )}
                    alt="Project"
                  />
                </div>
              </figure>
              <div className="card-body p-2">
                <div className="flex justify-between px-4 py-4">
                  <p className="card-title text-base font-normal opacity-50 group-hover:scale-110 group-hover:font-medium group-hover:text-base group-hover:opacity-100 ease-in-out duration-300">
                    {project.projectName}
                  </p>
                  <div className={`${displayMyProjects ? "card-actions justify-end" : "hidden"}`}>
                    <ProjectActions project={project} />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </>
      </div>
    )
  }

  const TableView = () => {
    if (!projectList || projectList.length === 0) {
      return <></>
    }

    return (
      <div className="overflow-auto scrollbar scrollbar-invisible">
        <table className="table w-full">
          {/* head */}
          <thead className="sticky top-0 bg-base-200 z-20">
            <tr>
              <th>Name</th>
              <th>Type</th>
              <th>Image Count</th>
              <th>Labels Count</th>
              <th>Members Count</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {projectList.map((project, index) => (
              <tr
                key={index}
                className="hover cursor-pointer w-full"
                onClick={() => projectOnClickHandler(project)}
              >
                <td>
                  <div className="flex items-center gap-3">
                    <div className="avatar">
                      <div className="rounded-lg w-12 h-12">
                        <img
                          src={generatePreSignedGetUrl(
                            `${
                              process.env.REACT_APP_NODE_ENV === "production"
                                ? "production/"
                                : "staging/"
                            }${project._id}/thumbnail.webp`,
                            "image/jpg"
                          )}
                          alt="Project"
                          className="z-10"
                        />
                      </div>
                    </div>
                    <div>
                      <div className="font-bold">{project.projectName}</div>
                    </div>
                  </div>
                </td>
                <td>{project.projectType}</td>
                <td>{project.imageIds.length}</td>
                <td>{project.labels.length}</td>
                <td>{Object.keys(project.userIds).length}</td>
                <td>
                  <div className="flex gap-x-2">
                    <ProjectActions project={project} />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }

  if (loading) {
    return (
      <div className="flex h-[60vh] justify-center items-center">
        <span className="loader">
          <ClipLoader size={50} color={CSSHelper.getLeadingColor()} loading={true} />
        </span>
      </div>
    )
  }

  if (!projectList || projectList.length === 0) {
    // if my projects tab and searchValue isn't null then we need to show the Add new Project Card
    if (!displayMyProjects || (displayMyProjects && searchValue && searchValue !== "")) {
      return (
        <div className="flex flex-col gap-4 h-[60vh] justify-center items-center">
          <img src="/nothing_found.svg" alt="nothing found" width={"15%"}></img>
          <span>Nothing here!!</span>
        </div>
      )
    }
  }

  if (isTableView) {
    return <TableView />
  }

  return <CardGridView />
}

export default ProjectView
