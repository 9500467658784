import React, { useState } from "react"
import "./ExportLabelPopup.scss"
import { AnnotationFormatType } from "../../../data/enums/AnnotationFormatType"
import { LabelType } from "../../../data/enums/LabelType"
import { ILabelFormatData } from "../../../interfaces/ILabelFormatData"
import { PopupActions } from "../../../logic/actions/PopupActions"
import GenericLabelTypePopup from "../GenericLabelTypePopup/GenericLabelTypePopup"
import { ExportFormatData } from "../../../data/ExportFormatData"
import { AppState } from "../../../store"
import { connect } from "react-redux"
import axios from "axios"
import { toast } from "react-toastify"
require("dotenv").config()

interface IProps {
  activeLabelType: LabelType
}

const getCSVRows = (_data: any, _labelType: string) => {
  console.log(_data)
  if (_labelType === "POINT") {
    const csvRows = _data.map((e) => {
      const _row = []
      for (var k of Object.keys(e)) {
        if (k === "point") {
          _row.push(e[k]["x"])
          _row.push(e[k]["y"])
        } else {
          _row.push(e[k])
        }
      }
      return _row
    })
    const headers = []
    for (var k of Object.keys(_data[0])) {
      if (k === "point") {
        headers.push("x")
        headers.push("y")
      } else {
        headers.push(k)
      }
    }
    return [headers].concat(csvRows)
  } else if (_labelType === "LINE") {
    const csvRows = _data.map((e) => {
      const _row = []
      for (var k of Object.keys(e)) {
        if (k === "line") {
          _row.push(e[k]["start"]["x"])
          _row.push(e[k]["start"]["y"])
          _row.push(e[k]["end"]["x"])
          _row.push(e[k]["end"]["y"])
        } else {
          _row.push(e[k])
        }
      }
      return _row
    })
    const headers = []
    for (var k of Object.keys(_data[0])) {
      if (k === "line") {
        headers.push("x1")
        headers.push("y1")
        headers.push("x2")
        headers.push("y2")
      } else {
        headers.push(k)
      }
    }
    return [headers].concat(csvRows)
  } else if (_labelType === "RECT") {
    const csvRows = _data.map((e) => {
      const _row = []
      for (var k of Object.keys(e)) {
        if (k === "rect") {
          _row.push(e[k]["x"])
          _row.push(e[k]["y"])
          _row.push(e[k]["width"])
          _row.push(e[k]["height"])
        } else {
          _row.push(e[k])
        }
      }
      return _row
    })
    const headers = []
    for (var k of Object.keys(_data[0])) {
      if (k === "rect") {
        headers.push("x")
        headers.push("y")
        headers.push("width")
        headers.push("height")
      } else {
        headers.push(k)
      }
    }
    return [headers].concat(csvRows)
  } else if (_labelType === "IMAGE RECOGNITION") {
    const csvRows = _data.map((e) => {
      const _row = []
      console.log(e)
      for (var k of Object.keys(e)) {
        console.log(k)
        if (k === "annotations") {
          _row.push("[" + e[k].toString() + "]")
        } else {
          _row.push(e[k])
        }
      }
      return _row
    })
    console.log(csvRows)
    const headers = []
    for (var k of Object.keys(_data[0])) {
      headers.push(k)
    }
    return [headers].concat(csvRows)
  } else {
    console.log("Label Type Not Recognised", _labelType)
  }
}

const downloadCSV = (_csvRows: any[], _filename: string) => {
  let csvContent = "data:text/csv;charset=utf-8," + _csvRows.map((e) => e.join(",")).join("\n")
  var encodedUri = encodeURI(csvContent)
  var link = document.createElement("a")
  link.setAttribute("href", encodedUri)
  link.setAttribute("download", _filename)
  document.body.appendChild(link) // Required for FF
  link.click() // This will download the file.
}

const downloadFile = ({ data, fileName, fileType }: any) => {
  const blob = new Blob([data], { type: fileType })
  const a = document.createElement("a")
  a.download = fileName
  a.href = window.URL.createObjectURL(blob)
  const clickEvt = new MouseEvent("click", {
    view: window,
    bubbles: true,
    cancelable: true,
  })
  a.dispatchEvent(clickEvt)
  a.remove()
}

const ExportLabelPopup: React.FC<IProps> = ({ activeLabelType }) => {
  const [labelType, setLabelType] = useState(activeLabelType)
  const [exportFormatType, setExportFormatType] = useState(null)

  const onAccept = (labelType: LabelType) => {
    let _labelType = ""
    switch (labelType) {
      case LabelType.RECT:
        // RectLabelsExporter.export(exportFormatType);
        _labelType = "labelRects"
        break
      case LabelType.POINT:
        // PointLabelsExporter.export(exportFormatType);
        _labelType = "labelPoints"
        break
      case LabelType.LINE:
        // LineLabelsExporter.export(exportFormatType);
        _labelType = "labelLines"
        break
      case LabelType.POLYGON:
        // PolygonLabelsExporter.export(exportFormatType);
        _labelType = "labelPolygons"
        break
      case LabelType.IMAGE_RECOGNITION:
        _labelType = "image_recognition"
    }
    console.log(labelType, exportFormatType)
    const _projectID = window.location.href.split("/")[4]
    if (_labelType) {
      const accessToken = localStorage.getItem("accessToken")
      if (exportFormatType === "YOLO") {
        const downloadAnnotationPromise = new Promise((resolve, reject) => {
          axios
            .post(
              `${process.env.REACT_APP_API_URL}/projects/downloadAnnotations`,
              {
                projectId: _projectID,
                annoType: _labelType,
                format: exportFormatType === "CSV" ? "JSON" : exportFormatType,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  accesstoken: accessToken,
                },
                responseType: "blob",
              }
            )
            .then(
              (res: any) => {
                try {
                  // create file link in browser's memory
                  const href = URL.createObjectURL(res.data)

                  // create "a" HTML element with href to file & click
                  const link = document.createElement("a")
                  link.href = href
                  link.setAttribute(
                    "download",
                    _projectID + "_" + _labelType + "_" + exportFormatType + ".zip"
                  ) //or any other extension
                  document.body.appendChild(link)
                  link.click()

                  // clean up "a" element & remove ObjectURL
                  document.body.removeChild(link)
                  URL.revokeObjectURL(href)
                  resolve(true)
                } catch (err) {
                  console.log(err)
                  reject(true)
                }
              },
              (err) => {
                reject(true)
              }
            )
        })
        toast.promise(downloadAnnotationPromise, {
          pending: "Downloading Annotations",
          success: "Downloaded Annotations",
          error: "Cannot Download Annotation",
        })
      } else {
        const downloadAnnotationPromise = new Promise((resolve, reject) => {
          axios
            .post(
              `${process.env.REACT_APP_API_URL}/projects/downloadAnnotations`,
              {
                projectId: _projectID,
                annoType: _labelType,
                format: exportFormatType === "CSV" ? "JSON" : exportFormatType,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  accesstoken: accessToken,
                },
              }
            )
            .then(
              (res: any) => {
                console.log(res)
                if (exportFormatType === "YOLO") {
                  return
                }
                const _data = res.data.data.labels
                if (exportFormatType === "JSON") {
                  console.log(_data)
                  downloadFile({
                    data: JSON.stringify(_data),
                    fileName: `${_projectID}_${_labelType}_annotations.json`,
                    fileType: "text/json",
                  })
                  resolve(true)
                } else if (exportFormatType === "CSV") {
                  const csvRows = getCSVRows(_data, labelType)
                  const csvName = `${_projectID}_${_labelType}_annotations.csv`
                  if (csvRows) {
                    downloadCSV(csvRows, csvName)
                  }
                  resolve(true)
                } else if (exportFormatType === "COCO") {
                  downloadFile({
                    data: JSON.stringify(res.data.data.COCOContent),
                    fileName: `${_projectID}_${_labelType}_annotations.json`,
                    fileType: "text/json",
                  })
                  resolve(true)
                } else {
                  console.log("Export format not recognized", exportFormatType)
                  reject(true)
                }
                reject(true)
              },
              (err) => {
                reject(true)
              }
            )
        })
        toast.promise(downloadAnnotationPromise, {
          pending: "Downloading Annotations",
          success: "Downloaded Annotations",
          error: "Cannot Download Annotation",
        })
      }
    }
    PopupActions.close()
  }

  const onReject = (labelType: LabelType) => {
    PopupActions.close()
  }

  const onSelect = (exportFormatType: AnnotationFormatType) => {
    setExportFormatType(exportFormatType)
  }
  // <div className='flex flex-col gap-2'>
  //     <div className="form-control">
  //         <label className="label cursor-pointer">
  //             <input onClick={() => onSelect(entry.type)} key={entry.type} checked={entry.type === exportFormatType}
  //                 type="radio" name="radio-10" className="mr-2 radio border-current checked:bg-white" />
  //             <span className="label-text">Object Detection</span>
  //         </label>
  //     </div>
  //     <div className="form-control">
  //         <label className="label cursor-pointer">
  //             <input onClick={() => onSelect(entry.type)} key={entry.type} checked={entry.type === exportFormatType} type="radio" name="radio-10" className="mr-2 radio border-current checked:bg-white" />
  //             <span className="label-text">Image Recognition</span>
  //         </label>
  //     </div>
  // </div>
  const getOptions = (exportFormatData: ILabelFormatData[]) => {
    return exportFormatData.map((entry: ILabelFormatData) => {
      return (
        <div>
          <div className="form-control items-start">
            <label className="label cursor-pointer">
              <input
                onClick={() => onSelect(entry.type)}
                key={entry.type}
                checked={entry.type === exportFormatType}
                type="radio"
                name="radio-10"
                className="mr-2 radio border-current checked:bg-primary-content"
              />
              <span className="label-text">{entry.label}</span>
            </label>
          </div>
        </div>
      )
    })
  }

  const renderInternalContent = (labelType: LabelType) => {
    console.log(labelType)
    if (["POINT", "RECT", "LINE", "POLYGON", "IMAGE RECOGNITION"].includes(labelType)) {
      return [
        <div className="my-12 h-48">
          <div className="text-center">
            Select label type and the file format you would like to use to export labels.
          </div>
          <div className="my-12">{getOptions(ExportFormatData[labelType])}</div>
        </div>,
      ]
    } else {
      return <div className="mt-8 h-48 text-center">Please select a label type to export</div>
    }
  }

  const onLabelTypeChange = (labelType: LabelType) => {
    setLabelType(labelType)
    setExportFormatType(null)
  }

  return (
    <GenericLabelTypePopup
      activeLabelType={labelType}
      title={!!labelType ? `Export ${labelType?.toLowerCase()} annotations` : "No Label Type Set"}
      onLabelTypeChange={onLabelTypeChange}
      acceptLabel={"Export"}
      onAccept={onAccept}
      skipRejectButton={true}
      disableAcceptButton={!exportFormatType}
      rejectLabel={"Cancel"}
      onReject={onReject}
      renderInternalContent={renderInternalContent}
    />
  )
}

const mapDispatchToProps = {}

const mapStateToProps = (state: AppState) => ({
  activeLabelType: state.labels.activeLabelType,
})

export default connect(mapStateToProps, mapDispatchToProps)(ExportLabelPopup)
