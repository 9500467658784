import { blogs } from "data/Blogs"
import { useState, useEffect } from "react"

const BlogsCarousel = () => {
  const [currentPage, setCurrentPage] = useState(0)
  const [itemsPerPage, setItemsPerPage] = useState(4)

  useEffect(() => {
    const updateItemsPerPage = () => {
      if (window.innerWidth >= 1800) {
        setItemsPerPage(4)
      } else if (window.innerWidth >= 1600) {
        setItemsPerPage(3)
      } else if (window.innerWidth >= 1050) {
        setItemsPerPage(2)
      } else {
        setItemsPerPage(1)
      }
    }

    updateItemsPerPage()
    window.addEventListener("resize", updateItemsPerPage)
    return () => window.removeEventListener("resize", updateItemsPerPage)
  }, [])

  const handleNext = () => {
    setCurrentPage((prevPage) => (prevPage + 1) % Math.ceil(blogs.length / itemsPerPage))
  }

  const handlePrev = () => {
    setCurrentPage(
      (prevPage) =>
        (prevPage - 1 + Math.ceil(blogs.length / itemsPerPage)) %
        Math.ceil(blogs.length / itemsPerPage)
    )
  }

  const displayBlogs = blogs.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage)

  return (
    <div className="w-full py-20 px-16 flex flex-col relative" id="our-blogs">
      <h2 className="text-6xl font-bold text-accent text-start md:text-start mb-4">Our Blogs</h2>
      <div className="flex py-10 gap-6 flex-wrap justify-center">
        {displayBlogs.map((blog, index) => (
          <div className="card card-compact w-96 h-[50vh] shadow-xl" key={index}>
            <figure className="w-full h-64">
              <img src={blog.imgSrc} alt={blog.title} className="w-full h-full object-cover" />
            </figure>
            <div className="card-body bg-base-200">
              <h2 className="card-title text-primary-content">{blog.title}</h2>
              <p>{blog.description}</p>
              <div className="card-actions justify-end">
                <a className="btn btn-accent" href={blog.href}>
                  Read More
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
      <button
        className="text-5xl absolute left-16 top-1/2 transform -translate-y-1/2 focus:outline-none"
        onClick={handlePrev}
      >
        ❮
      </button>
      <button
        className="text-5xl absolute right-16 top-1/2 transform -translate-y-1/2 focus:outline-none"
        onClick={handleNext}
      >
        ❯
      </button>
    </div>
  )
}

export default BlogsCarousel
