import React, { useState, useEffect, PropsWithChildren, useRef } from "react"
import { AppState } from "../../../store"
import { connect } from "react-redux"
import { GenericYesNoPopup } from "../GenericYesNoPopup/GenericYesNoPopup"
import { useFormik } from "formik"
import * as yup from "yup"
import { toast } from "react-toastify"
import { sendInvitation } from "apis/invitations"
import { GeneralSelector } from "store/selectors/GeneralSelector"
import {
  getUserRoleInProjectAPI,
  listUsersInProjectAPI,
  removeUserFromProjectAPI,
  updateUserRoleInProjectAPI,
} from "apis/projects"
import { useLocation } from "react-router-dom"
import { ProjectData } from "store/general/types"
import LinearProgressWithLabel from "./../../Common/ProgressBar/LinearProgressWithLabel"
import LoadingBar from "react-top-loading-bar"

interface IProps {
  projectData: ProjectData
}

const ContributorList = ({ projectId, isAdmin, usersInCurrentProject }) => {
  const [clicked, setClicked] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)
  const [isDelete, setIsDelete] = useState(false)
  const [selectedRole, setSelectedRole] = useState("")
  const [contributorId, setContributorId] = useState("")
  const [index, setIndex] = useState(null)

  const updateUserRoleInProject = async (role, userId, idx) => {
    setClicked((prev) => !prev)
    setIndex(idx)
    setContributorId(userId)
    setIsUpdate(true)
    setIsDelete(false)
    setSelectedRole(role)
  }

  const callUpdateUserRoleInProjectAPI = async (userId) => {
    setClicked((prev) => !prev)
    const accessToken: string = localStorage.getItem("accessToken")
    try {
      const { statusCode, error, data } = await updateUserRoleInProjectAPI(
        projectId,
        userId,
        selectedRole,
        accessToken
      )
      console.log({ statusCode, data })
      if (statusCode === 500 || error) {
        console.log("Some Error Occurred in updateUserRoleInProjectAPI")
        toast.error("Internal Server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        })
        return { ok: false }
      } else {
        toast.success("Role changed for this user!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        })
        return {
          ok: true,
        }
      }
    } catch (error) {
      console.error(error)
      toast.error("Unexpected server error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      })
      return {
        ok: false,
      }
    }
  }

  const callRemoveUserAPI = async (userId, index) => {
    setClicked((prev) => !prev)
    const accessToken: string = localStorage.getItem("accessToken")
    usersInCurrentProject.splice(index, 1)

    try {
      const { statusCode, error, data } = await removeUserFromProjectAPI(
        projectId,
        userId,
        accessToken
      )
      console.log({ statusCode, data })
      if (statusCode === 500 || error) {
        console.log("Some Error Occurred in removeUserFromProjectAPI")
        toast.error("Internal Server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        })
        return { ok: false }
      } else {
        // usersInCurrentProject.splice(index, 1);
        toast.success("Removed the user from this project!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        })
        return {
          ok: true,
        }
      }
    } catch (error) {
      console.error(error)
      toast.error("Unexpected server error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      })
      return {
        ok: false,
      }
    }
  }

  const removeUser = (userId, idx) => {
    setClicked((prev) => !prev)
    setContributorId(userId)
    setIndex(idx)
    setIsDelete(true)
    setIsUpdate(false)
  }

  const confirmPopup = () => {
    if (isUpdate) {
      return callUpdateUserRoleInProjectAPI(contributorId)
    }

    if (isDelete) {
      return callRemoveUserAPI(contributorId, index)
    }
  }

  return (
    <>
      {!!usersInCurrentProject &&
        usersInCurrentProject.length > 0 &&
        usersInCurrentProject.map((contributor, i) => (
          <>
            <div
              className={`${
                clicked && index === i
                  ? "hidden"
                  : "flex items-center px-2 gap-4 border-b-2 border-opacity-25 border-base-content"
              }`}
            >
              <ul className="flex flex-col gap-2 w-full">
                <li className="flex items-center gap-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-person-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                  </svg>
                  {contributor.name}
                </li>
                <li className="flex items-center gap-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-envelope-at-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M2 2A2 2 0 0 0 .05 3.555L8 8.414l7.95-4.859A2 2 0 0 0 14 2H2Zm-2 9.8V4.698l5.803 3.546L0 11.801Zm6.761-2.97-6.57 4.026A2 2 0 0 0 2 14h6.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586l-1.239-.757ZM16 9.671V4.697l-5.803 3.546.338.208A4.482 4.482 0 0 1 12.5 8c1.414 0 2.675.652 3.5 1.671Z" />
                    <path d="M15.834 12.244c0 1.168-.577 2.025-1.587 2.025-.503 0-1.002-.228-1.12-.648h-.043c-.118.416-.543.643-1.015.643-.77 0-1.259-.542-1.259-1.434v-.529c0-.844.481-1.4 1.26-1.4.585 0 .87.333.953.63h.03v-.568h.905v2.19c0 .272.18.42.411.42.315 0 .639-.415.639-1.39v-.118c0-1.277-.95-2.326-2.484-2.326h-.04c-1.582 0-2.64 1.067-2.64 2.724v.157c0 1.867 1.237 2.654 2.57 2.654h.045c.507 0 .935-.07 1.18-.18v.731c-.219.1-.643.175-1.237.175h-.044C10.438 16 9 14.82 9 12.646v-.214C9 10.36 10.421 9 12.485 9h.035c2.12 0 3.314 1.43 3.314 3.034v.21Zm-4.04.21v.227c0 .586.227.8.581.8.31 0 .564-.17.564-.743v-.367c0-.516-.275-.708-.572-.708-.346 0-.573.245-.573.791Z" />
                  </svg>
                  {contributor.email}
                </li>
                <li>
                  {isAdmin && contributor.role !== "admin" ? (
                    <div className="flex gap-2 item-center w-full">
                      <select
                        className="select select-bordered flex-grow rounded-full mb-2"
                        onChange={(event) =>
                          updateUserRoleInProject(event.target.value, contributor?._id, i)
                        }
                      >
                        <option disabled selected>
                          {contributor.role}
                        </option>
                        <option value="annotator">Annotator</option>
                        <option value="reviewer">Reviewer</option>
                      </select>
                      <button
                        className="stroke-1 opacity-50 stroke-primary-content hover:opacity-100"
                        onClick={() => removeUser(contributor?._id, i)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          className="bi bi-trash3-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                        </svg>
                      </button>
                    </div>
                  ) : (
                    <div className="flex items-center gap-4 capitalize">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-gear-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z" />
                      </svg>
                      {contributor?.role}
                    </div>
                  )}
                </li>
              </ul>
            </div>

            {/* <div className={`${!clicked ? "hidden" : "flex gap-2 mx-1"}`}> */}
            <div className={`${clicked && index === i ? "flex gap-2 mx-2" : "hidden"}`}>
              <p>
                Do you want to
                {isUpdate ? " change this user's role?" : isDelete ? " remove this user?" : ""}
              </p>
              <button className="btn btn-success" onClick={confirmPopup}>
                YES
              </button>

              <button className="btn btn-error" onClick={() => setClicked((prev) => !prev)}>
                NO
              </button>
            </div>
          </>
        ))}
    </>
  )
}

const ManageProjectMembers: React.FC<IProps> = (props: PropsWithChildren<IProps>) => {
  const location = useLocation()
  console.log({ location })
  const [progress, setProgress] = useState(-1)
  const [usersInCurrentProject, setUsersInCurrentProject] = useState([])
  const [isAdmin, setIsAdmin] = useState(false)
  const [invitedUserMailId, setInvitedUserMailId] = useState("")
  const [invitedUserRole, setInvitedUserRole] = useState("annotator")
  const [topBarprogress, setTopBarProgress] = useState(0)

  const projectId =
    GeneralSelector.getProjectId() !== "my-project-id"
      ? GeneralSelector.getProjectId()
      : props.projectData?.id
  console.log({ pid: projectId })

  useEffect(() => {
    const fetchUsersInProject = async () => {
      setTopBarProgress(20)
      const accessToken: string = localStorage.getItem("accessToken")
      const { data } = await listUsersInProjectAPI(projectId, accessToken)
      console.log({ data: data?.data?.payload })
      setUsersInCurrentProject(data?.data?.payload)

      const userId = JSON.parse(localStorage.getItem("userInfo"))?.data?._id
      const userRoleData = await getUserRoleInProjectAPI(projectId, userId, accessToken)
      console.log({ userRoleData })
      setTopBarProgress(100)
      setIsAdmin(userRoleData?.data?.data === "admin" ? true : false)
    }
    fetchUsersInProject()
  }, [])

  // const formik = useFormik({
  //   initialValues: {
  //     email: "",
  //     role: "annotator",
  //   },
  //   validationSchema: yup.object({
  //     email: yup.string().email().required(),
  //     role: yup.string().required(),
  //   }),
  //   onSubmit: (values) => {
  //     console.log(values);
  //     sendInvitationToUser(values.email, values.role);
  //   },
  // });

  const sendInvitationToUser = async () => {
    try {
      setProgress(50)
      const accessToken = localStorage.getItem("accessToken")
      const userInfo = JSON.parse(localStorage.getItem("userInfo")).data
      const projectId = GeneralSelector.getProjectId()
      const projectName = GeneralSelector.getProjectName()
      const adminMailId = userInfo.email
      const toastId = toast.loading("Mail is being sent...")
      const { statusCode } = await sendInvitation(
        projectId,
        projectName,
        invitedUserRole,
        adminMailId,
        invitedUserMailId,
        accessToken
      )
      if (statusCode === 200) {
        toast.update(toastId, {
          render: "Mail sent successfully!",
          type: "success",
          isLoading: false,
          autoClose: 2000,
        })
      } else {
        toast.update(toastId, {
          render: "Mail could not be sent to the user. Please try again!",
          type: "error",
          isLoading: false,
          autoClose: 2000,
        })
      }
      setProgress(0)
    } catch (error) {
      setProgress(0)
      console.error(error)
    }
  }

  const renderContent = () => {
    return (
      <>
        <div>
          <LoadingBar
            color="#f11946"
            progress={topBarprogress}
            onLoaderFinished={() => setTopBarProgress(0)}
          />
          <div className="pt-4 pb-8 text-center">
            <p>Manage your project contributors </p>
          </div>
          <div className="flex-grow flex flex-col gap-2 h-52 overflow-x-hidden overflow-y-scroll scrollbar-invisible hover:scrollbar">
            <>
              {/* <div className="flex items-center px-2 gap-4 border-b-2 border-opacity-25 border-base-content">
                  <ul className="flex flex-col gap-2 w-full">
                    <li>{contributor.name}</li>
                    <li>{contributor.email}</li>
                    <li>
                      {admin ? (
                        <div className="flex gap-2 item-center w-full">
                          <select
                            className="select select-bordered flex-grow rounded-full"
                            // id="role"
                            // onChange={formik.handleChange}
                            // value={formik.values.role}
                          >
                            <option
                              disabled
                              selected
                              // defaultValue="annotator"
                            >
                              {contributor.role}
                            </option>
                            <option value="annotator">Annotator</option>
                            <option value="reviewer">Reviewer</option>
                          </select>
                          <button
                            className={`${
                              !!isButtonChanged
                                ? "hidden"
                                : "stroke-current hover:stroke-white"
                            }`}
                            onClick={(event) => {
                              // console.log({ event });
                              setIsButtonChanged(true);
                              // contributor.email = "bhargabnath691@gmail.com";
                              // console.log({ contributor });
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              className="bi bi-trash3-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                            </svg>
                          </button>

                          <button
                            className={`${
                              !isButtonChanged ? "hidden" : "btn btn-primary"
                            }`}
                            onClick={(event) => {}}
                          >
                            YES
                          </button>

                          <button
                            className={`${
                              !isButtonChanged ? "hidden" : "btn btn-error"
                            }`}
                            onClick={(event) => {}}
                          >
                            NO
                          </button>
                        </div>
                      ) : (
                        <>{contributor.role}</>
                      )}
                    </li>
                  </ul>
                </div> */}

              <>
                {usersInCurrentProject.length > 0 && topBarprogress === 0 ? (
                  <ContributorList
                    projectId={projectId}
                    isAdmin={isAdmin}
                    usersInCurrentProject={usersInCurrentProject}
                  />
                ) : (
                  <div>Loading...</div>
                )}
              </>

              {/* <ContributorList
                isAdmin={isAdmin}
                projectId={projectId}
                usersInCurrentProject={usersInCurrentProject}
              /> */}
            </>
          </div>
          {/* <div
            className="m-auto mt-3 h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
            role="status">
            <span
              className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
              >Loading...</span
            >
          </div> */}
          <div className="w-full mt-2">{LinearProgressWithLabel(progress)}</div>

          <div className="pt-8 flex flex-col gap-1">
            <div className="w-full">
              <label className="label">
                <span className="font-medium text-primary-content">
                  Send Invitation link to team members
                </span>
              </label>
              <input
                id="email"
                onChange={(e) => setInvitedUserMailId(e.target.value)}
                value={invitedUserMailId}
                type="email"
                placeholder="Invite by email"
                className="w-full input input-bordered focus:input-accent hover:input-accent rounded-full my-1"
              />
              {/* <label className="label">
                <span className="label-text-alt text-error ">
                  {formik.errors.email}
                </span>
              </label> */}
            </div>
            <select
              className="select select-bordered rounded-full w-full mt-1 mb-2"
              id="role"
              onChange={(e) => setInvitedUserRole(e.target.value)}
              value={invitedUserRole}
            >
              <option
                disabled
                selected
                // defaultValue="annotator"
              >
                Select Role
              </option>
              <option value="annotator">Annotator</option>
              <option value="reviewer">Reviewer</option>
            </select>
            {/* <label className="label">
              <span className="label-text-alt text-error ">
                {formik.errors.role}
              </span>
            </label> */}
          </div>
        </div>
      </>
    )
  }

  return (
    <GenericYesNoPopup
      title={"Manage contributors"}
      renderContent={renderContent}
      acceptLabel={"Send Invite"}
      onAccept={sendInvitationToUser}
      skipRejectButton
      // isFormik={() => formik}
      disableAcceptButton={progress > 0}
    />
  )
}

const mapDispatchToProps = {}

const mapStateToProps = (state: AppState) => ({
  projectData: state.general.projectData,
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageProjectMembers)
