import { deleteProjectAPI } from "apis/projects"
import { deleteAWSProjectFolder } from "apis/s3/deleteItem"
import { AuthContext } from "contexts/authContext"
import { PopupActions } from "logic/actions/PopupActions"
import React, { PropsWithChildren, useContext } from "react"
import { connect } from "react-redux"
import { toast } from "react-toastify"
import { AppState } from "store"
import { updateProjectData, updateUserData } from "store/general/actionCreators"
import { ProjectData, UserData } from "store/general/types"
import { GenericYesNoPopup } from "../GenericYesNoPopup/GenericYesNoPopup"

interface IProps {
  updateUserDataAction: (userData: UserData) => any
  updateProjectDataAction: (projectData: ProjectData) => any
  projectData: ProjectData
  userData: UserData
}

const DeleteProjectPopup: React.FC<IProps> = (props: PropsWithChildren<IProps>) => {
  const authContext = useContext(AuthContext)
  function renderContent() {
    return (
      <>
        <div className="pb-4">
          <div className="pt-2 pb-5 text-center text-base">{`Are you sure you want to delete project ${props.projectData?.name}`}</div>
        </div>
      </>
    )
  }

  const deleteProject = async () => {
    // const projectId = GeneralSelector.getProjectId();
    const projectId = props.projectData?.id
    // const projectName = GeneralSelector.getProjectName();
    const projectName = props.projectData?.name
    const project = `${projectId}_${projectName}`
    // let userData = GeneralSelector.getUserData();
    let userData = props.userData
    const projectIndex = userData.adminProjectIds.indexOf(project)
    console.log({ project, projectIndex })
    // PopupActions.close();
    if (projectIndex > -1) {
      userData.adminProjectIds.splice(projectIndex, 1)
      // store.dispatch(updateUserData(userData));
      const accessToken: string = localStorage.getItem("accessToken")
      const deleteProjectResponse = await deleteProjectAPI(project, userData.cognitoId, accessToken)
      console.log({ deleteProjectResponse })
      PopupActions.close()
      //   setUserData({
      //     ...userData,
      //     adminProjectIds: userData.adminProjectIds,
      //   });
      if (deleteProjectResponse?.statusCode === 200) {
        toast.success("Project deleted successfully!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        })
        console.log({ userData })
        const newUserData: UserData = {
          ...props.userData,
          adminProjectIds: [...userData.adminProjectIds],
        }
        // props.updateUserDataAction(userData);
        props.updateUserDataAction(newUserData)
        const projectFolderToDelete: string = `${
          process.env.REACT_APP_NODE_ENV === "production" ? "production/" : "staging/"
        }${projectId}`
        await deleteAWSProjectFolder(projectFolderToDelete)
        let userInfo = JSON.parse(localStorage.getItem("userInfo"))
        userInfo = {
          ...userInfo,
          data: userData,
        }
        console.log({ USER_INFO: userInfo })
        authContext.setUserInfo(userInfo)
        // var adminProjects = [...currentAdminProjects];
        // adminProjects.splice(adminProjects.indexOf(project), 1);
        // setCurrentAdminProjects(adminProjects);
      } else {
        toast.error("Internal Server Error!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        })
        console.log("Project could not be deleted!")
      }
    } else {
      toast.error("The project not found in the database!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      })
    }
  }

  return (
    <>
      <GenericYesNoPopup
        title={"Delete Project"}
        renderContent={renderContent}
        acceptLabel={"Confirm"}
        onAccept={deleteProject}
        skipRejectButton
      />
    </>
  )
}

const mapDispatchToProps = {
  updateUserDataAction: updateUserData,
  updateProjectDataAction: updateProjectData,
}

const mapStateToProps = (state: AppState) => ({
  projectData: state.general.projectData,
  userData: state.general.userData,
})

// export default DeleteProjectPopup;
export default connect(mapStateToProps, mapDispatchToProps)(DeleteProjectPopup)
