import React, { useRef, useState, useCallback, useContext } from "react"
import { useFormik } from "formik"
import * as yup from "yup"
import { ResetPasswordView } from "views/Auth/ResetPasswordView/ResetPasswordView"
import { AuthContext } from "contexts/authContext"
import { toast } from "react-toastify"

export const ForgotView = (props: any) => {
  const [resetPassword, setResetPassword] = useState<JSX.Element | null>(null)
  const inputRefs = useRef<Array<HTMLInputElement | null>>(Array(6).fill(null))
  const { email } = props.state
  const authContext = useContext(AuthContext)

  const formik = useFormik({
    initialValues: { otp: "" },
    validationSchema: yup.object({
      otp: yup.string().length(6).required("Please enter the OTP"),
    }),
    onSubmit: (values) => {
      setResetPassword(
        <ResetPasswordView state={{ email: props.state["email"], otp: values.otp }} />
      )
    },
  })

  const handleInputChange = useCallback(
    (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target
      const updatedOtp = [...formik.values.otp]
      updatedOtp[index] = value || null
      formik.setFieldValue("otp", updatedOtp.join(""))
      console.log({ index, updatedOtp, inputRefs })

      if (!value && index > 0) {
        inputRefs.current[index - 1]?.focus()
      } else if (value && index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1]?.focus()
      } else if (value && index === inputRefs.current.length - 1) {
        inputRefs.current[index]?.blur()
      }
    },
    [formik.values.otp]
  )

  const sendCodeClicked = async (email) => {
    try {
      await authContext.sendCode(email)
      toast.success("Email Sent!")
    } catch (err) {
      console.log(err)
      if (err instanceof Error) {
        toast.error(err.message, {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
    }
  }

  console.log("OTP:", formik.values.otp) // Check the value in the console

  return (
    <>
      {resetPassword === null ? (
        <div className="flex flex-col justify-center w-full flex-grow items-center">
          <div className="py-1">
            <div className="text-5xl tracking-widest font-black text-accent">
              Account
              <span className="text-5xl ml-3 tracking-widest font-black text-accent-content">
                Recovery
              </span>
            </div>
          </div>
          <div className="w-3/4 h-full flex flex-col justify-center">
            <p className="text-center my-4">
              To help keep your account safe,{" "}
              {window.location.hostname.includes("upjao.ai") ? "Upjao" : "EasyML"} wants to make
              sure it’s really you trying to sign in
            </p>
            <p className="my-2 mx-auto text-lg">
              Email sent to
              <span className="ml-1 text-accent">{email}</span>
            </p>
            <form className="w-full" onSubmit={formik.handleSubmit}>
              <div className="flex justify-center pt-10 space-x-4">
                {Array.from({ length: 6 }, (_, index) => (
                  <input
                    key={index}
                    ref={(el) => (inputRefs.current[index] = el)}
                    type="text"
                    value={formik.values.otp[index] || ""}
                    maxLength={1}
                    onChange={(event) => handleInputChange(index, event)}
                    className={`input text-2xl input-bordered focus:input-accent bg-secondary hover:input-accent rounded-md w-20 h-20 text-center`}
                  />
                ))}
              </div>
              <div className="pt-4 w-3/4 mx-auto">
                <button
                  disabled={!(formik.isValid && formik.dirty)}
                  type="submit"
                  className="btn my-4 w-full rounded-full py-3 border-none bg-accent-content text-primary-content text-opacity-50 hover:text-white hover:text-opacity-100 hover:bg-accent disabled:bg-accent-content"
                >
                  Verify OTP
                </button>
              </div>

              <label className="label">
                <span className="label-text-alt text-error">
                  {formik.touched.otp && formik.errors.otp}
                </span>
              </label>
            </form>
          </div>
          <span className="label-text font-medium text-md">
            <h3 className="text-center">
              Didn’t receive OTP?{" "}
              <span
                className="ml-2 text-accent cursor-pointer"
                onClick={() => sendCodeClicked(email)}
              >
                Resend OTP
              </span>
            </h3>
          </span>
        </div>
      ) : (
        resetPassword
      )}
    </>
  )
}
