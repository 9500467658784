import { useEffect, useRef } from "react"

export function Dropdown(props) {
  const ref = useRef(null)
  const { onClickOutside } = props
  const { onClick } = props
  const { className } = props

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        console.log("x")
        if (props.show) onClickOutside && onClickOutside()
      } else {
        console.log("y")

        onClickOutside && onClickOutside()
      }
    }
    document.addEventListener("click", handleClickOutside, true)
    return () => {
      document.removeEventListener("click", handleClickOutside, true)
    }
  }, [onClickOutside])

  return (
    <div ref={ref} className={className && className}>
      {props.message}
    </div>
  )
}
