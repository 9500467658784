import { connect } from "react-redux"
import { GenericYesNoPopup } from "../GenericYesNoPopup/GenericYesNoPopup"
import { AppState } from "../../../store"
import { useState } from "react"
import { UploadImageToS3 } from "apis/s3/uploadAndDownload"
import { connectImageToProject, generateImageDocument } from "apis/images/index"
import LinearProgressWithLabel from "views/Common/ProgressBar/LinearProgressWithLabel"
import { updateActivePopupData, updateActivePopupType } from "store/general/actionCreators"
import { addImageData, updateActiveLabelNameId } from "store/labels/actionCreators"
import { ImageDataUtil } from "utils/ImageDataUtil"
import { FileUtil } from "../../../utils/FileUtil"

const UploadErrorPopup = (props) => {
  const uploadErrorFiles = props.errorFiles
  const [progress, setProgress] = useState(-10)

  const onClick = (e: React.MouseEvent<HTMLButtonElement> = null) => {
    e.preventDefault()
    uploadImage(uploadErrorFiles, props.projectId)
  }

  const uploadImage = async (files: { file_name: string; file: File }[], projectId) => {
    const error = []
    // name folder in aws s3 as per NODE_ENV
    setProgress(1)
    let projectFolderInititial
    if (process.env.REACT_APP_NODE_ENV === "production") {
      projectFolderInititial = `production/${projectId}`
    } else {
      projectFolderInititial = `staging/${projectId}`
    }
    var perIncrement = 100 / files.length
    const accessToken: string = localStorage.getItem("accessToken")
    // next, loop through indices of files and images, uploading respectively
    const successFileName = []
    const successFiles = []
    // next, loop through indices of files and images, uploading respectively
    for (let i = 0; i < files.length; i++) {
      const file = files[i].file
      const imageElement = await FileUtil.loadImage(file)
      var image = await generateImageDocument(
        file.name,
        imageElement.height,
        imageElement.width,
        accessToken
      )
      if (image["error"] == null) {
        const imageId = image["data"]["data"]["images"].find(
          (image) => image.imageName === files[i].file_name
        )?._id
        const response = await UploadImageToS3(
          `${projectFolderInititial}/original/${imageId}`,
          file,
          80
        )
        if (response.status !== 200) {
          error.push({ file_name: file.name, file: file })
        } else {
          connectImageToProject(projectId, [imageId.split("_")[0] + "_" + file.name], accessToken)
          successFileName.push(file.name)
          successFiles.push({ file: file, image_id: imageId })
        }
      } else {
        error.push({ file_name: file.name, file: file })
      }
      setProgress((prevState) => {
        return (prevState += perIncrement)
      })
    }
    const labels = {
      labelLines: [],
      labelPoints: [],
      labelRects: [],
      labelPolygons: [],
      labelNameIds: [],
    }
    console.log("successfiles", successFiles.length)
    await props.addImageDataAction(
      successFiles.map((file: any) =>
        ImageDataUtil.createImageDataFromFileData(file.file, labels, file.image_id)
      )
    )
    props.updateActivePopupTypeData(error)
    if (error.length !== 0) {
      props.updateActiveLabelNameIdAction(1)
      setProgress(-10)
    } else {
      props.updateActivePopupTypeAction(null)
      props.updateActivePopupTypeData(null)
    }
    window.history.replaceState({}, document.title)
    // window.location.reload();
  }

  function renderContent() {
    return (
      <>
        <div className="pb-4">
          <div className="pt-2 pb-5 text-center text-base">
            {uploadErrorFiles.length} files failed to upload
          </div>
          <ul className="flex flex-col gap-2 w-full h-56 overflow-y-scroll scrollbar-invisible hover:scrollbar">
            {uploadErrorFiles.map((file, index) => (
              <li>{file.file_name}</li>
            ))}
          </ul>
          <div className="w-full">{LinearProgressWithLabel(progress)}</div>
        </div>
      </>
    )
  }

  return (
    <>
      <GenericYesNoPopup
        title={"Upload Failed"}
        renderContent={renderContent}
        acceptLabel={"Retry"}
        onAccept={onClick}
        skipRejectButton
        disableAcceptButton={progress >= 0}
      />
    </>
  )
}

const mapDispatchToProps = {
  updateActivePopupTypeAction: updateActivePopupType,
  updateActivePopupTypeData: updateActivePopupData,
  addImageDataAction: addImageData,
  updateActiveLabelNameIdAction: updateActiveLabelNameId,
}

const mapStateToProps = (state: AppState) => ({
  errorFiles: state.general.activePopUpData,
  projectId: state.general.projectData.id,
  imageData: state.labels.imagesData,
})

export default connect(mapStateToProps, mapDispatchToProps)(UploadErrorPopup)
