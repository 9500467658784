import React from "react"
import "./ColorSelectorView.scss"

interface IProps {
  color: string
  onClick: () => any
}

export const ColorSelectorView: React.FC<IProps> = ({ color, onClick }) => {
  return (
    <div
      className={`btn btn-circle border-accent-content hover:border-accent-content `}
      style={{
        backgroundColor: color,
      }}
      onClick={onClick}
    >
      <img draggable={false} width={16} alt={"refresh"} src={"/ico/refresh.png"} />
    </div>
  )
}
