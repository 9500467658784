import axios from "axios"
import { ImageStatus } from "data/enums/ImageStatus"
import { LabelType } from "data/enums/LabelType"
import { ProjectMongoData } from "interfaces/IProjects"
require("dotenv").config()

export const getProjectInfo = async (
  projectId: string,
  accessToken: string,
  imageStatus: string = null
) => {
  try {
    if (imageStatus === null || imageStatus === undefined) imageStatus = ImageStatus.UN_ANNOTATED
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/projects/getProjectInfo`,
      {
        projectId,
        imageStatus,
      },
      {
        headers: {
          "Content-Type": "application/json",
          accesstoken: accessToken,
        },
      }
    )
    return { statusCode: 200, data }
  } catch (error) {
    if (error instanceof Error) {
      return { statusCode: 500, error: error.message }
    }
  }
}

export const setProject = async (
  cognitoId: string,
  projectName: any,
  projectType: any,
  annotationType: string | number,
  metadata: any,
  imageList: any[],
  accessToken: string
) => {
  const images = []
  imageList.map((image: { name: any }) => {
    images.push({ imageName: image?.name })
  })
  try {
    if (annotationType === 1) {
      annotationType = "Point"
    } else if (annotationType === 2) {
      annotationType = "Line"
    } else if (annotationType === 3) {
      annotationType = "Bounding Box"
    } else {
      annotationType = "Polygon"
    }
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/projects/setProject`,
      {
        cognitoId,
        projectName,
        annotationType,
        metadata,
        projectType,
        images,
      },
      {
        headers: {
          "Content-Type": "application/json",
          accesstoken: accessToken,
        },
      }
    )
    return { statusCode: 200, data }
  } catch (error) {
    if (error instanceof Error) {
      return { statusCode: 500, error: error.message }
    }
  }
}

export const updateProjectAPI = async (projectId: any, imageList: any[], accessToken: string) => {
  const images = []
  imageList.map((image: { name: any }) => {
    images.push({ imageName: image?.name })
  })
  try {
    const { data } = await axios.put(
      `${process.env.REACT_APP_API_URL}/projects/updateProject`,
      {
        projectId,
        images,
      },
      {
        headers: {
          "Content-Type": "application/json",
          accesstoken: accessToken,
        },
      }
    )
    return { statusCode: 200, data }
  } catch (error) {
    if (error instanceof Error) {
      return { statusCode: 500, error: error.message }
    }
  }
}

export const updateProjectNameAndMetaAPI = async (
  projectId: string,
  projectName: string,
  metadata: string,
  accessToken: string
) => {
  console.log("projectId", projectId)
  try {
    const { data } = await axios.put(
      `${process.env.REACT_APP_API_URL}/projects/updateProjectNameAndMeta/${projectId}`,
      {
        projectName,
        metadata,
      },
      {
        headers: {
          "Content-Type": "application/json",
          accesstoken: accessToken,
        },
      }
    )
    return { statusCode: 200, data }
  } catch (error) {
    console.log("err", error)
    throw Error("Some Error Occured")
  }
}

export const deleteProjectAPI = async (
  projectId_Name: string,
  cognitoId: string,
  accessToken: string
) => {
  try {
    const payload = {
      projectid_name: projectId_Name,
      cognito_id: cognitoId,
    }
    const { data } = await axios.delete(`${process.env.REACT_APP_API_URL}/projects`, {
      data: payload,
      headers: {
        "Content-Type": "application/json",
        accesstoken: localStorage.getItem("accessToken"),
      },
    })
    console.log({ DELETE_PROJECT_RESPONSE: data })
    return { statusCode: 200, data }
  } catch (error) {
    if (error instanceof Error) {
      console.log(error)
      return { statusCode: 500, error: error.message }
    }
  }
}

export const updateLabels = async (projectId: any, labelList: any, accessToken: string) => {
  console.log({ labelslist: labelList })
  console.log({ labelslist: projectId })
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/projects/setLabelList`,
      {
        projectId,
        labelList,
      },
      {
        headers: {
          "Content-Type": "application/json",
          accesstoken: accessToken,
        },
      }
    )
    return { statusCode: 200, data }
  } catch (error) {
    if (error instanceof Error) {
      return { statusCode: 500, error: error.message }
    }
  }
}

export const getUserRoleInProjectAPI = async (
  projectId: string,
  userId: string,
  accessToken: string
) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/projects/getUserRoleInProject?projectId=${projectId}&userId=${userId}`,
      {
        headers: {
          "Content-Type": "application/json",
          accesstoken: accessToken,
        },
      }
    )
    return { statusCode: 200, data }
  } catch (error) {
    if (error instanceof Error) {
      return { statusCode: 500, error: error.message }
    }
  }
}

export const listUsersInProjectAPI = async (projectId: string, accessToken: string) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/projects/getUserInProject?projectId=${projectId}`,
      {
        headers: {
          "Content-Type": "application/json",
          accesstoken: accessToken,
        },
      }
    )
    return { statusCode: 200, data }
  } catch (error) {
    if (error instanceof Error) {
      return { statusCode: 500, error: error.message }
    }
  }
}

export const updateUserRoleInProjectAPI = async (
  projectId: any,
  userId: any,
  role: string,
  accessToken: string
) => {
  try {
    const { data } = await axios.put(
      `${process.env.REACT_APP_API_URL}/projects/updateUserRoleInProject`,
      {
        projectId,
        userId,
        role,
      },
      {
        headers: {
          "Content-Type": "application/json",
          accesstoken: accessToken,
        },
      }
    )
    return { statusCode: 200, data }
  } catch (error) {
    if (error instanceof Error) {
      return { statusCode: 500, error: error.message }
    }
  }
}

export const removeUserFromProjectAPI = async (
  projectId: any,
  userId: any,
  accessToken: string
) => {
  try {
    const payload = {
      projectId,
      userId,
    }
    const { data } = await axios.delete(
      `${process.env.REACT_APP_API_URL}/projects/removeUserFromProject`,
      {
        data: payload,
        headers: {
          "Content-Type": "application/json",
          accesstoken: localStorage.getItem("accessToken"),
        },
      }
    )
    console.log({ response: data })
    return { statusCode: 200, data }
  } catch (error) {
    if (error instanceof Error) {
      console.log(error)
      return { statusCode: 500, error: error.message }
    }
  }
}

export const getImageNames = async (projectId: string, accessToken: string) => {
  try {
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/projects/getImageNames`, {
      headers: {
        "Content-Type": "application/json",
        accesstoken: accessToken,
      },
      params: {
        projectId: projectId,
      },
    })
    return { statusCode: 200, data }
  } catch (error) {
    if (error instanceof Error) {
      return { statusCode: 500, error: error.message }
    }
  }
}

export const uploadAnnotations = async (
  projectId: string,
  annotationData: any[],
  annoType: LabelType,
  accessToken: string,
  format: string
) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/projects/uploadAnnotations`,
      {
        projectId: projectId,
        annotations: annotationData,
        annoType: annoType,
        format: format,
      },
      {
        headers: {
          "Content-Type": "application/json",
          accesstoken: accessToken,
        },
      }
    )
    return { statusCode: 200, data }
  } catch (error) {
    if (error instanceof Error) {
      return { statusCode: 500, error: error.message }
    }
  }
}

export const getProjectsByUserIdAndRole = async (
  {
    userId,
    isAdmin,
    page,
    recordsPerPage,
    sortType,
    searchQuery,
  }: {
    userId: string
    isAdmin: boolean
    page: number
    recordsPerPage: number
    sortType: string
    searchQuery: string
  },
  accessToken: string
): Promise<{ data: ProjectMongoData[]; totalProjects: number }> => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/projects/getProjectsByUser`,
      { userId, isAdmin, page, recordsPerPage, sortType, searchQuery },
      {
        headers: {
          accesstoken: accessToken,
        },
      }
    )
    return response.data
  } catch (error) {
    console.error("Error fetching projects:", error)
    throw error
  }
}
