import { LabelsActionTypes, LabelsState, ImageData } from "./types"
import { Action } from "../Actions"
import { ImageStatus } from "data/enums/ImageStatus"

const initialState: LabelsState = {
  activeImageIndex: null,
  activeLabelNameId: null,
  activeLabelType: null,
  activeLabelId: null,
  highlightedLabelId: null,
  highlightedLabel: null,
  imagesData: [],
  imageSize: 0,
  firstLabelCreatedFlag: false,
  labels: [],
  selectedImages: [],
  imageStatus: ImageStatus.ALL,
}

export function labelsReducer(state = initialState, action: LabelsActionTypes): LabelsState {
  switch (action.type) {
    case Action.UPDATE_ACTIVE_IMAGE_INDEX: {
      return {
        ...state,
        activeImageIndex: action.payload.activeImageIndex,
      }
    }
    case Action.UPDATE_ACTIVE_LABEL_NAME_ID: {
      return {
        ...state,
        activeLabelNameId: action.payload.activeLabelNameId,
      }
    }
    case Action.UPDATE_ACTIVE_LABEL_ID: {
      return {
        ...state,
        activeLabelId: action.payload.activeLabelId,
      }
    }
    case Action.UPDATE_HIGHLIGHTED_LABEL_ID: {
      return {
        ...state,
        highlightedLabelId: action.payload.highlightedLabelId,
      }
    }
    case Action.UPDATE_HIGHLIGHTED_LABEL: {
      return {
        ...state,
        highlightedLabel: action.payload.highlightedLabel,
      }
    }
    case Action.UPDATE_ACTIVE_LABEL_TYPE: {
      return {
        ...state,
        activeLabelType: action.payload.activeLabelType,
      }
    }
    case Action.UPDATE_IMAGE_DATA_BY_ID: {
      return {
        ...state,
        imagesData: state.imagesData.map((imageData: ImageData) =>
          imageData.id === action.payload.id ? action.payload.newImageData : imageData
        ),
      }
    }
    case Action.ADD_IMAGES_DATA: {
      return {
        ...state,
        imagesData: state.imagesData.concat(action.payload.imageData),
      }
    }
    case Action.UPDATE_IMAGES_DATA: {
      return {
        ...state,
        imagesData: action.payload.imageData,
      }
    }
    case Action.UPDATE_LABEL_NAMES: {
      return {
        ...state,
        labels: action.payload.labels,
      }
    }
    case Action.UPDATE_FIRST_LABEL_CREATED_FLAG: {
      return {
        ...state,
        firstLabelCreatedFlag: action.payload.firstLabelCreatedFlag,
      }
    }

    case Action.UPDATE_IMAGE_SIZE: {
      return {
        ...state,
        imageSize: action.payload.imageSize,
      }
    }

    case Action.UPDATE_SELECTED_IMAGES: {
      return {
        ...state,
        selectedImages: action.payload.selectedImages,
      }
    }

    case Action.UPDATE_IMAGE_STATUS: {
      return {
        ...state,
        imageStatus: action.payload.imageStatus,
      }
    }

    default:
      return state
  }
}
