import { AuthContext } from "contexts/authContext"
import { PopupWindowType } from "data/enums/PopupWindowType"
import { Dropdown } from "hooks/DropDown"
import { store } from "index"
import { useState, useRef, useContext } from "react"
import { useNavigate, useLocation } from "react-router-dom"

import { updateActivePopupType } from "store/general/actionCreators"
import { ThemeToggle } from "views/Common/ThemeToggle/ThemeToggle"
export const TopNavigation = (props: any) => {
  const actionsDropdownButton = useRef(null)
  // const dispatch = useDispatch();
  const [actionsDropdownOpen, setActionsDropdownOpen] = useState(false)

  const handleActionsDropdownButtonClick = () => {
    actionsDropdownButton.current.classList.toggle("dropdown-open")

    console.log(actionsDropdownOpen)
    if (actionsDropdownOpen === true) {
      setActionsDropdownOpen(false)
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur()
      }
    } else setActionsDropdownOpen(true)
  }

  // const onUserDropdownSimClick = () => {
  //   console.log("bookmaker");
  //   actionsDropdownButton.current.classList.remove("dropdown-open");

  //   setActionsDropdownOpen(false);
  //   if (document.activeElement instanceof HTMLElement) {
  //     document.activeElement.blur();

  //     userDropdownButton.current.classList.add("dropdown-open");
  //     setUserDropdownOpen(true);
  //   }
  // };

  const userDropdownButton = useRef(null)
  const [userDropdownOpen, setUserDropdownOpen] = useState(false)

  const handleUserDropdownButtonClick = () => {
    userDropdownButton.current.classList.toggle("dropdown-open")

    console.log(userDropdownOpen)
    if (userDropdownOpen === true) {
      setUserDropdownOpen(false)
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur()
      }
    } else setUserDropdownOpen(true)
  }

  const navigate = useNavigate()
  const authContext = useContext(AuthContext)

  function handleSignOut() {
    authContext.signOut()
    navigate("/")
  }

  console.log(console.log(window.location.pathname))

  const actionsDropdown = () => {
    return (
      <Dropdown
        show={actionsDropdownOpen}
        message={
          <div
            className="dropdown dropdown-start "
            id="action-dropdown"
            ref={actionsDropdownButton}
          >
            <label
              tabIndex={0}
              className=" cursor-pointer text-current fill-current rounded-none bg-accent-content hover:bg-accent text-primary-content opacity-70 hover:opacity-100 h-full w-full disabled:bg-accent-content"
            >
              <div className="flex flex-row gap-2 items-center justify-between">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-gear-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z" />
                </svg>
                <div className="whitespace-nowrap">Actions</div>
              </div>
            </label>
            <ul
              tabIndex={0}
              className="dropdown-content bg-secondary menu p-2 drop-shadow-3xl rounded-box w-52"
            >
              <li
                className="text-sm"
                onClick={() => {
                  store.dispatch(updateActivePopupType(PopupWindowType.UPDATE_LABEL))
                }}
              >
                <p className="opacity-60 text-primary-content hover:opacity-100 py-2">
                  Edit Labels
                </p>
              </li>
              <li
                className="text-sm"
                onClick={() => {
                  store.dispatch(updateActivePopupType(PopupWindowType.IMPORT_IMAGES))
                }}
              >
                <p className="opacity-60 text-primary-content hover:opacity-100 py-2">
                  Import Images{" "}
                </p>
              </li>
              <li
                className="text-sm"
                onClick={() => {
                  store.dispatch(updateActivePopupType(PopupWindowType.CHANGE_IMAGE_STATUS))
                }}
              >
                <text className="hover:text-white py-2">Change Image Status </text>
              </li>
              <li
                className="text-sm"
                onClick={() => {
                  store.dispatch(updateActivePopupType(PopupWindowType.IMPORT_ANNOTATIONS))
                }}
              >
                <p className="opacity-60 text-primary-content hover:opacity-100 py-2">
                  Import Annotations{" "}
                </p>
              </li>
              <li
                className="text-sm"
                onClick={() => {
                  store.dispatch(updateActivePopupType(PopupWindowType.EXPORT_ANNOTATIONS))
                }}
              >
                <p className="opacity-60 text-primary-content hover:opacity-100 py-2">
                  Export Annotations{" "}
                </p>
              </li>
              {/* <li
                className="text-sm"
                onClick={() => {
                  store.dispatch(updateActivePopupType(PopupWindowType.LOAD_AI_MODEL))
                }}
              >
                <p className="opacity-60 text-primary-content hover:opacity-100 py-2">
                  Load AI Model{" "}
                </p>
              </li> */}
              <li
                className="text-sm"
                onClick={() => {
                  store.dispatch(updateActivePopupType(PopupWindowType.MANAGE_PROJECT_MEMBERS))
                }}
              >
                <p className="opacity-60 text-primary-content hover:opacity-100 py-2">
                  Manage Members{" "}
                </p>
              </li>
            </ul>
          </div>
        }
        onClickOutside={() => {
          handleActionsDropdownButtonClick()
        }}
      />
    )
  }

  const userDropdown = () => {
    return (
      <Dropdown
        show={userDropdownOpen}
        message={
          <div className="dropdown dropdown-start " id="user-dropdown" ref={userDropdownButton}>
            <label
              tabIndex={0}
              className=" cursor-pointer text-current fill-current rounded-none bg-accent-content hover:bg-accent hover:fill-white hover:text-white h-full w-full disabled:bg-accent-content"
            >
              <div className="flex flex-row gap-2 items-center justify-between">
                <div className="whitespace-nowrap">{authContext.getUserInfo().data.name}</div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  // fill="white"
                  className="bi bi-person-circle"
                  viewBox="0 0 16 16"
                >
                  <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                  <path
                    fillRule="evenodd"
                    d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                  />
                </svg>
              </div>
            </label>
            <ul
              tabIndex={0}
              className="dropdown-content menu p-2 drop-shadow-3xl bg-base-100 rounded-box w-52"
            >
              <li
                onClick={() => {
                  handleSignOut()
                }}
              >
                <div className="flex gap-2 hover:text-white hover:fill-white">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-box-arrow-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z"
                    />
                    <path
                      fillRule="evenodd"
                      d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"
                    />
                  </svg>
                  <p>Sign out</p>
                </div>
              </li>
            </ul>
          </div>
        }
        onClickOutside={() => {
          handleUserDropdownButtonClick()
        }}
        type={"user"}
      />
    )
  }

  const location = useLocation()

  return (
    <>
      <div className="w-full top-0 pt-4 bg-base-100 z-20 fixed">
        <div className="my-4 rounded-full bg-base-200 mx-10 flex justify-between">
          <div className="px-12 py-4 gap-4 flex items-center">
            <div
              onClick={() => {
                // store.dispatch(updateActivePopupType(PopupWindowType.EXIT_PROJECT))
                if (location.pathname.includes("/projects")) {
                  if (window.confirm("Are you sure you want to exit the project?")) {
                    navigate("/")
                  }
                } else {
                  navigate("/")
                }
              }}
            >
              <img
                className="my-auto"
                width={"94px"}
                height={"20px"}
                draggable={false}
                alt={"logo"}
                src={
                  window.location.hostname.includes("upjao.ai") ? "/upjao-logo.png" : "/easyML.png"
                }
              />
            </div>
            {window.location.pathname.includes("projects") && (
              <div className="px-2 border-x-2 border-primary-content border-opacity-50 hover:border-opacity-100">
                {actionsDropdown()}
              </div>
            )}
          </div>
          <div className="px-12 py-4 gap-4 flex">
            {/* <div
              className="flex items-center gap-2 text-primary-content opacity-70 hover:opacity-100 hover:fill-white cursor-pointer"
              onClick={() => navigate("/upgrade-plan")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-gem"
                viewBox="0 0 16 16"
              >
                <path d="M3.1.7a.5.5 0 0 1 .4-.2h9a.5.5 0 0 1 .4.2l2.976 3.974c.149.185.156.45.01.644L8.4 15.3a.5.5 0 0 1-.8 0L.1 5.3a.5.5 0 0 1 0-.6l3-4zm11.386 3.785-1.806-2.41-.776 2.413 2.582-.003zm-3.633.004.961-2.989H4.186l.963 2.995 5.704-.006zM5.47 5.495 8 13.366l2.532-7.876-5.062.005zm-1.371-.999-.78-2.422-1.818 2.425 2.598-.003zM1.499 5.5l5.113 6.817-2.192-6.82L1.5 5.5zm7.889 6.817 5.123-6.83-2.928.002-2.195 6.828z" />
              </svg>
              <p>Upgrade Plan</p>
            </div> */}
            <div
              className="flex items-center gap-2 text-primary-content opacity-70 hover:opacity-100 hover:fill-white cursor-pointer"
              onClick={() => store.dispatch(updateActivePopupType(PopupWindowType.FEEDBACK))}
            >
              <i className="bi bi-pencil-square"></i>
              <p>Feedback</p>
            </div>
            <ThemeToggle />
            <div
              onClick={() => {
                handleSignOut()
              }}
              className="flex items-center gap-2 text-primary-content opacity-70 hover:opacity-100 hover:fill-white cursor-pointer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-box-arrow-right"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z"
                />
                <path
                  fillRule="evenodd"
                  d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"
                />
              </svg>
              <p>Sign out</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
