import React, { MutableRefObject } from "react"
import { PopupWindowType } from "../../data/enums/PopupWindowType"
import { AppState } from "../../store"
import { connect } from "react-redux"
import EditorContainer from "./EditorContainer/EditorContainer"

interface IProps {
  activePopupType: PopupWindowType
  loadingBarRef: MutableRefObject<any>
}

const EditorView: React.FC<IProps> = ({ activePopupType, loadingBarRef }) => {
  return (
    <>
      <EditorContainer />
    </>
  )
}

const mapStateToProps = (state: AppState) => ({
  activePopupType: state.general.activePopupType,
})

export default connect(mapStateToProps)(EditorView)
