/* This code snippet defines a React functional component called `QuickActions`. It handles context
menu interactions and provides quick actions for annotations in an application.*/

import { LabelType } from "data/enums/LabelType"
import { LabelActions } from "logic/actions/LabelActions"
import { useState, useCallback, useEffect } from "react"
import { updateLineLabel } from "../SideNavigationBar/LineLabelsList/LineLabelsList"
import { updatePointLabel } from "../SideNavigationBar/PointLabelsList/PointLabelsList"
import { updatePolygonLabel } from "../SideNavigationBar/PolygonLabelsList/PolygonLabelsList"
import { updateRectLabel } from "../SideNavigationBar/RectLabelsList/RectLabelsList"
import { LabelsSelector } from "store/selectors/LabelsSelector"
import { PopupWindowType } from "data/enums/PopupWindowType"

interface IProps {
  showMenu: boolean
  updateActiveLabelId: (highlightedLabelId: string) => any
  updateHighlightedLabelId: (highlightedLabelId: string) => any
  updateActivePopupType: (activePopupType: PopupWindowType) => any
}

const QuickActions: React.FC<IProps> = (props) => {
  const { showMenu, updateHighlightedLabelId, updateActiveLabelId, updateActivePopupType } = props
  const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 })
  const [show, setShow] = useState(false)

  // Consolidate context menu handlers into a single effect
  useEffect(() => {
    const handleContextMenu = (event: { preventDefault: () => void; pageX: any; pageY: any }) => {
      event.preventDefault()
      setAnchorPoint({ x: event.pageX, y: event.pageY })
      setShow(true)
    }

    const handleClick = () => {
      if (show) {
        setShow(false)
      }
    }

    if (showMenu) {
      document.addEventListener("contextmenu", handleContextMenu)
      document.addEventListener("click", handleClick)
      document.addEventListener("contextmenu", handleClick)
    }

    return () => {
      document.removeEventListener("contextmenu", handleContextMenu)
      document.removeEventListener("click", handleClick)
      document.removeEventListener("contextmenu", handleClick)
    }
  }, [show, showMenu]) // Minimize dependencies to only necessary states

  const deleteAnnotationFromMenu = useCallback(() => {
    const activeLabelId = LabelsSelector.getActiveLabelId()
    const activeImageId = LabelsSelector.getActiveImageData().id
    LabelActions.deleteImageLabelById(activeImageId, activeLabelId)
  }, [])

  const listLabelNames = useCallback(() => {
    const activeLabelId = LabelsSelector.getActiveLabelId()
    const labelNames = LabelsSelector.getLabelNames()

    return labelNames.map((option) => (
      <li key={option.id}>
        <div
          className="opacity-50 text-primary-content hover:opacity-100"
          onClick={(event) => {
            updateLabel(activeLabelId, option.id)
            setShow(false)
            event.stopPropagation()
          }}
        >
          {option.name}
        </div>
      </li>
    ))
  }, [])

  const updateLabel = (activeLabelId: string, labelId: string) => {
    const activeLabelType = LabelsSelector.getActiveLabelType()

    switch (activeLabelType) {
      case LabelType.RECT:
        updateRectLabel(activeLabelId, labelId)
        break
      case LabelType.POINT:
        updatePointLabel(activeLabelId, labelId)
        break
      case LabelType.LINE:
        updateLineLabel(activeLabelId, labelId)
        break
      case LabelType.POLYGON:
        updatePolygonLabel(activeLabelId, labelId)
        break
    }

    updateHighlightedLabelId(null)
    updateActiveLabelId(activeLabelId)
  }

  return (
    <>
      {show ? (
        <ul
          className="menu bg-secondary menu-compact w-fit rounded-box z-10 absolute"
          style={{
            top: anchorPoint.y,
            left: anchorPoint.x,
          }}
        >
          <li>
            <div
              className="flex items-center space-x-2 cursor-pointer hover:opacity-100 opacity-50"
              onClick={deleteAnnotationFromMenu}
            >
              <i className="bi bi-trash-fill text-primary-content w-4 h-4"></i>
              <span>Delete</span>
            </div>
          </li>
          <li>
            <div
              className="flex items-center space-x-2 cursor-pointer hover:opacity-100 opacity-50"
              onClick={() => updateActivePopupType(PopupWindowType.UPDATE_LABEL)}
            >
              <i className="bi bi-pencil text-primary-content w-4 h-4"></i>
              <span>Edit Labels</span>
            </div>
          </li>
          <li tabIndex={0} className="cursor-pointer">
            <div className="flex items-center space-x-2 hover:opacity-100 opacity-50">
              <i className="bi bi-gear-fill text-primary-content w-4 h-4"></i>
              <span>Change Label</span>
            </div>
            <ul className="rounded-box bg-secondary max-h-36 overflow-y-scroll scrollbar">
              {listLabelNames()}
            </ul>
          </li>
        </ul>
      ) : (
        <> </>
      )}
    </>
  )
}

export default QuickActions
