export enum CustomCursorStyle {
  DEFAULT = "DEFAULT",
  MOVE = "MOVE",
  RESIZE = "RESIZE",
  ADD = "ADD",
  CANCEL = "CANCEL",
  CLOSE = "CLOSE",
  GRAB = "GRAB",
  GRABBING = "GRABBING",
}
