import React, { useState } from "react"
import "./ImportLabelPopup.scss"
import { LabelType } from "../../../data/enums/LabelType"
import { PopupActions } from "../../../logic/actions/PopupActions"
import GenericLabelTypePopup from "../GenericLabelTypePopup/GenericLabelTypePopup"
import { AppState } from "../../../store"
import { connect } from "react-redux"
import { useDropzone } from "react-dropzone"
import { AcceptedFileType } from "../../../data/enums/AcceptedFileType"
import {
  updateActiveLabelType,
  updateImageDataById,
  updateImageStatus,
  updateLabelNames,
} from "../../../store/labels/actionCreators"
import { toast } from "react-toastify"
import {
  validateAnnotationSchema,
  findAnnotationType,
} from "../../../logic/import/AnnotationSchema"
import { uploadAnnotations } from "../../../apis/projects/index"
import { ImageData, LabelName } from "../../../store/labels/types"
import { downloadImageAnnotationsAPI } from "../../../apis/images"
import { getProjectInfo } from "../../../apis/projects"

interface IProps {
  activeLabelType: LabelType
  projectId: string
  updateActiveLabelTypeAction: (activeLabelType: LabelType) => any
  activeImageIndex: number
  imagesData: ImageData[]
  updateImageDataByIdAction: (id: string, image_data: ImageData) => any
  updateLabelNamesAction: (labels: LabelName[]) => any
  imageStatus: string
}

const ImportLabelPopup: React.FC<IProps> = ({
  activeLabelType,
  projectId,
  imagesData,
  activeImageIndex,
  updateImageDataByIdAction,
  updateLabelNamesAction,
  imageStatus,
}) => {
  // const resolveFormatType = (labelType: LabelType): AnnotationFormatType => {
  //     const possibleImportFormats = ImportFormatData[labelType]
  //     return possibleImportFormats.length === 1 ? possibleImportFormats[0].type : null
  // }

  const [labelType, setLabelType] = useState(activeLabelType)
  // const [formatType, setFormatType] = useState(resolveFormatType(activeLabelType));
  const [loadedImageData, setLoadedImageData] = useState([])
  const [fileName, setFileName] = useState("")
  const [annotationsLoadedError, setAnnotationsLoadedError] = useState(null)
  const [imageNotFound, setImageNotFound] = useState([])
  const [fileFormat, setFileFormat] = useState("")
  const { getRootProps, getInputProps } = useDropzone({
    accept: [AcceptedFileType.JSON],
    onDrop: (acceptedFiles) => {
      setAnnotationsLoadedError(null)
      console.log(acceptedFiles, labelType)
      console.log("File content:", acceptedFiles[0])
      const fileReader = new FileReader()
      try {
        fileReader.readAsText(acceptedFiles[0], "UTF-8")
      } catch (error) {
        toast.error("Invalid File Uploaded", {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
      fileReader.onload = (e) => {
        var data = JSON.parse(e.target.result as string)
        console.log("Parsed Data:", data)
        var format = null
        format = findAnnotationType(data, labelType)
        console.log("Annotation Format:", format)
        if (format === "NONE") {
          toast.error("Invalid JSON Uploaded", {
            position: toast.POSITION.TOP_RIGHT,
          })
          setAnnotationsLoadedError({ message: "Invalid JSON Uploaded" })
          return
        }
        setFileFormat(format)
        console.log(
          "Before validateAnnotationSchema. Data:",
          data,
          "Label:",
          labelType,
          "Format:",
          format
        )
        if (!validateAnnotationSchema(data, labelType, format)) {
          toast.error("Invalid JSON Uploaded", {
            position: toast.POSITION.TOP_RIGHT,
          })
          setAnnotationsLoadedError({ message: "Invalid JSON Uploaded" })
        } else {
          console.log("Schema validation successful.")
          setFileName(acceptedFiles[0].name)
          setLoadedImageData(data)
          toast.success("JSON File Loaded Successfully", {
            position: toast.POSITION.TOP_RIGHT,
          })
        }
      }
    },
  })

  const onLabelTypeChange = (labelType: LabelType) => {
    setImageNotFound([])
    setLabelType(labelType)
    setLoadedImageData([])
    setAnnotationsLoadedError(null)
  }

  const updateAnnotationAndLabels = async () => {
    const accessToken: string = localStorage.getItem("accessToken")
    var image_data = imagesData[activeImageIndex]
    const downloadInfo = await downloadImageAnnotationsAPI(image_data["imageId"], accessToken)
    if (downloadInfo.statusCode === 200) {
      if (downloadInfo.data.data) {
        const { labelPoints, labelRects, labelLines, labelPolygons, labelNameIds } =
          downloadInfo.data.data.labels
        if (labelPoints !== null && labelPoints !== undefined) image_data.labelPoints = labelPoints
        if (labelLines !== null && labelLines !== undefined) image_data.labelLines = labelLines
        if (labelRects !== null && labelRects !== undefined) image_data.labelRects = labelRects
        if (labelPolygons !== null && labelPolygons !== undefined)
          image_data.labelPolygons = labelPolygons
        if (labelNameIds !== null && labelNameIds !== undefined)
          image_data.labelNameIds = labelNameIds
      }
      updateImageDataByIdAction(image_data["imageId"], image_data)

      var projectInfo = await getProjectInfo(projectId, accessToken, imageStatus)
      console.log(projectInfo.data.data.labels, projectInfo)
      updateLabelNamesAction(projectInfo.data.data.labels)
      toast.success("Annotation Successfully Imported", {
        position: toast.POSITION.TOP_RIGHT,
      })
    }
  }

  const onAccept = async (labelType: LabelType) => {
    if (imageNotFound.length !== 0) {
      updateAnnotationAndLabels()
      PopupActions.close()
    }
    if (loadedImageData.length !== 0) {
      const accessToken: string = localStorage.getItem("accessToken")
      var uploadResult = await uploadAnnotations(
        projectId,
        loadedImageData,
        labelType,
        accessToken,
        fileFormat
      )
      if (uploadResult.data.imageNotFound.length) {
        setImageNotFound(uploadResult.data.imageNotFound)
      } else {
        toast.success("Annotation Successfully Imported", {
          position: toast.POSITION.TOP_RIGHT,
        })
        window.location.reload()
        PopupActions.close()
      }
    }
  }

  const onReject = (labelType: LabelType) => {
    PopupActions.close()
  }

  const getDropZoneContent = () => {
    if (!!annotationsLoadedError) {
      return (
        <>
          <input {...getInputProps()} />
          <div className="flex flex-col items-center justify-center stroke-base-content w-full h-full">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="49.641"
              height="49.64"
              x="0"
              y="0"
              enableBackground="new 0 0 49.641 49.64"
              version="1.1"
              viewBox="0 0 49.641 49.64"
              xmlSpace="preserve"
            >
              <path d="M44.118 11.852c-.008-.014-.022-.021-.03-.034a.631.631 0 00-.109-.122c-.014-.01-.023-.021-.036-.03a.599.599 0 00-.225-.11l-20.65-4.835a.663.663 0 00-.336.015L8.002 11.57c-.042.015-.075.043-.112.065-.024.014-.053.017-.074.033-.006.005-.009.014-.015.019-.006.006-.016.007-.022.014l-7.573 6.81a.627.627 0 00.637 1.052l6.73-2.508v20.163a.62.62 0 00.471.604l19.957 5.092a.657.657 0 00.36-.011l14.935-5.094a.622.622 0 00.424-.591V24.155l5.5-1.904a.625.625 0 00.338-.901l-5.44-9.498zM7.571 15.718l-4.086 1.524 4.086-3.677v2.153zm19.961 25.787l-18.71-4.773V12.978l18.71 5.012v23.515zm.604-24.649l-17.749-4.754 12.568-4.124 18.377 4.302-13.196 4.576zM42.468 36.77l-13.686 4.666V18.815l5.607 8.089a.631.631 0 00.72.236l7.359-2.55v12.18zM35.147 25.8l-5.619-8.104 13.763-4.772 4.805 8.392L35.147 25.8z"></path>
            </svg>
            <p className="mx-auto text-sm px-2 text-center py-2">
              {`Annotation import was unsuccessful        
                    ${annotationsLoadedError?.message}
                    
                    Try again`}
            </p>
          </div>
        </>
      )
    }
    // else if (test) {
    //     return <>
    //         <div className='flex flex-col items-center justify-center stroke-base-content w-full h-full'>
    //             <div className='h-[49.64px] w-[49.64px]'>
    //                 <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" className="bi bi-box" viewBox="0 0 16 16">
    //                     <path d="M8.186 1.113a.5.5 0 0 0-.372 0L1.846 3.5 8 5.961 14.154 3.5 8.186 1.113zM15 4.239l-6.5 2.6v7.922l6.5-2.6V4.24zM7.5 14.762V6.838L1 4.239v7.923l6.5 2.6zM7.443.184a1.5 1.5 0 0 1 1.114 0l7.129 2.852A.5.5 0 0 1 16 3.5v8.662a1 1 0 0 1-.629.928l-7.185 2.874a.5.5 0 0 1-.372 0L.63 13.09a1 1 0 0 1-.63-.928V3.5a.5.5 0 0 1 .314-.464L7.443.184z" />
    //                 </svg>
    //             </div>
    //             <p className='mx-auto text-sm px-2 text-center py-4'>
    //                 {
    //                     `Annotation ready for import
    //                     After import you will lose
    //                     all your current annotations`
    //                 }
    //             </p>
    //         </div>
    //     </>;
    // }
    else if (imageNotFound.length) {
      return (
        <>
          <div className=" group-hover:text-white text-center pt-4">
            <p className="extraBold">{`The Following Images were not found in the project`}</p>
            {[...imageNotFound].map((x, i) => (
              <p key={i}>{x}</p>
            ))}
            <p>Please upload the images with the correct names to upload their annotation.</p>
          </div>
        </>
      )
    } else if (loadedImageData.length !== 0) {
      return (
        <>
          <input {...getInputProps()} />
          <div className="flex flex-col items-center justify-center stroke-base-content w-full h-full">
            <div className="h-[49.64px] w-[49.64px]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="currentColor"
                className="bi bi-box"
                viewBox="0 0 16 16"
              >
                <path d="M8.186 1.113a.5.5 0 0 0-.372 0L1.846 3.5 8 5.961 14.154 3.5 8.186 1.113zM15 4.239l-6.5 2.6v7.922l6.5-2.6V4.24zM7.5 14.762V6.838L1 4.239v7.923l6.5 2.6zM7.443.184a1.5 1.5 0 0 1 1.114 0l7.129 2.852A.5.5 0 0 1 16 3.5v8.662a1 1 0 0 1-.629.928l-7.185 2.874a.5.5 0 0 1-.372 0L.63 13.09a1 1 0 0 1-.63-.928V3.5a.5.5 0 0 1 .314-.464L7.443.184z" />
              </svg>
            </div>
            <p className="mx-auto text-sm px-2 text-center py-4 break-words w-full">
              {`Annotation ready for import
                        After import you will lose
                        all your current annotations`}
              <br />
              {`Importing from File: ${fileName}`}
            </p>
          </div>
        </>
      )
    } else {
      return (
        <>
          <input {...getInputProps()} />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            className="bi bi-cloud-upload fill-secondary-content"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z"
            />
            <path
              fillRule="evenodd"
              d="M7.646 4.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V14.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3z"
            />
          </svg>
          <div className=" text-secondary-content text-center pt-4">
            <p className="extraBold">{`Drop a json file`}</p>
            <p>or</p>
            <p className="extraBold">Click here to select them</p>
          </div>
          {/* <img
                    draggable={false}
                    alt={'upload'}
                    src={'/ico/box-opened.png'}
                />
                <p className='extraBold'>{`Drop ${formatType} annotations`}</p>
                <p>or</p>
                <p className='extraBold'>Click here to select them</p> */}
        </>
      )
    }
  }

  // const getOptions = (ExportFormatData: ILabelFormatData[]) => {
  //     return ExportFormatData?.map((entry: ILabelFormatData) => {
  //         return <div>
  //             <div className="form-control items-start">
  //                 <label className="label cursor-pointer">
  //                     <input onClick={() => onSelect(entry.type)} key={entry.type} checked={entry.type === importFormatType}
  //                         type="radio" name="radio-10" className="mr-2 radio border-current checked:bg-white" />
  //                     <span className="label-text">{entry.label}</span>
  //                 </label>
  //             </div>
  //         </div>
  //     })

  // };

  const renderInternalContent = (labelType: LabelType) => {
    if (["POINT", "RECT", "LINE", "POLYGON", "IMAGE RECOGNITION"].includes(labelType)) {
      return [
        <div className="py-8">
          <div
            {...getRootProps({
              className:
                "DropZone border-2 border-dashed border-current border-secondary-content opacity-60 hover:opacity-100 py-6 rounded-xl group flex flex-col items-center",
            })}
          >
            {getDropZoneContent()}
          </div>
        </div>,
      ]
    } else {
      return [
        <div className="my-8 h-48">
          <div className="text-center">
            Select label type and the file format you would like to use to export labels.
          </div>
        </div>,
      ]
    }
  }

  return (
    <GenericLabelTypePopup
      activeLabelType={labelType}
      title={!!labelType ? `Import ${labelType?.toLowerCase()} annotations` : "No Label Type Set"}
      onLabelTypeChange={onLabelTypeChange}
      acceptLabel={imageNotFound.length ? "Ok" : "Import"}
      onAccept={onAccept}
      disableAcceptButton={loadedImageData.length === 0 || !!annotationsLoadedError}
      rejectLabel={"Cancel"}
      onReject={onReject}
      skipRejectButton={true}
      renderInternalContent={renderInternalContent}
    />
  )
}

const mapDispatchToProps = {
  updateActiveLabelTypeAction: updateActiveLabelType,
  updateImageDataByIdAction: updateImageDataById,
  updateLabelNamesAction: updateLabelNames,
}

const mapStateToProps = (state: AppState) => ({
  activeLabelType: state.labels.activeLabelType,
  projectId: state.general.projectData.id,
  activeImageIndex: state.labels.activeImageIndex,
  imagesData: state.labels.imagesData,
  imageStatus: state.labels.imageStatus,
})

export default connect(mapStateToProps, mapDispatchToProps)(ImportLabelPopup)
