import { connect } from "react-redux"
import { GenericYesNoPopup } from "../GenericYesNoPopup/GenericYesNoPopup"
import { AppState } from "../../../store"
import { useState } from "react"
import { changeImageStatus } from "apis/images"
import {
  updateActiveImageIndex,
  updateImageData,
  updateImageDataById,
  updateSelectedImages,
} from "store/labels/actionCreators"
import { toast } from "react-toastify"
import { PopupActions } from "logic/actions/PopupActions"
import { useLocation } from "react-router-dom"
import { ImageStatus } from "../../../data/enums/ImageStatus"
import { PopupWindowType } from "../../../data/enums/PopupWindowType"
import {
  updateActivePopupType,
  // updateProjectData,
  // updateUserData,
} from "store/general/actionCreators"
import { updateActiveImageIndexThunk } from "store/thunks/labelsThunks"

interface IProps {
  updateActivePopupTypeAction: (activePopupType: PopupWindowType) => any
  updateActiveImageIndexAction: (activeImageIndex: number) => any
  updateActiveImageIndexThunkAction: (activeImageIndex: number) => any
}

const ChangeImageStatusPopUp = (props) => {
  const { selectedImages, imageStatus, userRole, imageData } = props
  console.log(imageData)
  const [selectImageStatus, setSelectImageStatus] = useState("Select the Image Status")

  const handleChange = (e) => {
    const selectedImageStatus = e.target.value
    console.log(selectedImageStatus)
    setSelectImageStatus(selectedImageStatus)
  }
  const location = useLocation()

  function renderContent() {
    const imageStatusOptions = {
      annotator: [ImageStatus.REVIEW],
      reviewer: [ImageStatus.RE_EVALUATE, ImageStatus.DONE],
      admin: [ImageStatus.REVIEW, ImageStatus.RE_EVALUATE, ImageStatus.DONE],
    }

    const options = imageStatusOptions[userRole] || []

    console.log("imageData:", imageData)
    imageData.forEach((image) => {
      console.log("Label data for imageId", image.imageId)
      console.log("labelLines:", image.labelLines)
      console.log("labelPoints:", image.labelPoints)
      console.log("labelRects:", image.labelRects)
      console.log("labelPolygons:", image.labelPolygons)
    })

    return (
      <>
        <div className="pb-4">
          <ul className="flex flex-col gap-2 w-full h-56 overflow-y-scroll scrollbar-invisible hover:scrollbar">
            <div className="m-auto">
              {selectedImages.length} selected with status "{imageStatus}"
              {(imageStatus === ImageStatus.UN_ANNOTATED || imageStatus === ImageStatus.ALL) && (
                <p className="text-red-500">
                  Image status change isn't allowed from unannotated/all sections.
                </p>
              )}
              <select className="select w-full max-w-xs" onChange={handleChange}>
                <option disabled selected>
                  Select the Image Status
                </option>
                {imageStatus !== ImageStatus.UN_ANNOTATED &&
                  imageStatus !== ImageStatus.ALL &&
                  options
                    .filter((status) => status !== imageStatus)
                    .map((status, i) => (
                      <option
                        disabled={status === imageStatus}
                        value={status.toLowerCase()}
                        key={i}
                      >
                        {status}
                      </option>
                    ))}
              </select>
            </div>
          </ul>
        </div>
      </>
    )
  }

  const onClick = async () => {
    const accessToken: string = localStorage.getItem("accessToken")
    const projectId = location.pathname.split("/")[2]
    console.log("hi")
    props.updateActivePopupTypeAction(PopupWindowType.LOADER)
    const result = await changeImageStatus(
      selectedImages,
      selectImageStatus,
      projectId,
      accessToken
    )
    console.log(result)
    if (result.statusCode === 200) {
      let updatedImageData = [...imageData]
      selectedImages.forEach((selectedImageId) => {
        const imageIndex = updatedImageData.findIndex((image) => image.imageId === selectedImageId)
        if (imageIndex !== -1) {
          updatedImageData[imageIndex] = {
            ...updatedImageData[imageIndex],
            imageStatus: selectImageStatus,
          }
        }
      })
      updatedImageData = updatedImageData.filter((image) => !selectedImages.includes(image.imageId))
      if (updatedImageData[0]) {
        // downloadAnnotations(updatedImageData[0], props.updateImageDataById, true, () => null)
        const imageIndex = imageData.findIndex(
          (image) => image.imageId === updatedImageData[0].imageId
        )
        updatedImageData[0] = imageData[imageIndex]
      }
      console.log("updatedImageData", updatedImageData)
      await props.updateActiveImageIndexThunkAction(null)
      props.updateImageData(updatedImageData)
      props.updateSelectedImages([])
      toast.success("Changed the Image Status Successfully!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      })
    } else {
      toast.error("Failed Updating the Image Status!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      })
    }

    const checkboxElement: any = document.querySelectorAll("[id=imageCheckBox]")
    for (const checkbox of checkboxElement) {
      checkbox.checked = false
    }

    PopupActions.close()
  }

  return (
    <>
      <GenericYesNoPopup
        title={"Change Image Status"}
        renderContent={renderContent}
        acceptLabel={"Submit"}
        onAccept={onClick}
        skipRejectButton
        disableAcceptButton={
          selectImageStatus === "Select the Image Status" || selectedImages.length <= 0
        }
      />
    </>
  )
}

const mapDispatchToProps = {
  updateImageData,
  updateSelectedImages,
  updateImageDataById,
  updateActivePopupTypeAction: updateActivePopupType,
  updateActiveImageIndexAction: updateActiveImageIndex,
  updateActiveImageIndexThunkAction: updateActiveImageIndexThunk,
}

const mapStateToProps = (state: AppState) => ({
  selectedImages: state.labels.selectedImages,
  imageStatus: state.labels.imageStatus,
  userRole: state.general.userRole,
  imageData: state.labels.imagesData || [],
})

export default connect(mapStateToProps, mapDispatchToProps)(ChangeImageStatusPopUp)
