import React, { useState, useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { deleteInvitation, getInvitation } from "../../apis/invitations"
import { toast } from "react-toastify"
import "./ConfirmInvitationView.scss"

const ConfirmInvitationView: React.FC<{ loadingBarRef: React.MutableRefObject<any> }> = ({
  loadingBarRef,
}) => {
  const [userData, setUserData] = useState<any>()
  const [error, setError] = useState("")
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const navigator = useNavigate()

  useEffect(() => {
    function loadDashboard() {
      const USER = JSON.parse(localStorage.getItem("userInfo"))
      console.log({ USER })
      if (USER) {
        console.log("USER:", USER)
        setUserData(USER.data)
      } else {
        console.log("USER NOT FOUND:", USER)
        navigator("/signin", { state: window.location.search })
        // setTimeout(loadDashboard, 1000);
      }
    }
    loadDashboard()
  }, [])

  useEffect(() => {
    const checkExpiryDateOfUrl = () => {
      const now = new Date()

      const then: any = query.get("createdAt")

      const msBetweenDates = Math.abs(then - now.getTime())

      // 👇️ convert ms to hours                  min  sec   ms
      const hoursBetweenDates = msBetweenDates / (60 * 60 * 1000)

      console.log({ hoursBetweenDates })

      if (hoursBetweenDates < 24) {
        console.log("date is within 24 hours")
      } else {
        console.log("date is NOT within 24 hours")
        toast.error("Invitation has expired", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        })
        setError("InvitationURL expiry gone!")
      }
    }
    checkExpiryDateOfUrl()
  }, [])

  useEffect(() => {
    console.log({ error: !!error, errormsg: error })
    if (!error) {
      // !!error = It returns true for all string values other than the empty string (including strings like "0" and " ")
      const invitationId: any = query.get("key")
      const accessToken: string = localStorage.getItem("accessToken")
      const checkInvitationId = async () => {
        const res = await getInvitation(invitationId, accessToken)
        console.log({ INVITATION_CHECK: res })
        if (res.statusCode === 200) {
          const invitedMailId = res?.data?.data?.invitedMailId
          const adminMailId = res?.data?.data?.adminMailId
          const userRole = res?.data?.data?.userRole
          const projectId = res?.data?.data?.projectId + "_" + res?.data?.data?.projectName
          console.log({ projectId_FROM_ACCEPT_INVITATION: projectId })
          // const adminMailId = res?.data?.data?.adminMailId;
          console.log({ userData, tyoe_of_userData: typeof userData })
          let userMailId
          if (typeof userData == "object") {
            userMailId = userData.email
          } else {
            userMailId = query.get("email")
          }
          console.log("invitedMailId", invitedMailId, userMailId)
          if (invitedMailId.toLowerCase() === userMailId.toLowerCase()) {
            console.log("MAIL is VALID, redirect to DASHBOARD")
            await deleteInvitation(invitationId, accessToken)
            navigator("/dashboard", {
              state: {
                adminMailId,
                userRole,
                mailPopup: true,
                projectId,
                contributorId: userMailId,
              },
            })
          } else {
            if (userData !== undefined) {
              console.log("Mail is invalid")
              setError("Mail is invalid")
              toast.error("Invalid mail!", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
              })
            }
            navigator("/dashboard")
          }
        } else {
          if (accessToken) {
            console.error("The given invitation does not exist any more!")
            toast.error("This invitation does not exist any more!", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 8000,
            })
            navigator("/dashboard")
          } else {
            toast.error("Please Login to accept the invite", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 8000,
            })
          }
        }
      }
      checkInvitationId()
    }
  }, [userData])

  return <>{error && <h4>{error}</h4>}</>
}

export default ConfirmInvitationView
