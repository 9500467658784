const AddNewButtonSvg = () => {
  return (
    <svg className="mt-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 299 309">
      <g id="Component_32_1" data-name="Component 32 – 1" transform="translate(19)">
        <g id="Group_2" data-name="Group 2" transform="translate(-830 -303)" opacity="0.51">
          <rect
            id="add-project-shadow"
            data-name="Rectangle 18132"
            width="245"
            height="55"
            rx="20"
            transform="translate(838 530)"
            fill="none"
          />
          <g
            id="dotted_outline_rectangle_"
            data-name="dotted outline rectangle "
            transform="translate(830 303)"
            strokeLinecap="round"
            strokeWidth="5"
            strokeDasharray="12 14"
          >
            <rect width="262" height="262" rx="20" stroke="none" className=" fill-primary" />
            <rect
              id="rect-outline"
              x="2.5"
              y="2.5"
              width="257"
              height="257"
              rx="17.5"
              fill="none"
            />
          </g>
          <g id="plus_icon" data-name="plus icon" transform="translate(936.646 401.509)">
            <line
              id="Line_1"
              data-name="Line 1"
              y2="47"
              transform="translate(24.354 0.491)"
              strokeLinecap="round"
              strokeWidth="6"
            />
            <line
              id="Line_2"
              data-name="Line 2"
              x2="47"
              transform="translate(0.354 24.491)"
              strokeLinecap="round"
              strokeWidth="6"
            />
          </g>
          <text
            id="ADD_PROJECT"
            data-name="ADD PROJECT"
            transform="translate(960.5 503)"
            fill="primary-content"
            fontSize="20"
            fontFamily="Poppins-Medium, Poppins"
            fontWeight="500"
            letterSpacing="0.07em"
          >
            <tspan x="-75" y="0">
              ADD PROJECT
            </tspan>
          </text>
        </g>
      </g>
    </svg>
  )
}

export default AddNewButtonSvg
