import React, { useRef, useState, useEffect } from "react"
import classNames from "classnames"
import "./SideNavigationBar.scss"
import { Direction } from "../../../data/enums/Direction"
import { Dropdown } from "hooks/DropDown"
import { connect } from "react-redux"
import { AppState } from "store"
import {
  updateActiveLabelType,
  updateActiveLabelId,
  updateImageStatus,
  updateSelectedImages,
} from "store/labels/actionCreators"
import { LabelType } from "data/enums/LabelType"
import { ProjectType } from "data/enums/ProjectType"
import { GeneralSelector } from "store/selectors/GeneralSelector"
import { ImageData } from "store/labels/types"
import { ClipLoader } from "react-spinners"
import { ImageStatus } from "data/enums/ImageStatus"

interface IProps {
  activeLabelType: LabelType
  direction: Direction
  isOpen: boolean
  isWithContext?: boolean
  renderCompanion?: () => any
  renderContent?: () => any
  updateActiveLabelType: (activeLabelType: LabelType) => any
  updateActiveLabelId: (highlightedLabelId: string) => any
  updateImageStatus: (imageStatus: string) => any
  imageStatus: string
  projectType: ProjectType
  changeImageStatus?: (imageStatus: string) => any
  updateSelectedImages: (selectedImages: string[]) => any
  imageData: ImageData
  allImages: ImageData[]
  selectedImages: string[]
  imageDataLoading?: boolean
}

const SideNavigationBar: React.FC<IProps> = (props) => {
  const {
    direction,
    isOpen,
    isWithContext,
    renderContent,
    renderCompanion,
    activeLabelType,
    updateActiveLabelType,
    updateActiveLabelId,
    imageStatus,
    updateImageStatus,
    changeImageStatus,
    updateSelectedImages,
    projectType,
    imageData,
    imageDataLoading,
    selectedImages,
    allImages,
  } = props

  const [dropdownState, setDropdownState] = useState(
    direction === Direction.LEFT
      ? {
          value: ImageStatus.ALL,
        }
      : { value: "Label Type" }
  )

  const [hideSideNavBar, setHideSideNavBar] = useState(false)

  const dropdownButton = useRef(null)
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const handleDropdownButtonClick = () => {
    dropdownButton.current.classList.toggle("dropdown-open")

    // console.log(sortMenuOpen);
    if (dropdownOpen === true) {
      setDropdownOpen(false)
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur()
      }
    } else setDropdownOpen(true)
  }

  useEffect(() => {
    if (!!projectType && projectType === ProjectType.OBJECT_DETECTION) {
      props.updateActiveLabelType(null)
      props.updateActiveLabelId(null)
    } else if (
      !!projectType &&
      (projectType === ProjectType.IMAGE_RECOGNITION ||
        projectType === ProjectType.IMAGE_RECOGNITION_SINGLE_LABEL)
    ) {
      props.updateActiveLabelType(LabelType.IMAGE_RECOGNITION)
    }
  }, [])

  const labelDropdownHandler = (activeLabel) => {
    var tempLabel: LabelType = null
    if (activeLabel === "IMAGE RECOGNITION") tempLabel = LabelType.IMAGE_RECOGNITION
    else if (activeLabel === "POINT") tempLabel = LabelType.POINT
    else if (activeLabel === "RECT") tempLabel = LabelType.RECT
    else if (activeLabel === "POLYGON") tempLabel = LabelType.POLYGON
    else if (activeLabel === "LINE") tempLabel = LabelType.LINE
    // console.log("dsx", tempLabel);
    setDropdownState({ value: activeLabel })
    props.updateActiveLabelType(tempLabel)
    // console.log("dsx", props.activeLabelType);
    props.updateActiveLabelId(null)
  }

  useEffect(() => {
    setDropdownState({ value: activeLabelType ? activeLabelType : "Label Type" })
  }, [activeLabelType])

  const getLabelListMenu = () => {
    return (
      <>
        {!!projectType &&
          (projectType === ProjectType.IMAGE_RECOGNITION ||
            projectType === ProjectType.IMAGE_RECOGNITION_SINGLE_LABEL) && (
            <div className="pt-2">
              <div className="flex gap-4 h-full text-sm text-primary-content p-2 items-center bg-secondary hover:stroke-white">
                <p>IMAGE RECOGNITION</p>
              </div>
            </div>
          )}

        {!imageDataLoading ? (
          !!projectType &&
          projectType === ProjectType.OBJECT_DETECTION &&
          imageData && (
            <Dropdown
              show={dropdownOpen}
              message={
                <div
                  className="dropdown dropdown-start rounded-none p-0 pt-2 w-full"
                  ref={dropdownButton}
                >
                  <label
                    tabIndex={0}
                    className=" flex items-center bg-secondary rounded-none w-full border-2 border-secondary hover:cursor-pointer hover:border-accent text-primary-content text-left text-sm fill-white disabled:bg-accent-content"
                  >
                    <div className="flex flex-row items-center justify-between w-full py-1 pl-2">
                      <div className="whitespace-nowrap pr-2 normal-case">
                        {dropdownState.value}
                      </div>
                      <div className="px-2">
                        <i className="bi bi-caret-down-fill"></i>
                      </div>
                    </div>
                  </label>
                  <ul
                    tabIndex={0}
                    className="dropdown-content bg-secondary menu p-2 drop-shadow-3xl rounded-box w-52"
                  >
                    <>
                      <li
                        className="text-sm"
                        onClick={() => {
                          labelDropdownHandler("RECT")
                        }}
                      >
                        <div className="flex gap-4 h-full opacity-60 text-primary-content hover:opacity-100 py-2 hover:fill-white">
                          <i className="bi bi-square"></i>
                          <p>Rectangle</p>
                        </div>
                      </li>
                      <li
                        className="text-sm"
                        onClick={() => {
                          labelDropdownHandler("POINT")
                        }}
                      >
                        <div className="flex gap-4 h-full opacity-60 text-primary-content hover:opacity-100 py-2 hover:stroke-white">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="11"
                            height="11"
                            viewBox="0 0 11 11"
                          >
                            <g
                              id="Group_2562"
                              data-name="Group 2562"
                              transform="translate(-1573 -219)"
                            >
                              <g
                                id="Ellipse_153"
                                data-name="Ellipse 153"
                                transform="translate(1573 219)"
                                fill="#fff"
                                stroke="currentColor"
                                strokeWidth="1"
                              >
                                <circle cx="2" cy="2" r="2" stroke="none" />
                                <circle cx="2" cy="2" r="1.5" fill="none" />
                              </g>
                              <line
                                id="Line_464"
                                data-name="Line 464"
                                x2="9"
                                transform="translate(1575 221)"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="1"
                              />
                              <line
                                id="Line_465"
                                data-name="Line 465"
                                y1="9"
                                transform="translate(1575 221)"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="1"
                              />
                            </g>
                          </svg>
                          <p>Point</p>
                        </div>
                      </li>
                      <li
                        className="text-sm"
                        onClick={() => {
                          labelDropdownHandler("LINE")
                        }}
                      >
                        <div className="flex gap-4 h-full opacity-60 text-primary-content hover:opacity-100 py-2 hover:stroke-white">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="11"
                            height="11"
                            viewBox="0 0 10.768 12.64"
                          >
                            <line
                              id="Line_467"
                              data-name="Line 467"
                              y1="12"
                              x2="10"
                              transform="translate(0.384 0.32)"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="1"
                            />
                          </svg>
                          <p>Line</p>
                        </div>
                      </li>
                      <li
                        className="text-sm"
                        onClick={() => {
                          labelDropdownHandler("POLYGON")
                        }}
                      >
                        <div className="flex gap-4 h-full opacity-60 text-primary-content hover:opacity-100 py-2 hover:stroke-white">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="11"
                            height="11"
                            viewBox="0 0 16.57 15.959"
                          >
                            <path
                              id="Path_7899"
                              data-name="Path 7899"
                              d="M1570.5,218.67l5.963-5.967,9.585,2.849V227.6H1571.4Z"
                              transform="translate(-1569.979 -212.139)"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="1"
                            />
                          </svg>
                          <p>Polygon</p>
                        </div>
                      </li>
                    </>

                    {/* <li
                  className="text-sm"
                  onClick={() => {
                    setDropdownState({
                      // value: "Sort By",
                      value: "Done",
                    });
                  }}
                >
                  <p className="hover:text-white py-2">Done</p>
                </li> */}
                  </ul>
                </div>
              }
              onClickOutside={() => {
                handleDropdownButtonClick()
              }}
            />
          )
        ) : (
          <div className="flex justify-center items-center mx-auto pt-5">
            <ClipLoader size={30} color={"#14B58C"} loading={true} />
          </div>
        )}
      </>
    )
  }

  const getImageStateMenu = () => {
    return (
      <>
        <Dropdown
          show={dropdownOpen}
          message={
            <div
              className="dropdown dropdown-start rounded-none p-0 pt-2 w-full"
              ref={dropdownButton}
            >
              <label
                tabIndex={0}
                className=" flex items-center bg-secondary rounded-none w-full border-2 border-secondary hover:cursor-pointer hover:border-accent text-primary-content text-left text-sm fill-white disabled:bg-accent-content"
              >
                <div className="flex flex-row items-center justify-between py-1 pl-2 w-full">
                  <div className="whitespace-nowrap pr-2">{imageStatus}</div>
                  <div className="px-2">
                    <i className="bi bi-caret-down-fill"></i>
                  </div>
                </div>
              </label>
              <ul
                tabIndex={0}
                className="dropdown-content bg-secondary menu p-2 drop-shadow-3xl rounded-box w-52"
              >
                <li
                  className="text-sm"
                  onClick={() => {
                    changeImageStatus(ImageStatus.UN_ANNOTATED)
                    updateSelectedImages([])
                  }}
                >
                  <text className="opacity-60 text-primary-content hover:opacity-100 py-2">
                    {ImageStatus.UN_ANNOTATED}
                  </text>
                </li>
                <li
                  className="text-sm"
                  onClick={() => {
                    changeImageStatus(ImageStatus.ANNOTATED)
                    updateSelectedImages([])
                  }}
                >
                  <text className="hover:text-white py-2">{ImageStatus.ANNOTATED}</text>
                </li>
                <li
                  className="text-sm"
                  onClick={() => {
                    changeImageStatus(ImageStatus.REVIEW)
                    updateSelectedImages([])
                  }}
                >
                  <p className="opacity-60 text-primary-content hover:opacity-100 py-2">
                    {ImageStatus.REVIEW}
                  </p>
                </li>
                <li
                  className="text-sm"
                  onClick={() => {
                    changeImageStatus(ImageStatus.RE_EVALUATE)
                    updateSelectedImages([])
                  }}
                >
                  <p className="opacity-60 text-primary-content hover:opacity-100 py-2">
                    {ImageStatus.RE_EVALUATE}
                  </p>
                </li>
                <li
                  className="text-sm"
                  onClick={() => {
                    changeImageStatus(ImageStatus.DONE)
                    updateSelectedImages([])
                  }}
                >
                  <p className="opacity-60 text-primary-content hover:opacity-100 py-2">
                    {ImageStatus.DONE}
                  </p>
                </li>
                <li
                  className="text-sm"
                  onClick={() => {
                    changeImageStatus(ImageStatus.ALL)
                    updateSelectedImages([])
                  }}
                >
                  <text className="hover:text-white py-2">{ImageStatus.ALL}</text>
                </li>
              </ul>
            </div>
          }
          onClickOutside={() => {
            handleDropdownButtonClick()
          }}
        />
      </>
    )
  }

  const onSelectAllClickHandler = () => {
    const allImageIds = allImages.map((image) => image.imageId)
    if (selectedImages.length === allImages.length) {
      updateSelectedImages([])
    } else {
      updateSelectedImages(allImageIds)
    }
  }

  const isAllSelected = selectedImages.length === allImages.length

  return (
    <>
      {hideSideNavBar ? (
        <>
          <div
            className={`group cursor-pointer px-2 flex flex-col absolute bottom-0 ${
              direction === Direction.LEFT ? "left-0 pl-4" : "right-0 pr-4"
            }`}
            onClick={() => setHideSideNavBar(false)}
          >
            <div className="bg-accent rounded-t-lg">
              <div className="group-hover:animate-bounce rounded-t-lg flex items-start bg-accent p-4 w-12 fill-black group-hover:fill-white">
                <i className="bi bi-arrows-angle-expand"></i>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="w-1/4 bg-primary flex flex-col">
          {/* <div className="CompanionBar">
                        {renderCompanion && renderCompanion()}
                        </div> */}
          <div
            className="fill-black hover:fill-white cursor-pointer rounded-t-lg flex items-start bg-accent w-full"
            onClick={() => setHideSideNavBar(true)}
          >
            <div className="flex flex-row items-center justify-between py-1 px-2 w-full text-white text-sm uppercase">
              <div className="whitespace-nowrap pr-2">
                {direction === Direction.LEFT ? "Images" : "tools"}
              </div>
              <div className="flex flex-row ml-2">
                <i className="bi bi-arrows-angle-contract"></i>
              </div>
            </div>
          </div>
          {direction === Direction.LEFT ? (
            <div className="">
              {getImageStateMenu()}
              {imageStatus !== ImageStatus.ALL &&
                imageStatus !== ImageStatus.UN_ANNOTATED &&
                allImages &&
                allImages.length !== 0 && (
                  <div className="px-6 pt-2 flex items-center">
                    <input
                      type="checkbox"
                      checked={isAllSelected}
                      onChange={onSelectAllClickHandler}
                      className="mr-2 w-4 h-4"
                    />
                    <span className=" text-white text-sm">
                      {isAllSelected ? "Unselect All" : "Select All"}
                    </span>
                  </div>
                )}
            </div>
          ) : (
            <div className="">{getLabelListMenu()}</div>
          )}
          {isOpen && (
            <div className="w-full grow overflow-x-scroll no-scrollbar">
              {renderContent && renderContent()}
            </div>
          )}
        </div>
      )}
    </>
  )
}

const mapDispatchToProps = {
  updateActiveLabelType,
  updateActiveLabelId,
  updateImageStatus,
  updateSelectedImages,
}

const mapStateToProps = (state: AppState) => ({
  activeLabelType: state.labels.activeLabelType,
  projectType: state.general.projectData.type,
  imageStatus: state.labels.imageStatus,
  selectedImages: state.labels.selectedImages,
  allImages: state.labels.imagesData,
})

export default connect(mapStateToProps, mapDispatchToProps)(SideNavigationBar)
