import axios from "axios"
require("dotenv").config()

export const sendInvitation = async (
  projectId,
  projectName,
  userRole,
  adminMailId,
  invitedUserMailId,
  accessToken: string
) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/invitations/sendInvitation`,
      {
        projectId,
        projectName,
        userRole,
        adminMailId,
        invitedMailId: invitedUserMailId,
      },
      {
        headers: {
          "Content-Type": "application/json",
          accessToken: accessToken,
        },
      }
    )
    return { statusCode: 200, data }
  } catch (error) {
    if (error instanceof Error) {
      return { statusCode: 500, error: error.message }
    } else {
      return { statusCode: 500, error: "Internal Server error!" }
    }
  }
}

export const getInvitation = async (invitationId, accessToken: string) => {
  console.log({ accessToken })
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/invitations/getInvitation`,
      {
        invitationId,
      },
      {
        headers: {
          "Content-Type": "application/json",
          accessToken: accessToken,
        },
      }
    )
    return { statusCode: 200, data }
  } catch (error) {
    if (error instanceof Error) {
      return { statusCode: 500, error: error.message }
    } else {
      return { statusCode: 500, error: "Internal Server error!" }
    }
  }
}

export const sendMailToAdmin = async (
  mailId,
  accept: boolean,
  userRole: string,
  projectId: string,
  contributorId: string,
  accessToken: string
) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/invitations/sendMailToAdmin`,
      {
        adminMailId: mailId,
        accept,
        userRole,
        projectId,
        contributorId,
      },
      {
        headers: {
          "Content-Type": "application/json",
          accessToken: accessToken,
        },
      }
    )
    return { statusCode: 200, data }
  } catch (error) {
    if (error instanceof Error) {
      return { statusCode: 500, error: error.message }
    }
  }
}

export const deleteInvitation = async (invitationId, accessToken: string) => {
  try {
    const { data } = await axios.delete(`${process.env.REACT_APP_API_URL}/invitations`, {
      data: { invitationId },
      headers: {
        "Content-Type": "application/json",
        accessToken: accessToken,
      },
    })
    return { statusCode: 200, data }
  } catch (error) {
    if (error instanceof Error) {
      return { statusCode: 500, error: error.message }
    } else {
      return { statusCode: 500, error: "Internal Server error!" }
    }
  }
}
