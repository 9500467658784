const AnnotationFormatsSection = () => {
  return (
    <div className="px-8 md:px-16 lg:px-[4rem] py-20">
      <h2 className="text-4xl md:text-5xl lg:text-6xl font-bold text-accent mb-8">
        Versatile Annotation Formats
      </h2>
      <div className="flex flex-wrap justify-start gap-4 mb-12">
        <div className="badge badge-outline badge-accent p-4">yolo</div>
        <div className="badge badge-outline badge-accent p-4">voc</div>
        <div className="badge badge-outline badge-accent p-4">xml</div>
        <div className="badge badge-outline badge-accent p-4">vgg</div>
        <div className="badge badge-outline badge-accent p-4">json</div>
        <div className="badge badge-outline badge-accent p-4">csv</div>
      </div>

      <div className="flex flex-col lg:flex-row items-center lg:justify-between gap-16">
        {/* Image on the left */}
        <div className="w-full lg:w-2/5">
          <img
            src="/image-annotation.jpg"
            alt="Street Scene"
            className="rounded-lg shadow-lg w-full h-72"
          />
        </div>

        {/* Content on the right */}
        <div className="w-full lg:w-3/5 p-4 flex flex-col">
          <ul className="text-justify list-inside list-disc space-y-4">
            <li>
              Supports multiple annotation types including rectangles, lines, points, and polygons,
              providing flexibility for various annotation needs.
            </li>
            <li>
              Facilitates seamless import/export of annotations, improving efficiency and enabling
              smooth collaboration across different teams.
            </li>
            <li>
              Adaptable to a wide range of computer vision tasks, such as object detection, image
              segmentation, and image classification, ensuring extensive applicability.
            </li>
            <li>
              Handles complex labeling tasks with multi-class annotations, making it ideal for
              training advanced machine learning models.
            </li>
            <li>
              Optimized for project management, allowing administrators to track labeling progress
              and manage users with different roles such as annotators, reviewers, and admins.
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default AnnotationFormatsSection
