import { ISize } from "../../interfaces/ISize"
import { GeneralActionTypes, ProjectData, UserData } from "./types"
import { Action } from "../Actions"
import { PopupWindowType } from "../../data/enums/PopupWindowType"
import { CustomCursorStyle } from "../../data/enums/CustomCursorStyle"
import { ContextType } from "../../data/enums/ContextType"
import { ProjectMongoData } from "interfaces/IProjects"

export function updateWindowSize(windowSize: ISize): GeneralActionTypes {
  return {
    type: Action.UPDATE_WINDOW_SIZE,
    payload: {
      windowSize,
    },
  }
}

export function updateActivePopupType(activePopupType: PopupWindowType): GeneralActionTypes {
  return {
    type: Action.UPDATE_ACTIVE_POPUP_TYPE,
    payload: {
      activePopupType,
    },
  }
}

export function updateTheme(theme: string): GeneralActionTypes {
  return {
    type: Action.UPDATE_THEME,
    payload: {
      theme,
    },
  }
}

export function updateCustomCursorStyle(customCursorStyle: CustomCursorStyle): GeneralActionTypes {
  return {
    type: Action.UPDATE_CUSTOM_CURSOR_STYLE,
    payload: {
      customCursorStyle,
    },
  }
}

export function updateActiveContext(activeContext: ContextType): GeneralActionTypes {
  return {
    type: Action.UPDATE_CONTEXT,
    payload: {
      activeContext,
    },
  }
}

export function updatePreventCustomCursorStatus(preventCustomCursor: boolean): GeneralActionTypes {
  return {
    type: Action.UPDATE_PREVENT_CUSTOM_CURSOR_STATUS,
    payload: {
      preventCustomCursor,
    },
  }
}

export function updateImageDragModeStatus(imageDragMode: boolean): GeneralActionTypes {
  return {
    type: Action.UPDATE_IMAGE_DRAG_MODE_STATUS,
    payload: {
      imageDragMode,
    },
  }
}

export function updateCrossHairVisibleStatus(crossHairVisible: boolean): GeneralActionTypes {
  return {
    type: Action.UPDATE_CROSS_HAIR_VISIBLE_STATUS,
    payload: {
      crossHairVisible,
    },
  }
}

export function updateProjectData(projectData: ProjectData): GeneralActionTypes {
  return {
    type: Action.UPDATE_PROJECT_DATA,
    payload: {
      projectData,
    },
  }
}

export function updateProjectList(projectList: ProjectMongoData[]): GeneralActionTypes {
  return {
    type: Action.UPDATE_PROJECT_LIST,
    payload: {
      projectList,
    },
  }
}

export function updateZoom(zoom: number): GeneralActionTypes {
  return {
    type: Action.UPDATE_ZOOM,
    payload: {
      zoom,
    },
  }
}

export function updatePerClassColorationStatus(
  enablePerClassColoration: boolean
): GeneralActionTypes {
  return {
    type: Action.UPDATE_ENABLE_PER_CLASS_COLORATION_STATUS,
    payload: {
      enablePerClassColoration,
    },
  }
}

export function updateActivePopupData(activePopUpData: Object): GeneralActionTypes {
  return {
    type: Action.UPDATE_ACTIVE_POPUP_DATA,
    payload: {
      activePopUpData,
    },
  }
}

export function updateShowMenu(showMenu: boolean): GeneralActionTypes {
  return {
    type: Action.UPDATE_SHOW_MENU,
    payload: {
      showMenu,
    },
  }
}

export function updateUserData(userData: UserData): GeneralActionTypes {
  return {
    type: Action.UPDATE_USER_DATA,
    payload: {
      userData,
    },
  }
}

export function updateUserRole(userRole: string): GeneralActionTypes {
  return {
    type: Action.UPDATE_USER_ROLE,
    payload: {
      userRole,
    },
  }
}
