import { LabelType } from "../enums/LabelType"
import { ProjectType } from "../enums/ProjectType"

export interface ILabelToolkit {
  labelType: LabelType
  headerText: string
  imageSrc: string
  darkImageSrc: string
  imageAlt: string
  projectType: ProjectType
}

export const LabelToolkitData: ILabelToolkit[] = [
  {
    labelType: LabelType.IMAGE_RECOGNITION,
    headerText: "Image recognition",
    imageSrc: "/ico/object-white.png",
    darkImageSrc: "/ico/object.png",
    imageAlt: "object",
    projectType: ProjectType.IMAGE_RECOGNITION,
  },
  {
    labelType: LabelType.RECT,
    headerText: "Rect",
    imageSrc: "/ico/rectangle-white.png",
    darkImageSrc: "/ico/rectangle.png",
    imageAlt: "rectangle",
    projectType: ProjectType.OBJECT_DETECTION,
  },
  {
    labelType: LabelType.POINT,
    headerText: "Point",
    imageSrc: "/ico/point-white.png",
    darkImageSrc: "/ico/point.png",
    imageAlt: "point",
    projectType: ProjectType.OBJECT_DETECTION,
  },
  {
    labelType: LabelType.LINE,
    headerText: "Line",
    imageSrc: "/ico/line-white.png",
    darkImageSrc: "/ico/line.png",
    imageAlt: "line",
    projectType: ProjectType.OBJECT_DETECTION,
  },
  {
    labelType: LabelType.POLYGON,
    headerText: "Polygon",
    imageSrc: "/ico/polygon-white.png",
    darkImageSrc: "/ico/polygon.png",
    imageAlt: "polygon",
    projectType: ProjectType.OBJECT_DETECTION,
  },
]
