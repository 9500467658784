export const blogs = [
  {
    title: "Unlocking the Power of Data Annotation in AI and Machine Learning",
    description:
      "Exploring the importance of data annotation in AI and ML, including techniques like image classification, object detection, and OCR to improve model accuracy and efficiency.",
    imgSrc: "/blogs/DataAnnotationInAI/thumbnail.png",
    href: "/blogs/DataAnnotationInAI",
    dateOfPublication: "30/06/2024",
  },
  {
    title:
      "Crop Disease Detection: Leveraging Machine Learning and Computer Vision to Safeguard Yields",
    description:
      "This blog discusses how modern technology steps in to enable early and accurate disease identification, significantly reducing the impact of diseases on crop yield.",
    imgSrc: "/blogs/CropDiseaseDetection/thumbnail.png",
    href: "/blogs/CropDiseaseDetection",
    dateOfPublication: "26/03/1999",
  },
  {
    title: "Harvest Optimization: Leveraging Computer Vision for Enhanced Crop Management",
    description:
      "Exploring how computer vision systems are vital for harvest optimization, presenting applications, benefits, and future of smart farming.",
    imgSrc: "/blogs/HarvestOptimization/thumbnail.png",
    href: "/blogs/HarvestOptimization",
    dateOfPublication: "15/08/2002",
  },
  {
    title: "Revolutionizing Crop Grading with Image Processing Techniques",
    description:
      "This blog explores how computer vision and image processing techniques are revolutionizing crop grading and disease detection.",
    imgSrc: "/blogs/CropGradingAndDiseaseDetection/thumbnail.png",
    href: "/blogs/CropGradingAndDiseaseDetection",
    dateOfPublication: "30/05/2005",
  },
  {
    title: "Livestock Monitoring with Machine Learning: Revolutionizing Animal Health Care",
    description:
      "Examining the role of machine learning in detecting sick animals, predicting cattle health, and providing timely medical care.",
    imgSrc: "/blogs/LivestockMonitoring/thumbnail.png",
    href: "/blogs/LivestockMonitoring",
    dateOfPublication: "12/11/2008",
  },
  {
    title: "Revolutionizing Pest Detection and Prevention with Computer Vision",
    description:
      "Exploring how advancements in computer vision offer effective and accurate pest detection and prevention.",
    imgSrc: "/blogs/PestDetectionAndPrevention/thumbnail.png",
    href: "/blogs/PestDetectionAndPrevention",
    dateOfPublication: "21/06/2012",
  },
  {
    title: "Optimizing Land Management with Machine Learning: Maximizing Crop and Cattle Yield",
    description:
      "Discussing the use of machine learning for precision agriculture, predictive analytics, and resource allocation.",
    imgSrc: "/blogs/LandManagementOptimization/thumbnail.png",
    href: "/blogs/LandManagementOptimization",
    dateOfPublication: "14/03/2016",
  },
  {
    title: "Enhancing Safety with Computer Vision: PPE Detection and Enforcement",
    description:
      "Exploring how computer vision can detect the use of PPE, ensuring compliance and reducing the risk of accidents in hazardous environments.",
    imgSrc: "/blogs/PPEDetection/thumbnail.png",
    href: "/blogs/PPEDetection",
    dateOfPublication: "10/09/2019",
  },
  {
    title: "Remote Construction Monitoring: Revolutionizing the Industry",
    description:
      "Highlighting how remote monitoring systems powered by IoT, drones, and advanced software provide real-time oversight of construction sites.",
    imgSrc: "/blogs/RemoteConstructionMonitoring/thumbnail.png",
    href: "/blogs/RemoteConstructionMonitoring",
    dateOfPublication: "05/01/2021",
  },
  {
    title: "Revolutionizing Assembly Line Monitoring with Machine Vision Systems",
    description:
      "Detailing how machine vision systems are transforming assembly line monitoring by incorporating progress monitoring, gesture control, and worker support.",
    imgSrc: "/blogs/AssemblyLineMonitoring/thumbnail.png",
    href: "/blogs/AssemblyLineMonitoring",
    dateOfPublication: "15/05/2022",
  },
  {
    title: "Leveraging Machine Learning for Workplace Accident Prevention",
    description:
      "Delving into how machine learning provides predictive insights, real-time monitoring, and proactive measures to prevent workplace accidents.",
    imgSrc: "/blogs/WorkplaceAccidentPrevention/thumbnail.png",
    href: "/blogs/WorkplaceAccidentPrevention",
    dateOfPublication: "30/12/2023",
  },
]
