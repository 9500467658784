import { sendMailToAdmin } from "apis/invitations"
import { PopupActions } from "logic/actions/PopupActions"
import React, { PropsWithChildren } from "react"
import { connect } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { AppState } from "store"
import { updateUserData } from "store/general/actionCreators"
import { ProjectData, UserData } from "store/general/types"
import { GenericYesNoPopup } from "../GenericYesNoPopup/GenericYesNoPopup"

interface IProps {
  updateUserDataAction: (userData: UserData) => any
  userData: UserData
  projectData: ProjectData
}

const ConfirmInvitePopup: React.FC<IProps> = (props: PropsWithChildren<IProps>) => {
  const location = useLocation()
  console.log({ location })
  const navigator = useNavigate()
  const adminMailId = !!location?.state && location?.state["adminMailId"]
  const userRole: string = !!location?.state && location?.state["userRole"]
  const projectIdAndName: string = !!location?.state && location?.state["projectId"]
  const contributorId: string = !!location?.state && location?.state["contributorId"]
  const accessToken = localStorage.getItem("accessToken")
  const sendMail = async (accept: boolean) => {
    const res = await sendMailToAdmin(
      adminMailId,
      accept,
      userRole,
      projectIdAndName,
      contributorId,
      accessToken
    )
    if (res.statusCode === 200) {
      console.log("Mail sent to admin")
      toast.success("Mail sent to admin successfully!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      })
      // Update contributorProjectIds in userData
      if (accept) {
        const newUserData: UserData = {
          ...props.userData,
          contributorProjectIds: [...props.userData.contributorProjectIds, projectIdAndName],
        }
        props.updateUserDataAction(newUserData)
        // setUserData({
        //   ...userData,
        //   contributorProjectIds: [...userData.contributorProjectIds, projectId],
        // });
      }
      //   navigator("/dashboard", { replace: true });
    } else {
      toast.error("Internal Server Error!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      })
    }
    PopupActions.close()
    navigator("/dashboard", { replace: true })
  }

  function renderContent() {
    return (
      <>
        <div className="pb-4">
          <div className="pt-2 pb-5 text-center text-base">{`You have been invited by ${adminMailId} to contribute to project ${
            projectIdAndName ? projectIdAndName.split("_")[1] : ""
          }`}</div>
        </div>
      </>
    )
  }

  return (
    <>
      <GenericYesNoPopup
        title={"Confirm Invitation"}
        renderContent={renderContent}
        acceptLabel={"Confirm"}
        onAccept={() => sendMail(true)}
        rejectLabel={"Deny"}
        onReject={() => sendMail(false)}
        // skipRejectButton
      />
    </>
  )
}

const mapDispatchToProps = {
  updateUserDataAction: updateUserData,
}

const mapStateToProps = (state: AppState) => ({
  userData: state.general.userData,
  projectData: state.general.projectData,
})

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmInvitePopup)
