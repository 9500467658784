import { ContextType } from "../../../data/enums/ContextType"
import "./EditorTopNavigationBar.scss"
import React from "react"
import { AppState } from "../../../store"
import { connect } from "react-redux"
import {
  updateCrossHairVisibleStatus,
  updateImageDragModeStatus,
} from "../../../store/general/actionCreators"
import { ViewPortActions } from "../../../logic/actions/ViewPortActions"
import { LabelsSelector } from "../../../store/selectors/LabelsSelector"
import { LabelType } from "../../../data/enums/LabelType"
import { AISelector } from "../../../store/selectors/AISelector"
import { AIActions } from "../../../logic/actions/AIActions"

// const BUTTON_SIZE: ISize = { width: 30, height: 30 };
// const BUTTON_PADDING: number = 10;

interface IProps {
  activeContext: ContextType
  updateImageDragModeStatusAction: (imageDragMode: boolean) => any
  updateCrossHairVisibleStatusAction: (crossHairVisible: boolean) => any
  imageDragMode: boolean
  crossHairVisible: boolean
  activeLabelType: LabelType
  imageSize: number
}

const EditorTopNavigationBar: React.FC<IProps> = ({
  activeContext,
  updateImageDragModeStatusAction,
  updateCrossHairVisibleStatusAction,
  imageDragMode,
  crossHairVisible,
  activeLabelType,
  imageSize,
}) => {
  // const getClassName = () => {
  //     return classNames(
  //         'EditorTopNavigationBar bg-secondary bg-opacity-25 rounded-full',
  //         {
  //             'with-context': activeContext === ContextType.EDITOR
  //         }
  //     );
  // };

  const imageDragOnClick = () => {
    if (imageDragMode) {
      updateImageDragModeStatusAction(!imageDragMode)
    } else {
      updateImageDragModeStatusAction(!imageDragMode)
    }
  }

  const crossHairOnClick = () => {
    updateCrossHairVisibleStatusAction(!crossHairVisible)
  }

  return (
    <div className="flex px-12 py-3">
      <div className=" bg-secondary bg-opacity-25 rounded-full flex w-full gap-5 items-center px-2">
        <div className="flex item-center pl-4">
          <div className="tooltip tooltip-primary" data-tip="zoom in">
            <button
              className={`hover:bg-primary p-2 mx-auto my-auto rounded-md opacity-70 hover:opacity-100`}
              onClick={() => ViewPortActions.zoomIn()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi fill-primary-content bi-zoom-in "
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"
                />
                <path d="M10.344 11.742c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1 6.538 6.538 0 0 1-1.398 1.4z" />
                <path
                  fillRule="evenodd"
                  d="M6.5 3a.5.5 0 0 1 .5.5V6h2.5a.5.5 0 0 1 0 1H7v2.5a.5.5 0 0 1-1 0V7H3.5a.5.5 0 0 1 0-1H6V3.5a.5.5 0 0 1 .5-.5z"
                />
              </svg>
            </button>
          </div>
          <div className="tooltip tooltip-primary" data-tip="zoom out">
            <button
              className={`hover:bg-primary p-2 mx-auto my-auto rounded-md opacity-70 hover:opacity-100`}
              onClick={() => ViewPortActions.zoomOut()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi fill-primary-content bi-zoom-out"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"
                />
                <path d="M10.344 11.742c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1 6.538 6.538 0 0 1-1.398 1.4z" />
                <path
                  fillRule="evenodd"
                  d="M3 6.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z"
                />
              </svg>
            </button>
          </div>
          <div className="tooltip tooltip-primary" data-tip="fit image to available space">
            <button
              className={`hover:bg-primary p-2 mx-auto my-auto rounded-md opacity-70 hover:opacity-100`}
              onClick={() => ViewPortActions.setDefaultZoom()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi fill-primary-content bi-aspect-ratio-fill"
                viewBox="0 0 16 16"
              >
                <path d="M0 12.5v-9A1.5 1.5 0 0 1 1.5 2h13A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 12.5zM2.5 4a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 1 0V5h2.5a.5.5 0 0 0 0-1h-3zm11 8a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-1 0V11h-2.5a.5.5 0 0 0 0 1h3z" />
              </svg>
            </button>
          </div>
        </div>
        <div className="flex item-center">
          <div
            className="tooltip tooltip-primary"
            data-tip={`${
              imageDragMode
                ? "turn-off image drag mode"
                : "turn-on image drag mode - works only when image is zoomed"
            }`}
          >
            <button
              className={`${
                !imageDragMode ? " border-white border-opacity-0" : "border-opacity-0"
              } bg-opacity-25 p-2 mx-auto my-auto hover:bg-primary rounded-md border-2 opacity-70 hover:opacity-100`}
              onClick={imageDragOnClick}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi fill-primary-content bi-hand-index"
                viewBox="0 0 16 16"
              >
                <path d="M6.75 1a.75.75 0 0 1 .75.75V8a.5.5 0 0 0 1 0V5.467l.086-.004c.317-.012.637-.008.816.027.134.027.294.096.448.182.077.042.15.147.15.314V8a.5.5 0 1 0 1 0V6.435a4.9 4.9 0 0 1 .106-.01c.316-.024.584-.01.708.04.118.046.3.207.486.43.081.096.15.19.2.259V8.5a.5.5 0 0 0 1 0v-1h.342a1 1 0 0 1 .995 1.1l-.271 2.715a2.5 2.5 0 0 1-.317.991l-1.395 2.442a.5.5 0 0 1-.434.252H6.035a.5.5 0 0 1-.416-.223l-1.433-2.15a1.5 1.5 0 0 1-.243-.666l-.345-3.105a.5.5 0 0 1 .399-.546L5 8.11V9a.5.5 0 0 0 1 0V1.75A.75.75 0 0 1 6.75 1zM8.5 4.466V1.75a1.75 1.75 0 1 0-3.5 0v5.34l-1.2.24a1.5 1.5 0 0 0-1.196 1.636l.345 3.106a2.5 2.5 0 0 0 .405 1.11l1.433 2.15A1.5 1.5 0 0 0 6.035 16h6.385a1.5 1.5 0 0 0 1.302-.756l1.395-2.441a3.5 3.5 0 0 0 .444-1.389l.271-2.715a2 2 0 0 0-1.99-2.199h-.581a5.114 5.114 0 0 0-.195-.248c-.191-.229-.51-.568-.88-.716-.364-.146-.846-.132-1.158-.108l-.132.012a1.26 1.26 0 0 0-.56-.642 2.632 2.632 0 0 0-.738-.288c-.31-.062-.739-.058-1.05-.046l-.048.002zm2.094 2.025z" />
              </svg>{" "}
            </button>
          </div>
          <div
            className="tooltip tooltip-primary"
            data-tip={` ${
              crossHairVisible ? "turn-off cursor cross-hair" : "turn-on cursor cross-hair"
            }`}
          >
            <button
              className={`${
                !crossHairVisible ? " border-white border-opacity-0 " : "border-opacity-0"
              } bg-opacity-25 p-2 mx-auto my-auto hover:bg-primary rounded-md border-2 opacity-70 hover:opacity-100`}
              onClick={crossHairOnClick}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi fill-primary-content bi-plus-lg"
              >
                <path
                  fillRule="evenodd"
                  d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
                />
              </svg>
            </button>
          </div>
        </div>
        {((activeLabelType === LabelType.RECT && AISelector.isAIObjectDetectorModelLoaded()) ||
          (activeLabelType === LabelType.POINT && AISelector.isAIPoseDetectorModelLoaded())) && (
          <div className="flex">
            <div className="tooltip tooltip-primary" data-tip="accept all proposed detections">
              <button
                className={`hover:bg-primary p-2 mx-auto my-auto rounded-md opacity-70 hover:opacity-100`}
                onClick={() =>
                  AIActions.acceptAllSuggestedLabels(LabelsSelector.getActiveImageData())
                }
                disabled={true}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi fill-primary-content bi-check2"
                  viewBox="0 0 16 16"
                >
                  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                </svg>
              </button>
            </div>
            <div className="tooltip tooltip-primary" data-tip="reject all proposed detections">
              <button
                className={`hover:bg-primary p-2 mx-auto my-auto rounded-md opacity-70 hover:opacity-100`}
                onClick={() =>
                  AIActions.rejectAllSuggestedLabels(LabelsSelector.getActiveImageData())
                }
                disabled={true}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi fill-primary-content bi-x"
                  viewBox="0 0 16 16"
                >
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
              </button>
            </div>
          </div>
        )}
        <button
          className={`hover:bg-primary px-2 my-auto rounded-md text-sm`}
          onClick={() => AIActions.rejectAllSuggestedLabels(LabelsSelector.getActiveImageData())}
          disabled={true}
        >
          {imageSize} total images
        </button>
      </div>
    </div>
  )
}

const mapDispatchToProps = {
  updateImageDragModeStatusAction: updateImageDragModeStatus,
  updateCrossHairVisibleStatusAction: updateCrossHairVisibleStatus,
}

const mapStateToProps = (state: AppState) => ({
  activeContext: state.general.activeContext,
  imageDragMode: state.general.imageDragMode,
  crossHairVisible: state.general.crossHairVisible,
  activeLabelType: state.labels.activeLabelType,
  imageSize: state.labels.imageSize,
})

export default connect(mapStateToProps, mapDispatchToProps)(EditorTopNavigationBar)
