import { AuthContext } from "contexts/authContext"
import React, { useContext } from "react"
import "./NotFoundView.scss"

const NotFoundView: React.FC<{ loadingBarRef: React.MutableRefObject<any> }> = ({
  loadingBarRef,
}) => {
  const authContext = useContext(AuthContext)

  return (
    <>
      <div
        className={`w-screen flex-grow flex flex-col justify-center items-center text-center text-white ${
          authContext.getUserInfo() ? "pt-20" : ""
        }`}
      >
        <img className="w-2/5 p-8" alt={"404"} src="404-illustration.svg" />
        <p className="font-bold text-2xl">Page Not Found</p>
        <br></br>
        <p>Make sure the address is correct and the page hasn't moved.</p>
        <p>Please contact your administrator if you think this is a mistake.</p>
        <div className="divider pt-8 pb-2"></div>
        <nav>
          <ul className="flex gap-2 text-base-content fill-base-content hover:fill-white hover:text-white hover:underline">
            <li>
              <div className="flex gap-1 items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  className="bi bi-house-door-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M6.5 14.5v-3.505c0-.245.25-.495.5-.495h2c.25 0 .5.25.5.5v3.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5Z" />
                </svg>
                <a href="/">Home</a>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </>
  )
}

export default NotFoundView
