import React, { useState } from "react"
import { ISize } from "../../../../interfaces/ISize"
import { ImageData, LabelName, LabelRect } from "../../../../store/labels/types"
import "./RectLabelsList.scss"
import {
  updateActiveLabelId,
  updateActiveLabelNameId,
  updateImageDataById,
} from "../../../../store/labels/actionCreators"
import { AppState } from "../../../../store"
import { connect } from "react-redux"
import LabelInputField from "../LabelInputField/LabelInputField"
import EmptyLabelList from "../EmptyLabelList/EmptyLabelList"
import { LabelActions } from "../../../../logic/actions/LabelActions"
import { LabelStatus } from "../../../../data/enums/LabelStatus"
import { LabelsSelector } from "store/selectors/LabelsSelector"
import { store } from "index"

interface IProps {
  size: ISize
  imageData: ImageData
  updateImageDataById: (id: string, newImageData: ImageData) => any
  activeLabelId: string
  highlightedLabelId: string
  updateActiveLabelNameId: (activeLabelId: string) => any
  labelNames: LabelName[]
  updateActiveLabelId: (activeLabelId: string) => any
}

export const updateRectLabel = (labelRectId: string, labelNameId: string) => {
  const imagesData = LabelsSelector.getImagesData()
  const activeImageIndex = LabelsSelector.getActiveImageIndex()
  const imgData = imagesData[activeImageIndex]
  const newImageData = {
    ...imgData,
    labelRects: imgData?.labelRects.map((labelRect: LabelRect) => {
      if (labelRect.id === labelRectId) {
        return {
          ...labelRect,
          labelId: labelNameId,
          status: LabelStatus.ACCEPTED,
        }
      } else {
        return labelRect
      }
    }),
  }
  store.dispatch(updateImageDataById(imgData.id, newImageData))
  store.dispatch(updateActiveLabelNameId(labelNameId))
}

const RectLabelsList: React.FC<IProps> = ({
  size,
  imageData,
  updateImageDataById,
  labelNames,
  updateActiveLabelNameId,
  activeLabelId,
  highlightedLabelId,
  updateActiveLabelId,
}) => {
  const labelInputFieldHeight = 40

  const deleteRectLabelById = (labelRectId: string) => {
    LabelActions.deleteRectLabelById(imageData.id, labelRectId)
  }

  const onClickHandler = () => {
    updateActiveLabelId(null)
  }

  const [expandedGroups, setExpandedGroups] = useState<string[]>(["undefined"])

  const toggleGroup = (groupId: string) => {
    if (expandedGroups.includes(groupId)) {
      setExpandedGroups(expandedGroups.filter((id) => id !== groupId))
    } else {
      setExpandedGroups([...expandedGroups, groupId])
    }
  }

  // ...

  const renderGroup = (labelId: string, labels: LabelRect[]) => {
    const labelName = labelNames.find((label) => label.id === labelId)
    console.log(labelId)
    const labelColor = labelName?.color || ""

    return (
      <div key={labelId}>
        <div
          className={`py-2 px-4 cursor-pointer flex justify-between items-center`}
          onClick={() => toggleGroup(labelId)}
        >
          <span className="flex items-center">
            {labelColor && (
              <div className="w-6 h-6 rounded-md mr-2" style={{ backgroundColor: labelColor }} />
            )}
            {labelName?.name ? labelName?.name : "undefined"}
          </span>
          <span className="flex items-center text-sm">
            <span className="px-1">{labels.length}</span>
            {!expandedGroups.includes(labelId) ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-caret-right"
                viewBox="0 0 16 16"
              >
                <path d="M6 12.796V3.204L11.481 8 6 12.796zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z" />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-caret-down-fill"
                viewBox="0 0 16 16"
              >
                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
              </svg>
            )}
          </span>
        </div>
        {expandedGroups.includes(labelId) && (
          <div>
            {labels.map((labelRect) => (
              <LabelInputField
                size={{
                  width: size.width,
                  height: labelInputFieldHeight,
                }}
                isActive={labelRect.id === activeLabelId}
                isHighlighted={labelRect.id === highlightedLabelId}
                id={labelRect.id}
                key={labelRect.id}
                onDelete={deleteRectLabelById}
                value={labelName}
                options={labelNames}
                onSelectLabel={updateRectLabel}
              />
            ))}
          </div>
        )}
      </div>
    )
  }

  const getChildren = () => {
    const groupedLabels: Record<string, LabelRect[]> = {}
    const undefinedLabelGroup: LabelRect[] = [] // Group for label rects with undefined label names

    imageData?.labelRects.forEach((labelRect) => {
      console.log(`label rect ${JSON.stringify(labelRect)}`)
      const labelId = labelRect.labelId !== null ? labelRect.labelId : "other"
      const labelName = labelNames.find((label) => label.id === labelId)

      if (labelName === undefined) {
        undefinedLabelGroup.push(labelRect)
      } else {
        if (groupedLabels[labelId]) {
          groupedLabels[labelId].push(labelRect)
        } else {
          groupedLabels[labelId] = [labelRect]
        }
      }
    })

    // Push the undefined label group into groupedLabels
    if (undefinedLabelGroup.length > 0) {
      groupedLabels["undefined"] = undefinedLabelGroup
    }

    // Render grouped labels
    const renderedLabels = Object.entries(groupedLabels).map(([labelId, labels]) =>
      renderGroup(labelId, labels)
    )

    return renderedLabels
  }

  return (
    <div onClickCapture={onClickHandler} className="h-full">
      {imageData?.labelRects.filter(
        (labelRect: LabelRect) => labelRect.status === LabelStatus.ACCEPTED
      ).length === 0 ? (
        <EmptyLabelList type="Rectange" />
      ) : (
        <>{getChildren()}</>
      )}
    </div>
  )
}

const mapDispatchToProps = {
  updateImageDataById,
  updateActiveLabelNameId,
  updateActiveLabelId,
}

const mapStateToProps = (state: AppState) => ({
  activeLabelId: state.labels.activeLabelId,
  highlightedLabelId: state.labels.highlightedLabelId,
  labelNames: state.labels.labels,
})

export default connect(mapStateToProps, mapDispatchToProps)(RectLabelsList)
