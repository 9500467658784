import { ThemeToggle } from "views/Common/ThemeToggle/ThemeToggle"

const Navbar = () => {
  const hostname = window.location.hostname
  const logoSrc = hostname === "easyml.co" ? "easyML.png" : "/upjao-logo.png"
  return (
    <div className="navbar w-full sticky px-16">
      <header className="flex w-full justify-between items-center px-12 py-4 rounded-xl border border-accent">
        <a href="/">
          <img width="130px" draggable={false} alt="logo" src={logoSrc} />
        </a>
        <div className="flex items-center gap-2">
          <button className="btn bg-transparent border-transparent text-primary-content hover:bg-transparent hover:border-accent disabled:bg-accent-content">
            About
          </button>
          <a
            href="/#key-features"
            className="btn bg-transparent border-transparent text-primary-content hover:bg-transparent hover:border-accent disabled:bg-accent-content"
          >
            Key Features
          </a>
          <a
            href="/#our-blogs"
            className="btn bg-transparent border-transparent text-primary-content hover:bg-transparent hover:border-accent disabled:bg-accent-content"
          >
            Our Blogs
          </a>
          <a
            href="/#footer"
            className="btn bg-transparent border-transparent text-primary-content hover:bg-transparent hover:border-accent disabled:bg-accent-content"
          >
            Get In Touch
          </a>
          <a
            href="/signup"
            className="btn bg-transparent border-accent-content text-primary-content hover:bg-transparent hover:border-accent disabled:bg-accent-content"
          >
            Get Started
          </a>
          <div className="w-10 h-10 rounded-full flex items-center justify-center">
            <ThemeToggle />
          </div>
        </div>
      </header>
    </div>
  )
}

export default Navbar
