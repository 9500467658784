import { applyMiddleware, compose, createStore } from "redux"
import { thunk } from "redux-thunk"
import { rootReducer } from "./store"

export default function configureStore() {
  // @ts-ignore
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose // use compose from Redux if the extension is not installed

  return createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)))
}
