import React, { useState } from "react"
import { Formik, Field, Form, ErrorMessage, useFormik } from "formik"
import * as Yup from "yup"
import { toast } from "react-toastify"
import { GenericYesNoPopup } from "../GenericYesNoPopup/GenericYesNoPopup"
import { PopupActions } from "logic/actions/PopupActions"
import { FeedbackFormValues, submitFeedbackAPI } from "apis/feedbacks"

// Validation schema using Yup
const validationSchema = Yup.object().shape({
  phoneNumber: Yup.string().matches(/^[6-9]\d{9}$/, "Please enter a valid Indian phone number"),
  annotationAccuracy: Yup.number()
    .min(1, "Minimum value is 1")
    .max(5, "Maximum value is 5")
    .required("Annotation accuracy rating is required"),
  toolUsability: Yup.number()
    .min(1, "Minimum value is 1")
    .max(5, "Maximum value is 5")
    .required("Tool usability rating is required"),
  userInterface: Yup.number()
    .min(1, "Minimum value is 1")
    .max(5, "Maximum value is 5")
    .required("User interface rating is required"),
  annotationSpeed: Yup.number()
    .min(1, "Minimum value is 1")
    .max(5, "Maximum value is 5")
    .required("Annotation speed rating is required"),
  overallSatisfaction: Yup.number()
    .min(1, "Minimum value is 1")
    .max(5, "Maximum value is 5")
    .required("Overall satisfaction rating is required"),
  encounteredIssues: Yup.string(),
  featureRequest: Yup.string(),
  comments: Yup.string(),
})

const FeedbackPopup: React.FC = () => {
  const formik = useFormik({
    initialValues: {
      phoneNumber: "",
      annotationAccuracy: 3,
      toolUsability: 3,
      userInterface: 3,
      annotationSpeed: 3,
      overallSatisfaction: 3,
      encounteredIssues: "",
      featureRequest: "",
      comments: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values: FeedbackFormValues, { setSubmitting }) => {
      setSubmitting(true)
      try {
        const accessToken: string = localStorage.getItem("accessToken")
        const response = await submitFeedbackAPI(values, accessToken)
        if (response.statusCode === 200) {
          toast.success("Feedback submitted successfully")
          PopupActions.close()
        } else {
          toast.error("Failed to submit feedback")
        }
      } catch (error) {
        toast.error("An unexpected error occurred")
      } finally {
        setSubmitting(false)
      }
    },
  })

  const renderContent = () => (
    <div className="py-8">
      <div className="w-full flex flex-col gap-4 h-96 overflow-auto px-2 scrollbar">
        {/* Phone Number */}
        <div>
          <label className="label">
            <span className="label-text">Phone Number</span>
          </label>
          <input
            id="phoneNumber"
            onChange={formik.handleChange}
            value={formik.values.phoneNumber}
            type="text"
            placeholder="Enter Phone Number"
            className="input input-bordered w-full"
            disabled={formik.isSubmitting}
          />
          <label className="label">
            <span className="label-text-alt text-error ">{formik.errors.phoneNumber}</span>
          </label>
        </div>

        {/* Annotation Accuracy */}
        <div>
          <label className="label">
            <span className="label-text">Annotation Accuracy (1-5)</span>
          </label>
          <input
            id="annotationAccuracy"
            type="range"
            min="1"
            max="5"
            onChange={formik.handleChange}
            value={formik.values.annotationAccuracy}
            className="range range-sm range-accent"
            disabled={formik.isSubmitting}
          />
          <div className="w-full flex justify-between text-xs px-2">
            {[1, 2, 3, 4, 5].map((val) => (
              <span key={val}>{val}</span>
            ))}
          </div>
          <label className="label">
            <span className="label-text-alt text-error ">{formik.errors.annotationAccuracy}</span>
          </label>
        </div>

        {/* Tool Usability */}
        <div>
          <label className="label">
            <span className="label-text">Tool Usability (1-5)</span>
          </label>
          <input
            id="toolUsability"
            type="range"
            min="1"
            max="5"
            onChange={formik.handleChange}
            value={formik.values.toolUsability}
            className="range range-sm range-accent"
            disabled={formik.isSubmitting}
          />
          <div className="w-full flex justify-between text-xs px-2">
            {[1, 2, 3, 4, 5].map((val) => (
              <span key={val}>{val}</span>
            ))}
          </div>
          <label className="label">
            <span className="label-text-alt text-error ">{formik.errors.toolUsability}</span>
          </label>
        </div>

        {/* User Interface */}
        <div>
          <label className="label">
            <span className="label-text">User Interface (1-5)</span>
          </label>
          <input
            id="userInterface"
            type="range"
            min="1"
            max="5"
            onChange={formik.handleChange}
            value={formik.values.userInterface}
            className="range range-sm range-accent"
            disabled={formik.isSubmitting}
          />
          <div className="w-full flex justify-between text-xs px-2">
            {[1, 2, 3, 4, 5].map((val) => (
              <span key={val}>{val}</span>
            ))}
          </div>
          <label className="label">
            <span className="label-text-alt text-error ">{formik.errors.userInterface}</span>
          </label>
        </div>

        {/* Annotation Speed */}
        <div>
          <label className="label">
            <span className="label-text">Annotation Speed (1-5)</span>
          </label>
          <input
            id="annotationSpeed"
            type="range"
            min="1"
            max="5"
            onChange={formik.handleChange}
            value={formik.values.annotationSpeed}
            className="range range-sm range-accent"
            disabled={formik.isSubmitting}
          />
          <div className="w-full flex justify-between text-xs px-2">
            {[1, 2, 3, 4, 5].map((val) => (
              <span key={val}>{val}</span>
            ))}
          </div>
          <label className="label">
            <span className="label-text-alt text-error ">{formik.errors.annotationSpeed}</span>
          </label>
        </div>

        {/* Overall Satisfaction */}
        <div>
          <label className="label">
            <span className="label-text">Overall Satisfaction (1-5)</span>
          </label>
          <input
            id="overallSatisfaction"
            type="range"
            min="1"
            max="5"
            onChange={formik.handleChange}
            value={formik.values.overallSatisfaction}
            className="range range-sm range-accent"
            disabled={formik.isSubmitting}
          />
          <div className="w-full flex justify-between text-xs px-2">
            {[1, 2, 3, 4, 5].map((val) => (
              <span key={val}>{val}</span>
            ))}
          </div>
          <label className="label">
            <span className="label-text-alt text-error ">{formik.errors.overallSatisfaction}</span>
          </label>
        </div>

        {/* Encountered Issues */}
        <div>
          <label className="label">
            <span className="label-text">Encountered Issues</span>
          </label>
          <textarea
            id="encounteredIssues"
            onChange={formik.handleChange}
            value={formik.values.encounteredIssues}
            placeholder="Describe any issues encountered"
            className="textarea textarea-bordered w-full"
            disabled={formik.isSubmitting}
          />
          <label className="label">
            <span className="label-text-alt text-error ">{formik.errors.encounteredIssues}</span>
          </label>
        </div>

        {/* Feature Request */}
        <div>
          <label className="label">
            <span className="label-text">Feature Request</span>
          </label>
          <textarea
            id="featureRequest"
            onChange={formik.handleChange}
            value={formik.values.featureRequest}
            placeholder="Suggest any new features"
            className="textarea textarea-bordered w-full"
            disabled={formik.isSubmitting}
          />
          <label className="label">
            <span className="label-text-alt text-error ">{formik.errors.featureRequest}</span>
          </label>
        </div>

        {/* Comments */}
        <div>
          <label className="label">
            <span className="label-text">Comments</span>
          </label>
          <textarea
            id="comments"
            onChange={formik.handleChange}
            value={formik.values.comments}
            placeholder="Enter your comments"
            className="textarea textarea-bordered w-full"
            disabled={formik.isSubmitting}
          />
          <label className="label">
            <span className="label-text-alt text-error ">{formik.errors.comments}</span>
          </label>
        </div>
      </div>
    </div>
  )

  return (
    <GenericYesNoPopup
      title={"Submit Feedback"}
      renderContent={renderContent}
      acceptLabel={"Submit"}
      onAccept={formik.submitForm}
      skipRejectButton={true}
      isFormik={() => formik}
      disableAcceptButton={formik.isSubmitting}
    />
  )
}

export default FeedbackPopup
