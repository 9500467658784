import React, { useState, useEffect, useContext } from "react"

import * as cognito from "../libs/cognito"

export enum AuthStatus {
  Loading,
  SignedIn,
  SignedOut,
}

export interface IAuth {
  sessionInfo?: {
    name?: string
    email?: string
    sub?: string
    accessToken?: string
    refreshToken?: string
  }
  attrInfo?: any
  authStatus?: AuthStatus
  signInWithEmail?: any
  signUpWithEmail?: any
  signOut?: any
  verifyCode?: any
  getSession?: any
  sendCode?: any
  forgotPassword?: any
  changePassword?: any
  getAttributes?: any
  setAttribute?: any
  setUserInfo?: any
  getUserInfo?: any
  getAuthStatus?: any
  setAuthStatus?: any
}

const defaultState: IAuth = {
  sessionInfo: {},
  authStatus: AuthStatus.Loading,
}

export const AuthContext = React.createContext(defaultState)

export const AuthIsSignedIn: React.FunctionComponent = ({ children }) => {
  const { authStatus }: IAuth = useContext(AuthContext)

  return <>{authStatus === AuthStatus.SignedIn ? children : null}</>
}

export const AuthIsNotSignedIn: React.FunctionComponent = ({ children }) => {
  const { authStatus }: IAuth = useContext(AuthContext)

  return <>{authStatus === AuthStatus.SignedOut ? children : null}</>
}

const AuthProvider: React.FunctionComponent = ({ children }) => {
  const [authStatus, setAuthStatus] = useState(AuthStatus.Loading)
  const [loading, setLoading] = useState(true)
  const [sessionInfo, setSessionInfo] = useState({})
  const [attrInfo, setAttrInfo] = useState([])

  useEffect(() => {
    async function getSessionInfo() {
      try {
        setLoading(true)
        const session: any = await getSession()
        // console.log(session.accessToken.payload.sub)
        setSessionInfo({
          accessToken: session.accessToken.jwtToken,
          refreshToken: session.refreshToken.token,
        })
        window.localStorage.setItem("accessToken", `${session.accessToken.jwtToken}`)
        window.localStorage.setItem("refreshToken", `${session.refreshToken.token}`)
        const userData = getUserInfo()
        if (!userData) {
          signOut()
          throw Error("Not Signed In")
        }
        // await setAttribute({ Name: 'website', Value: 'abc2' })
        const attr: any = await getAttributes()
        setAttrInfo(attr)
        setAuthStatus(AuthStatus.SignedIn)
        console.log("signed in", authStatus)
      } catch (err) {
        setAuthStatus(AuthStatus.SignedOut)
      }
      setLoading(false)
    }
    getSessionInfo()
    // Set up a timer to execute every 23 hours
    const timerId = setInterval(getSessionInfo, 23 * 60 * 60 * 1000)
    return () => clearInterval(timerId)
  }, [authStatus])

  if (loading) {
    return (
      <div className="flex w-full h-full justify-center items-center">
        <span className="loading loading-spinner loading-lg"></span>
      </div>
    )
  }

  async function signInWithEmail(username: string, password: string) {
    try {
      await cognito.signInWithEmail(username, password)
      // setAuthStatus(AuthStatus.SignedIn)
    } catch (err) {
      setAuthStatus(AuthStatus.SignedOut)
      throw err
    }
  }

  async function signUpWithEmail(email: string, password: string) {
    try {
      const signupData = await cognito.signUpUserWithEmail(email, password)
      // setAuthStatus(AuthStatus.SignedIn);
      return signupData
    } catch (err) {
      // setAuthStatus(AuthStatus.SignedOut);
      throw err
    }
  }

  function signOut() {
    cognito.signOut()
    try {
      window.localStorage.removeItem("userInfo")
    } catch (error) {
      console.log("No user item to remove")
    }
    setAuthStatus(AuthStatus.SignedOut)
  }

  async function verifyCode(cognitoId: string, code: string) {
    try {
      const x = await cognito.verifyCode(cognitoId, code)
      console.log({ CODE_FROM_COGNITO: x })
      setAuthStatus(AuthStatus.SignedIn)
    } catch (err) {
      setAuthStatus(AuthStatus.SignedOut)
      throw err
    }
  }

  async function getSession() {
    try {
      const session = await cognito.getSession()
      return session
    } catch (err) {
      throw err
    }
  }

  async function getAttributes() {
    try {
      const attr = await cognito.getAttributes()
      return attr
    } catch (err) {
      throw err
    }
  }

  async function setAttribute(attr: any) {
    try {
      const res = await cognito.setAttribute(attr)
      return res
    } catch (err) {
      throw err
    }
  }

  async function sendCode(email: string) {
    try {
      await cognito.sendCode(email)
    } catch (err) {
      throw err
    }
  }

  async function forgotPassword(email: string, code: string, password: string) {
    try {
      await cognito.forgotPassword(email, code, password)
    } catch (err) {
      throw err
    }
  }

  async function changePassword(oldPassword: string, newPassword: string) {
    try {
      await cognito.changePassword(oldPassword, newPassword)
    } catch (err) {
      throw err
    }
  }

  function setUserInfo(userInfo: {}) {
    window.localStorage.setItem("userInfo", JSON.stringify(userInfo))
    const uInfoFromLStorage = JSON.parse(window.localStorage.getItem("userInfo"))
    return uInfoFromLStorage
    // console.log('sad',JSON.parse(window.localStorage.getItem('userInfo')))
  }

  function getUserInfo() {
    var localUserInfo = window.localStorage.getItem("userInfo")
    // console.log('sad2',JSON.parse(window.localStorage.getItem('userInfo')))
    return JSON.parse(localUserInfo)
  }

  function getAuthStatus() {
    if (authStatus === 1) {
      console.log("get auth in", authStatus === 1)

      return true
    } else return false
  }

  const state: IAuth = {
    authStatus,
    sessionInfo,
    attrInfo,
    signUpWithEmail,
    signInWithEmail,
    signOut,
    verifyCode,
    getSession,
    sendCode,
    forgotPassword,
    changePassword,
    getAttributes,
    setAttribute,
    setUserInfo,
    getUserInfo,
    getAuthStatus,
    setAuthStatus,
  }

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>
}

export default AuthProvider
