import React, { useEffect, useState } from "react"
import "./GenericYesNoPopup.scss"
import { ContextManager } from "../../../logic/context/ContextManager"
import { ContextType } from "../../../data/enums/ContextType"
import { PopupActions } from "logic/actions/PopupActions"

interface IProps {
  title: string
  renderContent: () => any
  leftBar?: () => any
  acceptLabel?: string
  onAccept?: () => any
  onClose?: () => any
  skipAcceptButton?: boolean
  disableAcceptButton?: boolean
  rejectLabel?: string
  onReject?: () => any
  skipRejectButton?: boolean
  disableRejectButton?: boolean

  // For AddNewProjectPopup
  isFormik?: () => any
}

export const GenericYesNoPopup: React.FC<IProps> = ({
  title,
  renderContent,
  leftBar,
  acceptLabel,
  onAccept,
  onClose,
  skipAcceptButton,
  disableAcceptButton,
  rejectLabel,
  onReject,
  skipRejectButton,
  disableRejectButton,

  isFormik,
}) => {
  //   console.log({ isFormik: isFormik() });
  const [status, setMountStatus] = useState(false)
  useEffect(() => {
    if (!status) {
      ContextManager.switchCtx(ContextType.POPUP)
      setMountStatus(true)
    }
  }, [status])

  return (
    <div
      className={`modal-box max-h-auto relative bg-primary rounded-[50px] overflow-hidden group/popup ${
        skipAcceptButton || skipRejectButton ? "" : "max-w-[600px]"
      }`}
    >
      <label
        className="btn btn-xs btn-circle absolute right-8 top-6 bg-white opacity-60 text-black hover:bg-white hover:opacity-100"
        onClick={onClose ? onClose : () => PopupActions.close()}
      >
        ✕
      </label>
      <div
        className={`flex ${
          leftBar ? "" : "w-full justify-center"
        } overflow-y-scroll scrollbar-invisible overflow-x-hidden  group-hover/popup:scrollbar h-full`}
      >
        {leftBar ? (
          <>
            <div className="mx-2 w-1/12">{leftBar()}</div>
            <div className="divider divider-horizontal divider-white"></div>
          </>
        ) : (
          <></>
        )}
        {!!isFormik ? (
          <form className="w-5/6" onSubmit={isFormik().handleSubmit}>
            <div className={`flex flex-col justify-center items-center mt-2 w-12/12 `}>
              <p
                className={`font-bold text-2xl uppercase  text-white tracking-wide text-center ${
                  skipAcceptButton || skipRejectButton ? "px-10 py-2" : "px-12"
                }`}
              >
                {title}
              </p>
              <div
                className={`bg-primary w-full ${
                  skipAcceptButton || skipRejectButton ? "" : "px-12"
                }`}
              >
                {renderContent()}
              </div>
              <div
                className={`flex justify-between w-full ${
                  skipAcceptButton || skipRejectButton ? "" : "px-12"
                }`}
              >
                {!skipAcceptButton && (
                  <div
                    className={`flex justify-start ${
                      skipRejectButton || !leftBar ? "w-full" : "w-6/12"
                    }`}
                  >
                    <button
                      onClick={onAccept}
                      // type={`${!!isFormik ? "submit" : "button"}`}
                      type="submit"
                      className={`${
                        skipRejectButton && !leftBar ? "w-full" : "w-[96%]"
                      } btn tracking-normal rounded-full w-full text-black bg-accent-content border-accent-content hover:bg-accent hover:border-accent hover:text-white disabled:bg-accent-content`}
                      disabled={disableAcceptButton}
                    >
                      {!!acceptLabel ? acceptLabel : "YES"}
                    </button>
                  </div>
                )}
                {!skipRejectButton && (
                  <div
                    className={`flex justify-end ${
                      skipAcceptButton || !leftBar ? "w-full" : "w-6/12"
                    }`}
                  >
                    <button
                      onClick={onReject}
                      className={
                        //  ?
                        `${
                          skipAcceptButton && !leftBar ? "w-full" : "w-[96%]"
                        } btn tracking-normal rounded-full text-black bg-accent-content hover:bg-accent hover:text-white border-accent-content hover:border-accent-content disabled:bg-accent-content`
                        // :
                        // `${skipAcceptButton ? 'w-full' : 'w-[96%]'} btn tracking-normal rounded-full text-black bg-accent-content hover:bg-accent hover:text-white disabled:bg-accent-content`
                      }
                      disabled={disableRejectButton}
                    >
                      {!!rejectLabel ? rejectLabel : "NO, THANKS"}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </form>
        ) : (
          <form className="w-5/6" onSubmit={(event) => event.preventDefault()}>
            <div className={`flex p-2 flex-col justify-center items-center mt-2 w-12/12 `}>
              <p
                className={`font-bold text-2xl uppercase  text-primary-content tracking-wide text-center ${
                  skipAcceptButton || skipRejectButton ? "" : "px-12"
                }`}
              >
                {title}
              </p>
              <div
                className={`bg-primary w-full ${
                  skipAcceptButton || skipRejectButton ? "" : "px-12"
                }`}
              >
                {renderContent()}
              </div>
              <div
                className={`flex justify-between w-full ${
                  skipAcceptButton || skipRejectButton ? "" : "px-12"
                }`}
              >
                {!skipAcceptButton && (
                  <div
                    className={`flex justify-start ${
                      skipRejectButton || !leftBar ? "w-full" : "w-6/12"
                    }`}
                  >
                    <button
                      onClick={onAccept}
                      // type={`${!!isFormik ? "submit" : "button"}`}
                      type="submit"
                      className={`${
                        skipRejectButton && !leftBar ? "w-full" : "w-[96%]"
                      } btn tracking-normal rounded-full w-full text-black bg-accent-content hover:bg-accent hover:text-white border-accent-content hover:border-accent-content disabled:bg-accent-content`}
                      disabled={disableAcceptButton}
                    >
                      {!!acceptLabel ? acceptLabel : "YES"}
                    </button>
                  </div>
                )}
                {!skipRejectButton && (
                  <div
                    className={`flex justify-end ${
                      skipAcceptButton || !leftBar ? "w-full" : "w-6/12"
                    }`}
                  >
                    <button
                      onClick={onReject}
                      className={
                        //  ?
                        `${
                          skipAcceptButton && !leftBar ? "w-full" : "w-[96%]"
                        } btn tracking-normal rounded-full text-black bg-accent-content hover:bg-accent hover:text-white border-accent-content hover:border-accent-content disabled:bg-accent-content`
                        // :
                        // `${skipAcceptButton ? 'w-full' : 'w-[96%]'} btn tracking-normal rounded-full text-black bg-accent-content hover:bg-accent hover:text-white disabled:bg-accent-content`
                      }
                      disabled={disableRejectButton}
                    >
                      {!!rejectLabel ? rejectLabel : "NO, THANKS"}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  )
}
