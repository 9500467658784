import { downloadImageAnnotationsAPI } from "apis/images"
import { ImageStatus } from "data/enums/ImageStatus"
import { toast } from "react-toastify"
import { Action } from "store/Actions"

export const hasLabels = (data) => {
  return (
    (data.labelLines && data.labelLines.length > 0) ||
    (data.labelNameIds && data.labelNameIds.length > 0) ||
    (data.labelPoints && data.labelPoints.length > 0) ||
    (data.labelPolygons && data.labelPolygons.length > 0) ||
    (data.labelRects && data.labelRects.length > 0)
  )
}

export const updateActiveImageIndexThunk =
  (activeImageIndex: number | null) => async (dispatch, getState) => {
    console.log("Starting update for active image index:", activeImageIndex)
    try {
      if (activeImageIndex == null) {
        dispatch({
          type: Action.UPDATE_ACTIVE_IMAGE_INDEX,
          payload: { activeImageIndex: null },
        })
        return
      }

      const accessToken = localStorage.getItem("accessToken")
      if (!accessToken) {
        throw new Error("Authentication token is missing.")
      }

      const { labels } = getState() // destructuring to get labels part of the state once
      const currentImageIndex = labels.activeImageIndex
      let newImagesData = undefined
      const currentImagesData = labels.imagesData[currentImageIndex]
      let activeImageIndexAdjusted = activeImageIndex
      if (currentImageIndex === activeImageIndex) return
      if (currentImagesData) {
        const isUnannotated =
          currentImagesData.imageStatus !== ImageStatus.UN_ANNOTATED &&
          !hasLabels(currentImagesData)

        const isAnnotated =
          currentImagesData.imageStatus === ImageStatus.UN_ANNOTATED && hasLabels(currentImagesData)

        console.log("tst", isAnnotated, isUnannotated, currentImagesData)
        // Check if the current image needs to be removed based on its annotated status and the section status
        if (
          currentImageIndex !== null &&
          labels.imageStatus !== ImageStatus.ALL &&
          (isUnannotated || isAnnotated)
        ) {
          newImagesData = labels.imagesData.filter((_, index) => index !== currentImageIndex)

          if (activeImageIndex > currentImageIndex) activeImageIndexAdjusted -= 1
          const imageId = newImagesData[activeImageIndexAdjusted].imageId.split("_")[0]
          const downloadInfo = await downloadImageAnnotationsAPI(imageId, accessToken)
          const { labels: newLabels, stage } = downloadInfo.data.data
          const updatedImageData = {
            ...newImagesData[activeImageIndexAdjusted],
            ...newLabels,
            imageStatus: stage,
          }
          newImagesData[activeImageIndexAdjusted] = updatedImageData

          // this dispatch also triggers
          dispatch({
            type: Action.UPDATE_IMAGES_DATA,
            payload: { imageData: newImagesData },
          })
        }
      }

      console.log(
        "activeImageIndexAdjusted",
        activeImageIndexAdjusted,
        "activeImageIndex",
        activeImageIndex
      )
      const imageData = labels.imagesData[activeImageIndexAdjusted]
      if (imageData === undefined) return
      const imageId = imageData.imageId.split("_")[0]
      const downloadInfo = await downloadImageAnnotationsAPI(imageId, accessToken)
      if (newImagesData) {
        dispatch({
          type: Action.UPDATE_ACTIVE_IMAGE_INDEX,
          payload: { activeImageIndex: activeImageIndexAdjusted },
        })
      } else if (downloadInfo.statusCode === 200 && downloadInfo.data) {
        const { labels: newLabels, stage } = downloadInfo.data.data
        if (!newLabels) {
          console.error("No labels data found in the response.", downloadInfo)
          // throw new Error("No labels data found in the response.")
        }

        const updatedImageData = {
          ...imageData,
          ...newLabels,
          imageStatus: stage,
        }

        dispatch({
          type: Action.UPDATE_IMAGE_DATA_BY_ID,
          payload: {
            id: imageData.id,
            newImageData: updatedImageData,
          },
        })

        dispatch({
          type: Action.UPDATE_ACTIVE_IMAGE_INDEX,
          payload: { activeImageIndex: activeImageIndexAdjusted },
        })
      } else {
        throw new Error("API call to fetch image data failed.")
      }
    } catch (error) {
      const errorObj = error as Error
      console.error("Error updating active image index:", errorObj)

      if (errorObj.message.includes("token")) {
        toast.error("Session expired. Please login again.")
      } else {
        toast.error("Failed to update image data.")
      }
    }
  }
